import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { areDatesEqual } from '../../utils/date';
import { axiosClient as axios } from '../../utils/axios_instance';
import * as stockingParameterSlice from '../Analysis/stockingParameterSlice';
import { openSuccessNotification } from '../../common/notification/Notification';
import { CompanyStockingParameter } from '../Settings/TabStockingParameters/interfaces';
import { COMPANY_STOCKING_PARAMETER_URL, STOCKING_PARAMETER_URL } from '../../config/config.api';

import { showStockingParameterError } from './helpers';
import { StockingParameterData } from './Detail/interfaces';
import { NewStockingParameter, NewStockingParameterState } from './interfaces';
import * as detailStockingParameterSlice from './Detail/detailStockingParameterSlice';

const initialState: NewStockingParameterState = {
  companyStockingParameters: [],
  isLoadingNewStockingParameters: false,
  showStockingParameterModal: false,
};

export const newStockingParameterSlice = createSlice({
  name: 'newStockingParameter',
  initialState,
  reducers: {
    setCompanyStockingParameters: (state: NewStockingParameterState, action: PayloadAction<CompanyStockingParameter[]>) => {
      state.companyStockingParameters = action.payload;
    },
    setIsLoadingNewStockingParameters: (state: NewStockingParameterState, action: PayloadAction<boolean>) => {
      state.isLoadingNewStockingParameters = action.payload;
    },
    setShowStockingParameterModal: (state: NewStockingParameterState, action: PayloadAction<boolean>) => {
      state.showStockingParameterModal = action.payload;
    },
  },
});

export const {
  setCompanyStockingParameters,
  setIsLoadingNewStockingParameters,
  setShowStockingParameterModal,
} = newStockingParameterSlice.actions;

export const fetchCompanyStockingParameters = (props: { companyId: string }) => async (dispatch: Function) => {
  const { companyId } = props;

  const params = {
    $limit: -1,
    companyId: companyId,
  };

  try {
    const response = await axios.get<CompanyStockingParameter[]>(COMPANY_STOCKING_PARAMETER_URL, { params });
    dispatch(setCompanyStockingParameters(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const createStockingParameters = (props: { body: NewStockingParameter; onCloseForm: Function }) => async (dispatch: Function) => {
  const { body, onCloseForm } = props;

  dispatch(setIsLoadingNewStockingParameters(true));

  try {
    await axios.post(STOCKING_PARAMETER_URL, body);
    dispatch(setShowStockingParameterModal(false));
    openSuccessNotification(i18next.t('stockingParameter.formNew.success'));
    onCloseForm();
    dispatch(setIsLoadingNewStockingParameters(false));
  } catch (e) {
    console.log(e?.response);
    dispatch(setIsLoadingNewStockingParameters(false));

    if (e?.response?.data?.data?.error) {
      const { error, key } = e.response.data.data;
      showStockingParameterError({ error, key });
    }

    return;
  }

  dispatch(stockingParameterSlice.fetchStockingParameters({ page: 0, stockingId: body.stockingId, companyId: body.companyId }));
};

const showStockingParameterModal = (props: { companyId: string }) => (dispatch: Function) => {
  const { companyId } = props;

  dispatch(fetchCompanyStockingParameters({ companyId }));
  dispatch(setShowStockingParameterModal(true));
};

export const addNewParameter = (props: { companyId: string; stockingId: string; stockingParameterData: StockingParameterData[] }) => async (dispatch: Function) => {
  const { companyId, stockingId, stockingParameterData } = props;

  if (stockingParameterData.length === 0) {
    dispatch(showStockingParameterModal({ companyId }));
    return;
  }

  let response: StockingParameterData[] = [];

  try {
    response = await detailStockingParameterSlice.fetchLastStockingParameter({ companyId: companyId, stockingId });
  } catch (e) {
    console.log(e?.response);
    dispatch(showStockingParameterModal({ companyId }));
    return;
  }

  if (!response.length) {
    dispatch(showStockingParameterModal({ companyId }));
    return;
  }

  const currentDate = new Date().toString();
  const lastStockingParameter = response[0];

  if (areDatesEqual({ date1: currentDate, date2: lastStockingParameter.date })) {
    dispatch(detailStockingParameterSlice.fetchCompanyStockingParameters({ companyId: companyId }));
    dispatch(detailStockingParameterSlice.fetchStockingParameter({ _id: lastStockingParameter._id }));
    dispatch(detailStockingParameterSlice.setShowEditStockingParameterModal(true));
    return;
  }

  dispatch(showStockingParameterModal({ companyId }));
};

export default newStockingParameterSlice.reducer;
