import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { THEME } from '../../../config/commons';
import { removeSpaces } from '../../../utils/strings';
import { Store } from '../../../state/store.interfaces';
import { getParameter } from '../../../helpers/stocking.helpers';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { colorsPoints } from '../../../common/components/charts/ShadedPlot/helpers';

import * as laboratoryChartSlice from './laboratoryChartSlice';
import styles from './Legends.module.scss';

interface Props {
  refLegends: React.RefObject<HTMLDivElement>;
  theme?: 'dark' | 'light';
}

export const Legends = (props: Props) => {
  const { refLegends, theme = 'dark' } = props;
  const dispatch = useDispatch();
  const isLightTheme = theme === THEME.LIGHT;

  const {
    filters,
    dataSource,
  } = useSelector((state: Store) => state.laboratoryChart);
  const { phaseType } = useSelector((state: Store) => state.header);

  const {
    parameter,
  } = filters;

  const renderStockingLegends = () => {
    const legendsList = [];
    for (let index = 0; index < dataSource.length; index++) {
      const data = dataSource[index];
      const nameWithoutSpaces = removeSpaces(data.name.toLowerCase());

      const element = (
        <div
          id={`legend_${nameWithoutSpaces}`}
          key={index}
          className={cx(styles.item, isLightTheme ? styles.itemLight : styles.itemDark)}
          onClick={() => {
            const dataSourceCopy = cloneDeep(dataSource);
            dataSourceCopy[index].enabled = !dataSourceCopy[index].enabled;
            dispatch(laboratoryChartSlice.setDataSource(dataSourceCopy));
          }}
        >
          <div className={styles.colorBox} style={{ background: `${colorsPoints[index]}` }} />
          <span className={data.enabled ? '' : styles.disabled}>
            <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={data.name} theme={theme} />
          </span>
        </div>
      );

      legendsList.push(element);
    }

    const renderYParameter = (props: { opacity?: number }) => {
      const { opacity = 1 } = props;

      return (
        <div className={styles.sideLeft} style={{ opacity }}>
          <div className={styles.labelAxisY}>
            <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={getParameter({ parameter, stockingPhaseType: phaseType })} />
          </div>
        </div>
      );
    };

    return (
      <div ref={refLegends} className={styles.container}>
        {renderYParameter({})}
        <div className={styles.legends}>{legendsList}</div>

        {renderYParameter({ opacity: 0 })}
      </div>
    );
  };

  return renderStockingLegends();
};
