import i18next from 'i18next';

import { formatUTCDate } from '../../../../utils/date';
import { defaultLegendChart, defaultStockingDataChart } from '../../../../pages/Sowings/Parameters/helpers';
import { StockingParameterValue, TooltipData, StockingParameterData, LayoutCustomizable } from '../../../../pages/Sowings/Parameters/interfaces';

const COLOR_MANDY = '#ec4c69';

export const getMinY = (props: { parameterData: StockingParameterValue[]; minDate: Date; maxDate: Date }) => {
  const { parameterData, minDate, maxDate } = props;

  const yValues = getScaleYValues({ parameterData });
  let minY = Math.min(...yValues);

  for (let index = 0; index < parameterData.length; index++) {
    const parameter = parameterData[index];
    if ((minDate <= parameter.date && parameter.date <= maxDate) && (minY > parameter.value)) {
      minY = parameter.value;
    }
  }

  return minY;
};

export const getMaxY = (props: { parameterData: StockingParameterValue[]; minDate: Date; maxDate: Date }) => {
  const { parameterData, minDate, maxDate } = props;

  const yValues = getScaleYValues({ parameterData });
  let maxY = Math.max(...yValues);

  for (let index = 0; index < parameterData.length; index++) {
    const parameter = parameterData[index];
    if ((minDate <= parameter.date && parameter.date <= maxDate) && maxY < parameter.value) {
      maxY = parameter.value;
    }
  }

  return maxY;
};

export const getScaleYValues = (props: { parameterData: StockingParameterValue[] }) => {
  const { parameterData = [] } = props;
  return parameterData.map((item) => item.value);
};

export const getDateWithoutTime = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const renderTickBottomFormat = (domainValue: Date | d3.NumberValue) => {
  return formatUTCDate(domainValue.toString());
};

export const generateDateArray = (props: { minDate: Date, maxDate: Date }): Date[] => {
  const { minDate, maxDate } = props;

  const dates: Date[] = [];
  const currentDate = new Date(minDate);

  while (currentDate <= maxDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const getColorParameterWithFrequencies = (index: number) => {
  switch (index) {
    case 0:
      return COLOR_MANDY;

    case 1:
      return 'limegreen';

    case 2:
      return 'purple';

    case 3:
      return 'cyan';

    default:
      return 'orange';
  }
};

export const getAllData = (props: {
  totalFeed: StockingParameterData;
  growthRates: StockingParameterData;
  parameterWithoutFrequencies: StockingParameterData;
  parameterWithFrequencies: { [key: string]: StockingParameterData };
  selectedParameter?: string;
}) => {
  const { parameterWithFrequencies, parameterWithoutFrequencies, totalFeed, growthRates, selectedParameter = '' } = props;

  const allData: TooltipData[] = [
    ...totalFeed.values.map(data => ({ ...data, type: defaultLegendChart.TOTAL_FEED, unit: totalFeed.unit })),
    ...growthRates.values.map(data => ({ ...data, type: defaultLegendChart.GROWTH_RATE, unit: growthRates.unit })),
    ...parameterWithoutFrequencies.values.map(data => ({ ...data, type: selectedParameter, unit: parameterWithoutFrequencies.unit })),
    ...Object.entries(parameterWithFrequencies).flatMap(([key, { unit, values }]) =>
      values.map((data: StockingParameterValue) => ({ ...data, type: selectedParameter, key, unit }))
    ),
  ];

  allData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  return allData;
};

interface LeftPositionProps {
  marginLeft: number;
  tooltipDialogWidth: number;
  bubbleWidth: number;
  width: number;
}

export const getStockingChartLeftPosition = (props: LeftPositionProps) => {
  const { marginLeft, tooltipDialogWidth, bubbleWidth, width } = props;

  const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

  let value = 0;
  if (marginLeft + tooltipTotalWidth < width) {
    value = marginLeft + (tooltipDialogWidth / 2.5) + (bubbleWidth / 2);
  } else {
    value = marginLeft - (tooltipDialogWidth / 1.8) - (bubbleWidth * 2);
  }

  return `${value}px`;
};

export const getLabelTooltip = (props: { type: string; key?: string }) => {
  const { key, type } = props;

  switch (type) {
    case defaultLegendChart.TOTAL_FEED:
      return i18next.t('production.parameter.totalFeed');

    case defaultLegendChart.GROWTH_RATE:
      return i18next.t('production.parameter.growthRate');

    default:
      return key ? `${type} ${key}` : type;
  }
};

export const getUnitTooltip = (props: { type: string; unit?: string; }) => {
  const { type, unit } = props;

  if (!unit) {
    return '';
  }

  switch (type) {
    case defaultLegendChart.GROWTH_RATE:
      return `${i18next.t('production.parameter.gramPerWeek', { unit })}`;

    default:
      return unit;
  }
};

export const renderCircle = (props: { key?: string; parameterWithoutFrequencies: StockingParameterValue[]; parameterWithFrequencies: { [key: string]: StockingParameterData }; }) => {
  const { key: mainKey, parameterWithoutFrequencies, parameterWithFrequencies } = props;

  if (parameterWithoutFrequencies.length > 0) {
    return `<circle cx="7" cy="7" r="6" stroke="${COLOR_MANDY}" stroke-width="1" fill="${COLOR_MANDY}" />`;
  }

  if (Object.keys(parameterWithFrequencies).length > 0) {
    const keys = Object.keys(parameterWithFrequencies);
    const index = keys.findIndex(key => key === mainKey);
    return `<circle cx="7" cy="7" r="6" stroke="white" stroke-width="1" fill="${getColorParameterWithFrequencies(index)}"  />`;
  }

  return '';
};

export const renderRect = () => {
  return '<rect x="1" y="2" width="11" height="11" stroke="royalblue" stroke-width="1" fill="royalblue" />';
};

export const renderPlusIcon = () => {
  return '<text x="8" y="8" style="font-size: 20px;" text-anchor="middle" alignment-baseline="middle" fill="black">+</text>';
};

export const getSelectedParametersChart = (props: { layoutCustomizable: LayoutCustomizable[] }) => {
  const { layoutCustomizable } = props;
  const parameters = layoutCustomizable.find((item) => item.i === defaultStockingDataChart.CHART)?.parameters;
  return !parameters || !parameters.length ? [] : parameters;
};
