import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { changeHeader } from '../../AppHeader/headerSlice';
import { stockingPhaseTypes } from '../../../config/commons';
import { getUnitPhaseTypeFromAnalysis } from '../../../helpers/units.helpers';

import { Legends } from './Legends';
import { Subheader } from './Subheader';
import { getDataParameter } from './helpers';
import styles from './ParameterState.module.scss';
import { ParameterEmpty } from './ParameterEmpty';
import { ParameterOptions } from './ParameterOptions';
import { StockingParameterGraph } from './ParameterGraph';
import * as parameterStateSlice from './parameterStateSlice';

export const ParameterState = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);
  const { stockingParameterGraphs, selectedParameterName } = useSelector((state: Store) => state.parameterState);

  useEffect(() => {
    dispatch(changeHeader({ title: t('production.parameter.title') }));
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCompany._id || phaseType !== stockingPhaseTypes.ADULT) {
      dispatch(parameterStateSlice.resetFilters());
      return;
    }

    const params = {
      companyId: selectedCompany._id,
      unitPhaseType: getUnitPhaseTypeFromAnalysis(phaseType),
    };

    dispatch(parameterStateSlice.fetchUnits(params));
    dispatch(parameterStateSlice.fetchGlobalReferenceCurves({ phaseType }));
    dispatch(parameterStateSlice.fetchCompanyReferenceCurves({ companyId: selectedCompany._id, phaseType }));
  }, [selectedCompany._id, phaseType]);

  return (
    <div className={styles.container}>
      <Subheader />
      <ParameterOptions />
      <Legends
        selectedParameterName={selectedParameterName}
        stockingParameterGraphs={stockingParameterGraphs}
      />
      <ParameterEmpty />

      <div className={styles.graphs}>
        {stockingParameterGraphs.map((item) => (
          <StockingParameterGraph
            key={item.containerId}
            data={getDataParameter({ item, selectedParameterName })}
            stockingName={item.stockingName}
            containerId={item.containerId}
            stockingId={item.stockingId}
          />
        ))}
      </div>
    </div>
  );
};
