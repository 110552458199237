import { Space, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from 'lodash';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../utils/select';
import { logBalanceOrigins, THEME } from '../../config/commons';
import CleanButton from '../../common/components/buttons/CleanButton';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';

import * as settingsSlice from './settingsSlice';
import styles from './TabHistoryManagementFilters.module.scss';

interface Props {
  theme?: 'dark' | 'light';
}

export default function TabHistoryManagementFilters (props: Props) {
  const { theme } = props;
  const { Option } = Select;

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const {
    historyManagement,
  } = useSelector((state: Store) => state.profile);

  const {
    users,
    filters,
  } = historyManagement;

  const isLightTheme = theme === THEME.LIGHT;

  const renderLogOriginsDropdown = () => {
    return (
      <LrvSelect
        id='dropdown_log_origins'
        theme={theme}
        showSearch
        className={styles.select}
        value={filters.logBalanceOrigin}
        title={t('analysis.actions')}
        suffixIcon={<Icon name='arrow-down-s' />}
        onChange={onChangeFilterLogBalance}
        dropdownMatchSelectWidth={false}
      >
        <Option
          id='log_origin_all'
          key='log_origin_all'
          value={logBalanceOrigins.ALL}
        >
          {capitalize(t('historyManagement.logOrigin.all').toLowerCase())}
        </Option>

        <Option
          id='log_origin_monthly_reload'
          key='log_origin_monthly_reload'
          value={logBalanceOrigins.MONTHLY_RELOAD}
        >
          {capitalize(t('historyManagement.logOrigin.monthlyReload').toLowerCase())}
        </Option>

        <Option
          id='log_origin_company_creation'
          key='log_origin_company_creation'
          value={logBalanceOrigins.COMPANY_CREATION}
        >
          {capitalize(t('historyManagement.logOrigin.companyCreation').toLowerCase())}
        </Option>

        <Option
          id='log_origin_user_creation'
          key='log_origin_user_creation'
          value={logBalanceOrigins.USER_CREATION}
        >
          {capitalize(t('historyManagement.logOrigin.userCreation').toLowerCase())}
        </Option>

        <Option
          id='log_origin_balance_management'
          key='log_origin_balance_management'
          value={logBalanceOrigins.BALANCE_MANAGEMENT}
        >
          {capitalize(t('historyManagement.logOrigin.balanceManagement').toLowerCase())}
        </Option>

        <Option
          id='log_origin_user_inactivation'
          key='log_origin_user_inactivation'
          value={logBalanceOrigins.USER_INACTIVATION}
        >
          {capitalize(t('historyManagement.logOrigin.userInactivation').toLowerCase())}
        </Option>
      </LrvSelect>
    );
  };

  const renderUsersDropdown = () => {
    return (
      <LrvSelect
        id='dropdown_users'
        theme={theme}
        className={styles.select}
        value={filters.userIdSelected === '' ? undefined : filters.userIdSelected}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        title={t('stockings.selectUser')}
        placeholder={t('stockings.selectUser')}
        optionFilterProp='children'
        onChange={onChangeUser}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        {users.map((user) => {
          const userFullName = `${user.firstName} ${user.lastName}`;
          return (
            <Option key={user._id} value={user._id}>{userFullName}</Option>
          );
        })}
      </LrvSelect>
    );
  };

  const onChangeFilters = (props: { logBalanceOrigin: string; userIdSelected?: string }) => {
    const { logBalanceOrigin, userIdSelected } = props;

    if (logBalanceOrigin === logBalanceOrigins.ALL) {
      dispatch(settingsSlice.fetchHistoryManagement({ page: 1, userId: userIdSelected }));
    } else {
      dispatch(settingsSlice.fetchHistoryManagement({ page: 1, origin: logBalanceOrigin, userId: userIdSelected }));
    }
  };

  const onChangeFilterLogBalance = (value: string) => {
    const newFilters = { ...filters };
    newFilters.logBalanceOrigin = value;

    dispatch(settingsSlice.setCurrentPageHistory(1));
    dispatch(settingsSlice.setHistoryManagementFilters(newFilters));
    onChangeFilters({ logBalanceOrigin: value, userIdSelected: filters.userIdSelected });
  };

  const onChangeUser = (value: string) => {
    const newFilters = { ...filters };
    newFilters.userIdSelected = value;
    dispatch(settingsSlice.setHistoryManagementFilters(newFilters));
    onChangeFilters({ logBalanceOrigin: filters.logBalanceOrigin, userIdSelected: value });
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={isLightTheme ? 'light' : 'dark'}
        onClick={() => {
          dispatch(settingsSlice.resetHistoryManagementFilters());
          dispatch(settingsSlice.fetchHistoryManagement({ page: 0 }));
        }}
      />
    );
  };

  return (
    <div id='container_filters' className={styles.container}>
      <Space align='end'>
        {renderLogOriginsDropdown()}
        {renderUsersDropdown()}
        {renderCleanButton()}
      </Space>
    </div>
  );
}
