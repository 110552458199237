import cx from 'classnames';
import { Row, Col, Form, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { getCurrentTheme } from '../../helpers/theme';
import { changeHeader } from '../AppHeader/headerSlice';
import Content from '../../common/components/Content/Content';
import { LrvTabs } from '../../common/components/LrvTabs/LrvTabs';
import DotSpinner from '../../common/components/DotSpinner/DotSpinner';
import { getMainRole, isAdminUser, isOwnerRole, roles } from '../../config/commons';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';
import NewFunctionalities from '../../common/components/FunctionalitiesModal/NewFunctionalities';

import './Settings.scss';
import TabCoupon from './TabCoupon';
import TabBanner from './TabBanner';
import TabFormEdit from './TabFormEdit';
import styles from './Settings.module.scss';
import TabScheduleRule from './TabScheduleRule';
import { fetchCredential } from './scheduleRuleSlice';
import TabApiCustomService from './TabApiCustomService';
import { SettingsTab, settingsTabs } from './interfaces';
import TabHistoryManagement from './TabHistoryManagement';
import TabIntegrationApiKey from './TabIntegrationApiKey';
import TabFormChangePassword from './TabFormChangePassword';
import TabCompanySetting from './TabCompany/TabCompanySetting';
import TabAnalysisCard from './TabAnalysisCard/TabAnalysisCard';
import * as companySettingSlice from './TabCompany/companySettingSlice';
import StockingParameters from './TabStockingParameters/StockingParameters';
import * as stockingParameterSlice from './TabStockingParameters/companyStockingParameterSlice';
import * as analysisCardCustomizableSlice from './TabAnalysisCard/analysisCardCustomizableSlice';
import { fetchAllowApiService, fetchUserProfile, fetchUsers, setSettingTab } from './settingsSlice';
import * as stockingParameterCustomizableSlice from './TabStockingParameters/stockingParameterCustomizableSlice';

export default function Settings () {
  const mode = window.innerWidth >= 620 ? 'left' : 'top';

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { TabPane } = Tabs;

  const profile = useSelector((state: Store) => state.profile);
  const scheduleRule = useSelector((state: Store) => state.scheduleRule);
  const { company, phaseType } = useSelector((state: Store) => state.header);
  const isRunningOnboarding = useSelector((state: Store) => state.onboarding.run);

  const theme = getCurrentTheme();
  const higherRole = getMainRole();

  const hasPermissionHistory = useCallback(() => {
    return higherRole === roles.SUPER_ADMIN || higherRole === roles.SALES || higherRole === roles.SALES_MANAGER || higherRole === roles.CLIENT_OWNER;
  }, []);

  useEffect(() => {
    if (isRunningOnboarding) {
      dispatch(goToOnboardingNextStep(1000));
    }
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    dispatch(changeHeader({ title: 'users.profile.account' }));
    dispatch(fetchUserProfile());
    dispatch(fetchAllowApiService());

    if (hasPermissionHistory()) {
      dispatch(fetchUsers());
      dispatch(fetchCredential());
      dispatch(companySettingSlice.fetchCompanySetting());
    }

    if (isOwnerRole()) {
      dispatch(stockingParameterSlice.fetchCompanyStockingParameter());
      dispatch(stockingParameterCustomizableSlice.fetchStockingParameterCustomizable());
    }
  }, [dispatch, hasPermissionHistory]);

  useEffect(() => {
    if (company._id && isOwnerRole()) {
      dispatch(analysisCardCustomizableSlice.fetchAnalysisCardCustomizable({ companyId: company._id, phaseType }));
    }
  }, [dispatch, company._id, phaseType]);

  if (profile.isLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner}>
          <DotSpinner />
        </div>
      </div>
    );
  }

  function renderTabHistoryManagement () {
    if (!hasPermissionHistory()) {
      return null;
    }

    return (
      <TabPane tab={<div id='tab_history_management'>{t('historyManagement.title')}</div>} key={settingsTabs.HISTORY_MANAGEMENT}>
        <TabHistoryManagement theme={theme} />
      </TabPane>
    );
  }

  function renderTabApiService () {
    if (!isOwnerRole()) {
      return null;
    }

    if (!profile.allowImageApi && !profile.allowDataApi) {
      return null;
    }

    const renderTabs = () => {
      const tabs = [];

      if (profile.allowImageApi) {
        tabs.push(
          {
            key: '1',
            label: t('apiService.tabs.image'),
            children: <TabApiCustomService theme={theme} />
          },
        );
      }

      if (profile.allowDataApi) {
        tabs.push(
          {
            key: '2',
            label: t('apiService.tabs.data'),
            children: <TabIntegrationApiKey theme={theme} />
          },
        );
      }

      return tabs;
    };


    return (
      <TabPane
        tab={<div id='tab_api'>{t('apiService.title')}</div>}
        className={styles.tabPane}
        key={settingsTabs.API_SERVICE}
      >
        <LrvTabs
          theme={theme}
          items={renderTabs()}
        >
        </LrvTabs>
      </TabPane>
    );
  }

  function renderTabCoupon () {
    if (!isAdminUser()) {
      return null;
    }

    return (
      <TabPane
        className={styles.tabPane}
        tab={<div id='tab_coupon'>{t('users.profile.coupon')}</div>}
        key={settingsTabs.COUPON}
      >
        <TabCoupon theme={theme} />
      </TabPane>
    );
  }

  const renderTabBanner = () => {
    if (higherRole === roles.SUPER_ADMIN || higherRole === roles.SALES || higherRole === roles.SALES_MANAGER) {
      return (
        <TabPane tab={<div id='tab_banner'>{t('users.profile.banner')}</div>} key={settingsTabs.BANNER}>
          <TabBanner theme={theme} />
        </TabPane>
      );
    }

    return null;
  };

  const renderTabSheduleRule = () => {
    if (!scheduleRule?._id) {
      return null;
    }

    if (higherRole !== roles.SUPER_ADMIN && higherRole !== roles.SALES && higherRole !== roles.SALES_MANAGER && higherRole !== roles.CLIENT_OWNER) {
      return null;
    }

    return (
      <TabPane
        className={styles.tabPane}
        tab={<div id='tab_coupon'>{t('users.profile.schedule')}</div>}
        key={settingsTabs.SCHEDULE}
      >
        <TabScheduleRule theme={theme} />
      </TabPane>
    );
  };

  const renderTabCompany = () => {
    if (!hasPermissionHistory()) {
      return null;
    }

    return (
      <TabPane
        className={styles.tabPane}
        tab={<div id='tab_company'>{t('users.profile.company')}</div>}
        key={settingsTabs.COMPANY}
      >
        <TabCompanySetting theme={theme} />
      </TabPane>
    );
  };

  const renderTabStockingParameters = () => {
    if (!company.showStockingParameterSection || !isOwnerRole()) {
      return null;
    }

    return (
      <TabPane
        className={styles.tabPane}
        tab={<div id='tab_stocking_parameter'>{t('stockingParameter.description')}</div>}
        key={settingsTabs.STOCKING_PARAMETER}
      >
        <StockingParameters theme={theme} />
      </TabPane>
    );
  };

  const renderTabAnalysisCard = () => {
    if (!isOwnerRole()) {
      return null;
    }

    return (
      <TabPane
        className={styles.tabPane}
        tab={<div id='tab_analysis_detail'>{t('analysisCardCustomMobile.title')}</div>}
        key={settingsTabs.ANALYSIS_CARD}
      >
        <TabAnalysisCard theme={theme} />
      </TabPane>
    );
  };

  return <div className={cx(styles.settings, 'settings')}>
    <Row >
      <Col className={styles.columnFlex} span={24}>
        <Content className={styles.content}>
          <LrvTabs
            className='tabs'
            defaultActiveKey={profile.settingTab}
            tabPosition={mode}
            theme={theme}
            onChange={(key) => {
              dispatch(setSettingTab(key as SettingsTab));
            }}
          >
            <TabPane className={styles.tabPane} tab={<div id='tab_profile'>{t('users.profile.profile')}</div>} key={settingsTabs.USER}>
              <TabFormEdit theme={theme} />
            </TabPane>

            {renderTabCompany()}
            {renderTabStockingParameters()}
            {renderTabAnalysisCard()}

            <TabPane tab={<div id='tab_password'>{t('users.profile.password')}</div>} key={settingsTabs.PASSWORD}>
              <TabFormChangePassword theme={theme} />
            </TabPane>

            {renderTabHistoryManagement()}
            {renderTabApiService()}
            {renderTabCoupon()}
            {renderTabBanner()}
            {renderTabSheduleRule()}

            <TabPane className={styles.tabPane} tab={<div id='tab_new_functionalities'>{t('users.profile.newFunctionalities')}</div>} key={settingsTabs.NOVELTIES}>
              <Form>
                <Form.Item>
                  <NewFunctionalities theme={theme} />
                </Form.Item>
              </Form>
            </TabPane>
          </LrvTabs>
        </Content>
      </Col>
    </Row>
  </div>;
}