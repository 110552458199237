import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Image, Button, Space, Form } from 'antd';

import { RootState } from '../../../state/store';
import { dollarUS } from '../../../utils/strings';
import { Breadcrumb } from '../../AppHeader/interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import { getPaymentStatus } from '../../../helpers/payment.helpers';
import { plansTypes, transferStatus } from '../../../config/commons';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import Breadcrumbs from '../../../common/components/Breadcrumb/Breadcrumbs';
import { changeBreadcrumb, changeHeader } from '../../AppHeader/headerSlice';
import { lrvConfirm } from '../../../common/components/LrvConfirm/LrvConfirm';
import { LrvTextArea } from '../../../common/components/LrvTextArea/LrvTextArea';
import { formatMonthDayYear, formatMonthYear } from '../../../utils/date';

import './DetailPayment.scss';
import styles from './DetailPayment.module.scss';
import { DetailPaymentState } from './interfaces';
import { fetchPayment, rejectPayment, setShowRejectModal, approvePayment } from './detailPaymentSlice';

type TParams = { id: string };

export default function DetailPayment ({ match }: RouteComponentProps<TParams>) {
  const { id: paymentId } = match.params;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [disabledButtonFormReject, setDisabledButtonFormReject] = useState(true);
  const [visiblePopConfirm, setVisiblePopConfirm] = useState(false);

  const detailPayment = useSelector((state: RootState) => state.detailPayment as DetailPaymentState);
  const isLoading = useSelector((state: RootState) => state.detailPayment.isLoading);
  const isLoadingReject = useSelector((state: RootState) => state.detailPayment.isLoadingReject);
  const isLoadingApprove = useSelector((state: RootState) => state.detailPayment.isLoadingApprove);
  const showRejectModal = useSelector((state: RootState) => state.detailPayment.showRejectModal);
  const payment = detailPayment.payment;

  const { breadcrumb } = useSelector((state: RootState) => state.header);

  const accessToken = localStorage.getItem('accessToken');
  const theme = getCurrentTheme();

  useEffect(() => {
    dispatch(fetchPayment(paymentId));
    dispatch(changeHeader({ title: 'payments.detail.titleContainer' }));
  }, [dispatch, paymentId]);

  useEffect(() => {
    if (!(paymentId && payment)) {
      return;
    }

    const breadcrumb: Breadcrumb[] = [{
      id: 'payment-manage',
      name: t('payments.title'),
      url: '/payments',
    }];

    switch (payment.type) {
      case plansTypes.PREPAID: {
        const plan = t('payments.detail.descriptionPayment', { quota: payment.quota, amount: dollarUS.format(payment.amount) });
        const item = {
          id: paymentId,
          name: payment.companyName + ' - ' + plan,
          url: `/payment/manage/${paymentId}`,
        };

        breadcrumb.push(item);
        break;
      }

      case plansTypes.POSTPAID: {
        const date = formatMonthYear(payment.paymentDate);
        const item = {
          id: paymentId,
          name: payment.companyName + ' - ' + date,
          url: `/payment/manage/${paymentId}`,
        };

        breadcrumb.push(item);
        break;
      }
    }

    dispatch(changeBreadcrumb(breadcrumb));
  }, [dispatch, t, paymentId, payment]);

  function renderComment () {
    if (payment.status === transferStatus.REJECTED && payment.comment) {
      return (
        <Row className={styles.row}>
          <LrvText className={styles.field} text={`${t('payments.detail.comment')}:`} theme={theme} />
          &nbsp;
          <LrvText className={styles.value} text={payment.comment} theme={theme} />
        </Row>
      );
    }
    return null;
  }

  function renderStatus () {
    const { color, text } = getPaymentStatus(payment.status);

    if (payment.status) {
      return (
        <Row className={styles.row}>
          <LrvText className={styles.field} text={`${t('payments.detail.status')}:`} theme={theme} />
          &nbsp;
          <LrvTag color={color} className={styles.tag}>{text}</LrvTag>
        </Row>
      );
    }
    return null;
  }

  function renderButtons () {
    let showApproveButton = false;
    let showRejectButton = false;

    switch (payment.status) {
      case transferStatus.PENDING_REVIEW:
        showApproveButton = true;
        showRejectButton = true;
        break;

      case transferStatus.APPROVED:
        showRejectButton = true;
        break;

      case transferStatus.REJECTED:
        showApproveButton = true;
        break;
    }

    const showConfirmApprovePayment = () => {
      const title = t('payments.detail.approvalDescription');
      const idOkButton = 'approve_button';
      const idCancelButton = 'cancel_button';

      lrvConfirm({
        theme: 'light',
        title: title,
        icon: <ExclamationCircleOutlined />,
        okText: t('archive.accept').toUpperCase(),
        cancelText: t('archive.cancel').toUpperCase(),
        okButtonProps: { id: idOkButton },
        cancelButtonProps: { id: idCancelButton },
        onOk () {
          dispatch(approvePayment({ paymentId, paymentType: payment.type }));
        },
      });
    };

    return (
      <Row className={styles.rowButtons}>
        <Space className={styles.space}>
          {showRejectButton &&
            <Button
              className={styles.rejectButton}
              type='primary'
              icon={<Icon name='close-circle' />}
              onClick={() => {
                setDisabledButtonFormReject(true);
                form.resetFields();
                dispatch(setShowRejectModal(true));

                if (visiblePopConfirm) {
                  setVisiblePopConfirm(false);
                }
              }}
            >
              &nbsp;{t('payments.detail.reject')}
            </Button>}

          {showApproveButton &&
            <Button
              className={styles.approveButton}
              type='primary'
              icon={<Icon name='checkbox-circle' />}
              onClick={showConfirmApprovePayment}
            >
              &nbsp;{t('payments.detail.approve')}
            </Button>
          }
        </Space>
      </Row>
    );
  }

  const renderCompany = () => {
    return (
      <Row className={styles.row}>
        <LrvText className={styles.field} text={`${t('payments.detail.company')}:`} theme={theme} />
        &nbsp;
        <LrvText className={styles.value} text={payment.companyName} theme={theme} />
      </Row>
    );
  };

  const renderUser = () => {
    if (payment.type === plansTypes.POSTPAID) {
      return null;
    }

    return (
      <Row className={styles.row}>
        <LrvText className={styles.field} text={`${t('payments.detail.user')}:`} theme={theme} />
        &nbsp;
        <LrvText className={styles.value} text={`${payment.userPayer?.firstName} ${payment.userPayer?.lastName}`} theme={theme} />
      </Row>
    );
  };

  const renderPaymentDate = () => {
    return (
      <Row className={styles.row}>
        <LrvText className={styles.field} text={`${t('payments.paymentDate')}:`} theme={theme} />
        &nbsp;
        <LrvText className={styles.value} text={formatMonthDayYear(payment.paymentDate)} theme={theme} />
      </Row>
    );
  };

  const renderExtraInformation = () => {
    if (payment.type === plansTypes.POSTPAID) {
      return null;
    }

    const plan = t('payments.detail.descriptionPayment', { quota: payment.quota, amount: dollarUS.format(payment.amount) });

    return (
      <Row className={styles.row}>
        <LrvText className={styles.field} text={`${t('payments.detail.information')}:`} theme={theme} />
        &nbsp;
        <LrvText className={styles.value} text={plan} theme={theme} />
      </Row>
    );
  };

  const renderDetail = () => {
    if (!payment?._id) {
      return null;
    }

    return (
      <div className={styles.row}>
        <div className={styles.columnText}>
          <div className={styles.descriptionContainer}>
            {renderCompany()}
            {renderUser()}
            {renderPaymentDate()}
            {renderExtraInformation()}
            {renderStatus()}
            {renderComment()}
          </div>
        </div>

        {
          payment.image?.url &&
          <div className={styles.imageContainer}>
            <Image
              className={styles.image}
              alt='voucher'
              src={payment.image.url}
            />
            <div className={styles.buttonContainer}>
              {renderButtons()}
            </div>
          </div>
        }
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner}>
          <DotSpinner />
        </div>
      </div>
    );
  };

  const renderSubHeader = () => {
    if (!accessToken) {
      return null;
    }

    return (
      <Row className={styles.rowHeader} align='middle' justify='space-between'>
        <Breadcrumbs breadcrumb={breadcrumb} theme={theme} />
      </Row>
    );
  };

  const renderBody = () => {
    if (isLoading || isLoadingApprove) {
      return renderLoading();
    }

    return (
      <>
        {renderSubHeader()}

        <Row>
          {renderDetail()}
        </Row>
      </>
    );
  };

  return (
    <div className={styles.detailPayment}>
      {renderBody()}

      <LrvModal
        theme='light'
        className={styles.rejectionModal}
        open={showRejectModal}
        title={t('payments.detail.titleRejectModal')}
        destroyOnClose={true}
        okButtonProps={{ disabled: disabledButtonFormReject, htmlType: 'submit', form: 'formRejectPayment', loading: isLoadingReject }}
        okText={t('payments.detail.reject')}
        cancelText={t('payments.detail.cancel')}
        onOk={() => {
          const fields = form.getFieldsValue();
          if (!fields.comment) {
            return;
          }

          dispatch(rejectPayment({ paymentId, comment: fields.comment, paymentType: payment.type }));
        }}
        onCancel={() => {
          dispatch(setShowRejectModal(false));
        }}
      >
        <LrvForm
          form={form}
          theme='light'
          name='formRejectPayment'
          id='formRejectPayment'
          layout='vertical'
          onFieldsChange={() => {
            setDisabledButtonFormReject(
              !form.isFieldsTouched(true) ||
              form.getFieldsError().filter(({ errors }) => errors.length).length > 0
            );
          }}
        >
          <Form.Item
            className={styles.commentLabel}
            label={t('payments.detail.comment')}
            name='comment'
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <LrvTextArea
              rows={3}
              theme='light'
              placeholder={t('payments.detail.placeholderComment')}
            />
          </Form.Item>

          <div className={styles.rejectionLabel}>
            {t('payments.detail.rejectionLabel')}
          </div>
        </LrvForm>
      </LrvModal>
    </div>
  );
}
