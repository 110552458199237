import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { Dropdown, Menu, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { RootState } from '../../../state/store';
import { getDividedReferences } from '../helpers';
import Icon from '../../../common/components/Icon/Icon';
import { referenceOptions } from '../../../config/commons';
import { ReferenceCurves } from '../../Units/interfaces';
import { formatMonthDayYear } from '../../../utils/date';
import { ReferenceCurvesModal } from '../ReferenceCurvesModal';
import { getParameter } from '../../../helpers/stocking.helpers';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import { ExtraActionsButton } from '../../../common/components/buttons/ExtraActionsButton';

import styles from './GlobalReferenceCurves.module.scss';
import * as globalReferenceCurvesSlice from './GlobalReferenceCurvesSlice';

interface Props {
  theme?: 'dark' | 'light';
}

const GlobalReferenceCurves = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { _id: companyId } = useSelector((state: RootState) => state.header.company);
  const { phaseType, isLoading, referenceCurves, showModal: showReferenceCurvesModal } = useSelector((state: RootState) => state.globalReferenceCurves);

  useEffect(() => {
    dispatch(globalReferenceCurvesSlice.fetchGlobalReferenceCurves());

    return () => {
      dispatch(globalReferenceCurvesSlice.setReferenceCurves([]));
    };
  }, [dispatch, companyId, phaseType]);

  const columns: ColumnProps<ReferenceCurves>[] = [
    {
      key: 1,
      title: t('references.table.name'),
      dataIndex: 'name',
      width: '25%',
    },
    {
      key: 2,
      title: t('references.table.phase'),
      dataIndex: 'phaseType',
      width: '15%',
      responsive: ['md'] as Breakpoint[],
      render: (_, record: ReferenceCurves) => t(`stockings.phaseTypes.${record.phaseType}`),
    },
    {
      key: 3,
      title: t('references.metricTypeSelect'),
      dataIndex: 'type',
      width: '20%',
      render: (_, record: ReferenceCurves) => getParameter({ parameter: record.type, stockingPhaseType: '', addUnit: false }),
    },
    {
      key: 4,
      title: t('references.table.creationDate'),
      width: '20%',
      render: (_, record: ReferenceCurves) => formatMonthDayYear(record.createdAt),
    },
    {
      key: 5,
      title: t('references.table.lastUpdate'),
      width: '20%',
      render: (_, record: ReferenceCurves) => formatMonthDayYear(record.updatedAt),
    },
    {
      key: 6,
      width: '5%',
      render: (_, record: ReferenceCurves) => {
        return (
          <Dropdown
            overlay={() => menuItemOptions(record)}
            trigger={['click']}
            placement='bottomRight'
          >
            <ExtraActionsButton
              id={`button_${record._id}`}
              onClick={(e) => e.stopPropagation()}
            />
          </Dropdown>
        );
      },
    }
  ];

  const menuItemOptions = (record: ReferenceCurves) => {
    return (
      // eslint-disable-next-line
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          if (key === referenceOptions.SHOW) {
            const reference = getDividedReferences({ referenceCurves: cloneDeep(record) });
            dispatch(globalReferenceCurvesSlice.setSelectedReferenceCurve(reference));
            dispatch(globalReferenceCurvesSlice.setShowModal(true));
          }
        }}
      >
        <Menu.Item id='menu_option_show' key={referenceOptions.SHOW} className={styles.menuItemOptions}>
          <Icon name='bar-chart-box' type='line' theme={theme} className={styles.icon} />
          <span>{t('references.show')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div className={styles.container} >
      <Row className={styles.rowFlex} >
        <LrvTable
          columns={columns}
          className={styles.table}
          rowClassName={styles.tableRow}
          dataSource={referenceCurves}
          loading={isLoading}
          scroll={{ y: '' }}
          size='small'
          theme={theme}
          pagination={false}
        />
      </Row>

      <ReferenceCurvesModal
        isLoading={false}
        onClose={() => {
          dispatch(globalReferenceCurvesSlice.setShowModal(false));
          dispatch(globalReferenceCurvesSlice.setSelectedReferenceCurve({} as ReferenceCurves));
        }}
        showModal={showReferenceCurvesModal}
        currentTab='GLOBAL_REFERENCE_CURVES'
        readOnly={true}
      />
    </div>
  );
};

export default GlobalReferenceCurves;