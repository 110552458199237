import cx from 'classnames';
import moment from 'moment';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef, useCallback, RefObject } from 'react';

import { DataSource } from '../../home/interfaces';
import { formatUTCDate } from '../../../utils/date';
import { Stocking as IStocking } from '../interfaces';
import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { handleResizeEvent } from '../../../utils/dimensions';
import { getMinStage, getMaxStage } from '../../Analysis/helpers';
import { fetchAnalysis, fetchGrowthDelta } from '../../home/homeSlice';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import * as stockingAnalysisSlice from '../Analysis/stockingAnalysisSlice';
import D3ShadedPlot from '../../../common/components/charts/ShadedPlot/D3ShadedPlot';
import { groupStagesByLunarPhase, incrementLastItem } from '../../../helpers/lunar-age';
import FactorKChartD3 from '../../../common/components/charts/FactorKChart/FactorKChartD3';
import GrowthDeltaChart from '../../../common/components/charts/ShadedPlot/GrowthDeltaChart';
import { calcDensity, getLabelAxisX, getUnitDensity } from '../../../helpers/stocking.helpers';
import { stockingPhaseTypes, THEME, analysisTypes, MINIMUM_DATE_FACTOR_K_CHART } from '../../../config/commons';
import { colorsStroke, colorsPoints, typeParam, groupPointsByStage, rectColor, typesChart, calcDeltaStages, sortDeltaData, injectPigmentationToPoints, calcReferenceCurveStages, lineColor, selectedTickStoke, isReferenceCurveMetric } from '../../../common/components/charts/ShadedPlot/helpers';

import styles from './StockingComparison.module.scss';
import { getHeightOfTheOtherElements, getWidthOfTheOtherElements, hasAtLeastThreeDifferentStages } from './helpers';

let chart: D3ShadedPlot | GrowthDeltaChart | FactorKChartD3 | null;

interface Props {
  refFilters: RefObject<HTMLDivElement>;
  refLegends: RefObject<HTMLDivElement>;
  stockingDefault: IStocking;
}

export const StockingComparison = (props: Props) => {
  const { stockingDefault, refFilters, refLegends } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const refChartMain = useRef<HTMLDivElement>(null);
  const refSlider = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(window.innerWidth - getWidthOfTheOtherElements());
  const [height, setHeight] = useState(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, legends: refLegends, slider: refSlider }));
  const [showTable, setShowTable] = useState(false);

  const metrics = useSelector((state: Store) => state.metrics);
  const isFetchMetrics = useSelector((state: Store) => state.metrics.isFetchMetrics);
  const isFetchAnalysis = useSelector((state: Store) => state.metrics.isFetchAnalysis);

  const {
    reference,
    firstStage,
    lastStage,
    parameter,
    scale,
    movingAverage,
    showMovingAverage,
    showDerivative,
    listStockingsIds,
    listStockingsName,
    enabledStockings,
    listStockingsDensity,
    isLoadingFetchActiveStockings,
    maxStage,
    containerSelected,
    referenceCurves,
  } = useSelector((state: Store) => state.stockingAnalysis);
  const { company, phaseType: phaseTypeSelected } = useSelector((state: Store) => state.header);

  const [amountAnalysis, setAmountAnalysis] = useState<number>(0);

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;
  const colorFillRect = isLightTheme ? rectColor.light : rectColor.dark;
  const colorLine = isLightTheme ? lineColor.light : lineColor.dark;
  const tickStroke = isLightTheme ? selectedTickStoke.light : selectedTickStoke.dark;

  const showFactorKChart = hasAtLeastThreeDifferentStages({ points: metrics.analysis, targetType: analysisTypes.CONSOLIDATED_ADULT_ANALYSIS });
  const isValidStartDateGrowOut = moment(stockingDefault.startDateGrowOut).isAfter(moment(MINIMUM_DATE_FACTOR_K_CHART));

  const sortStockings = useCallback(() => {
    const stockingGroupById = groupBy(metrics.analysis, 'sowingId');
    const dataSource: DataSource[] = [];
    const dataSourceSort: DataSource[] = [];

    for (const key in stockingGroupById) {
      if (Object.prototype.hasOwnProperty.call(stockingGroupById, key)) {
        const index = listStockingsIds.indexOf(key);
        const stockingName = listStockingsName[index] || stockingDefault.name;
        const enabled = enabledStockings[index] ?? true;
        const density = listStockingsDensity[index];
        const points = stockingGroupById[key];
        const newPoints = injectPigmentationToPoints({ points, parameter });

        const stockingGroup: DataSource = { id: key, name: stockingName, enabled: enabled, show: true, points: newPoints, avgPoint: [], density };
        dataSource.push(stockingGroup);
      }
    }

    if (listStockingsIds.length === 0) {
      const data = dataSource.find(value => value.id === stockingDefault._id);
      if (!data) {
        return dataSourceSort;
      }

      const density = calcDensity(stockingDefault);
      const unitDensity = getUnitDensity(stockingDefault);

      data.enabled = true;
      data.avgPoint = groupPointsByStage({ points: data.points, parameter });
      data.density = density + ' ' + unitDensity;

      dataSourceSort.push(data);
      return dataSourceSort;
    }

    for (let index = 0; index < listStockingsIds.length; index++) {
      const data = dataSource.find(stocking => stocking.id === listStockingsIds[index]);
      if (data) {
        data.avgPoint = groupPointsByStage({ points: data.points, parameter });
        dataSourceSort.push(data);
      } else {
        const id = listStockingsIds[index];
        const name = listStockingsName[index];
        const enabled = enabledStockings[index] || true;
        const density = listStockingsDensity[index];

        const stockingGroup: DataSource = { id, name, enabled, avgPoint: [], points: [], density };
        dataSourceSort.push(stockingGroup);
      }
    }
    return dataSourceSort;
  }, [metrics.analysis, stockingDefault._id, enabledStockings, listStockingsIds, listStockingsName, parameter]);

  useEffect(() => {
    return () => {
      chart = null;
    };
  }, [dispatch]);

  useEffect(() => {
    if (!parameter || !containerSelected) {
      setShowTable(false);
      return;
    }

    const timer = setTimeout(() => {
      setShowTable(true);
    }, 10);

    return () => {
      clearTimeout(timer);
    };
  }, [parameter, containerSelected, amountAnalysis, isFetchMetrics, isFetchAnalysis]);

  useEffect(() => {
    if (!stockingDefault._id || !stockingDefault.companyId) {
      return;
    }

    if (phaseTypeSelected !== stockingPhaseTypes.LARVAE && parameter === typeParam.PLG) {
      dispatch(stockingAnalysisSlice.setParameter(typeParam.AVG_WEIGHT));
    }
  }, [stockingDefault._id, stockingDefault.companyId, phaseTypeSelected]);

  useEffect(() => {
    if (!maxStage || isLoadingFetchActiveStockings) {
      return;
    }

    if (listStockingsIds.length === 0) {
      const stockingsId: string[] = [stockingDefault._id];
      dispatch(fetchAnalysis({ stockingsId, showLoading: true, maxStage }));
      return;
    }

    dispatch(fetchAnalysis({ stockingsId: listStockingsIds, showLoading: false, maxStage }));
  }, [dispatch, stockingDefault._id, listStockingsIds, company._id, maxStage, isLoadingFetchActiveStockings]);

  useEffect(() => {
    if (parameter !== typeParam.GROWTH_DELTA) {
      return;
    }
    dispatch(fetchGrowthDelta(listStockingsIds.length > 0 ? listStockingsIds : stockingDefault._id));
  }, [dispatch, listStockingsIds, parameter, stockingDefault._id, stockingDefault.companyId]);

  useEffect(() => {
    if (!refLegends.current?.offsetHeight || isFetchMetrics || isFetchAnalysis) {
      return;
    }

    setWidth(window.innerWidth - getWidthOfTheOtherElements());
    setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, legends: refLegends, slider: refSlider }));
  }, [refLegends.current?.offsetHeight, isFetchAnalysis, isFetchMetrics]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth - getWidthOfTheOtherElements());
      setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, legends: refLegends, slider: refSlider }));
    });
  }, []);

  useEffect(() => {
    chart && chart.resize(width, height);
  }, [width, height]);

  useEffect(() => {
    const updateRange = () => {
      const stockingGroupList = sortStockings();
      let firstStage = 0;
      let lastStage = 0;

      if (stockingGroupList.length === 0) {
        return;
      }

      if (parameter !== typeParam.GROWTH_DELTA) {
        firstStage = getMinStage(phaseTypeSelected);
        lastStage = getMaxStage(company, phaseTypeSelected);
      }

      if (parameter === typeParam.GROWTH_DELTA) {
        const deltaStockings = sortDeltaData(metrics.growthDelta, stockingDefault.name, listStockingsName, enabledStockings);
        const stages = calcDeltaStages(deltaStockings, maxStage);

        firstStage = stages[0];
        lastStage = stages[1];
      }

      if (isReferenceCurveMetric(reference)) {
        const stages = calcReferenceCurveStages({ stockingGroupList, maxStage });

        firstStage = stages.firstStage;
        lastStage = stages.lastStage;
      }

      dispatch(stockingAnalysisSlice.setFirstStage(firstStage));
      dispatch(stockingAnalysisSlice.setLastStage(lastStage));

      dispatch(stockingAnalysisSlice.setFirstStageZoom(firstStage));
      dispatch(stockingAnalysisSlice.setLastStageZoom(lastStage));
    };

    if ((parameter === typeParam.GROWTH_DELTA || parameter === typeParam.PIGMENTATION) && metrics.analysis.length > 0) {
      updateRange();
    }

    if (parameter !== typeParam.GROWTH_DELTA && metrics.analysis.length > 0) {
      setAmountAnalysis(metrics.analysis.length);
      updateRange();
    }
  }, [reference, metrics.analysis, referenceCurves, metrics.growthDelta, parameter, amountAnalysis, sortStockings, maxStage, stockingDefault.name, enabledStockings, listStockingsName]);

  useEffect(() => {
    const getData = () => {
      if (parameter === typeParam.GROWTH_DELTA) {
        return metrics.growthDelta;
      }

      switch (reference) {
        default:
          return referenceCurves.values;
      }
    };

    const showChartMain = () => {
      const data = getData();
      const stockingGroupList: DataSource[] = sortStockings();

      let deltaStockings = [];
      if (parameter === typeParam.GROWTH_DELTA) {
        if (data.length === 0) {
          return;
        }

        deltaStockings = sortDeltaData(data, stockingDefault.name, listStockingsName, enabledStockings);

        if (!chart || chart instanceof D3ShadedPlot || chart instanceof FactorKChartD3) {
          chart = new GrowthDeltaChart({
            colorFillRect,
            colorLine,
            companyData: company,
            container: refChartMain.current,
            dataMetric: deltaStockings,
            firstStage,
            height,
            lastStage,
            parameter: typeParam.GROWTH_DELTA,
            phaseType: phaseTypeSelected,
            scale,
            showLabels: false,
            typeChart: typesChart.STOCKINGS,
            width,
            movingAverage,
            showMovingAverage: showMovingAverage && listStockingsName.length === 0,
            selectedTickStoke: tickStroke,
            dispatch,
          });
        }

        if (chart instanceof GrowthDeltaChart) {
          chart.refreshChart({
            dataMetric: deltaStockings,
            scale,
            firstStage,
            lastStage,
            width,
            height,
            movingAverage,
            showMovingAverage: showMovingAverage && listStockingsName.length === 0,
            colorFillRect,
            colorLine,
          });
        }
        return;
      }

      if (stockingGroupList.length === 0) {
        return;
      }

      if (parameter === typeParam.FACTOR_K) {
        const firstLunarAge = metrics.analysis[0].resultData.lunarAge;
        let lunarPhaseStages = groupStagesByLunarPhase({ lunarAge: firstLunarAge, minDay: firstStage, maxDay: lastStage });
        lunarPhaseStages = incrementLastItem(lunarPhaseStages);

        if (!chart || chart instanceof GrowthDeltaChart || chart instanceof D3ShadedPlot) {
          chart = new FactorKChartD3({
            colorFillRect,
            colorLine,
            selectedTickStoke: tickStroke,
            container: refChartMain.current,
            dataMetric: data,
            dataSource: stockingGroupList,
            firstStage,
            height,
            lastStage,
            scale,
            typeMetric: reference,
            width,
            colorsPoints,
            colorsStroke,
            movingAverage,
            lunarPhaseStages,
            showMovingAverage: showMovingAverage && listStockingsName.length === 0,
            dispatch,
          });
          return;
        }

        if (chart instanceof FactorKChartD3) {
          chart.refreshChart({
            dataMetric: data,
            dataSource: stockingGroupList,
            firstStage,
            lastStage,
            width,
            height,
            colorsPoints,
            colorsStroke,
            typeMetric: reference,
            scale, movingAverage,
            colorFillRect,
            colorLine,
            lunarPhaseStages,
            showMovingAverage: showMovingAverage && listStockingsName.length === 0,
            showDerivative: showDerivative && listStockingsName.length === 0,
            selectedTickStoke: tickStroke,
          });
          return;
        }
      }

      if (!chart || chart instanceof GrowthDeltaChart || chart instanceof FactorKChartD3) {
        chart = new D3ShadedPlot({
          colorFillRect,
          colorLine,
          companyData: company,
          container: refChartMain.current,
          dataMetric: data,
          dataSource: stockingGroupList,
          firstStage,
          height,
          lastStage,
          parameter,
          phaseType: phaseTypeSelected,
          scale,
          showLabels: false,
          typeChart: typesChart.STOCKINGS,
          typeMetric: reference,
          width,
          colorsPoints,
          colorsStroke,
          movingAverage,
          showMovingAverage: showMovingAverage && listStockingsName.length === 0,
          selectedTickStoke: tickStroke,
          dispatch,
        });
        return;
      }

      if (chart instanceof D3ShadedPlot) {
        chart.refreshChart({
          dataMetric: data,
          dataSource: stockingGroupList,
          firstStage,
          lastStage,
          width,
          height,
          phaseType: phaseTypeSelected,
          colorsPoints,
          colorsStroke,
          parameter,
          typeMetric: reference,
          scale,
          movingAverage,
          showMovingAverage: showMovingAverage && listStockingsName.length === 0,
          colorFillRect,
          colorLine,
          selectedTickStoke: tickStroke
        });
        return;
      }
    };

    if (!isFetchMetrics && !isLoadingFetchActiveStockings && metrics.analysis.length > 0) {
      showChartMain();
    }

  }, [dispatch, sortStockings, isFetchMetrics, company, stockingDefault, metrics, parameter, reference, scale, firstStage, lastStage, width, height, listStockingsName, enabledStockings, movingAverage, showMovingAverage, colorFillRect, colorFillRect, colorLine, tickStroke, theme, showDerivative]);

  useEffect(() => {
    if (!company._id) {
      return;
    }

    switch (phaseTypeSelected) {
      case stockingPhaseTypes.LARVAE:
        dispatch(stockingAnalysisSlice.setMaxStage(company.maxStage));
        break;

      case stockingPhaseTypes.JUVENILE:
        dispatch(stockingAnalysisSlice.setMaxStage(company.maxDayJuvenile));
        break;

      case stockingPhaseTypes.ADULT:
        dispatch(stockingAnalysisSlice.setMaxStage(company.maxDayGrowOut));
        break;
    }
  }, [company, phaseTypeSelected]);

  const showOptions = () => {
    if (isFetchAnalysis || isFetchMetrics) {
      return (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}>
            <DotSpinner />
          </div>
        </div>
      );
    }

    if (metrics.analysis && !metrics.analysis.length) {
      return (
        <div className={styles.center} >
          <LrvEmpty theme={theme} description={t('shadedplot.analysis.empty')} />
        </div>
      );
    }

    if (!metrics.analysis.length && !isFetchMetrics) {
      return (
        <div className={styles.center} >
          <LrvEmpty theme={theme} description={t('shadedplot.growOut')} />
        </div>
      );
    }

    if (parameter === typeParam.FACTOR_K) {
      if (!isValidStartDateGrowOut) {
        return (
          <div className={styles.center} >
            <LrvEmpty theme={theme} description={t('shadedplot.invalidStartDateGrowOut', { date: formatUTCDate(MINIMUM_DATE_FACTOR_K_CHART.toString()) })} />
          </div>
        );
      }

      if (!showFactorKChart) {
        return (
          <div className={styles.center} >
            <LrvEmpty theme={theme} description={t('shadedplot.insufficientAnalysis')} />
          </div>
        );
      }
    }

    return null;
  };

  return (
    <div id='stockingComparisonContainer' className={styles.container}>
      {showOptions()}

      <div ref={refChartMain} className={isFetchAnalysis || isFetchMetrics || (parameter === typeParam.FACTOR_K && (!showFactorKChart || !isValidStartDateGrowOut)) ? styles.hideChart : styles.showChart} />
      {
        ((metrics.analysis.length > 0) && !isFetchAnalysis && !isFetchMetrics && !(parameter === typeParam.FACTOR_K && (!showFactorKChart || !isValidStartDateGrowOut)) && showTable) &&
        <div className={cx(styles.labelAxisX, isLightTheme ? styles.axisLight : styles.axisDark)}>{getLabelAxisX(phaseTypeSelected)}</div>
      }
    </div>
  );
};
