import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import { Store } from '../../../state/store.interfaces';
import { getDaysToInitialStage } from '../../../helpers/analysis.helpers';
import { formatMonthDayYear, formatUTCDate, formatUTCHour } from '../../../utils/date';
import { analysisTypes, animalDaysUnit, roundLength, roundWeight, stockingPhaseTypes } from '../../../config/commons';
import { getStartDateStocking, calcDensity, getUnitDensity, fixStockingStartDate, calcAvgGrowth } from '../../../helpers/stocking.helpers';

import styles from './Data.module.scss';

export default function DataDetail (props: { hours: number }) {
  const { hours } = props;

  const [t] = useTranslation();
  const { company } = useSelector((state: Store) => state.header);
  const { analysis: analysisOriginal } = useSelector((state: Store) => state.detailAnalysis);
  const daysToInitialStage = getDaysToInitialStage({ company, daysToInitialStage: analysisOriginal.sowingId?.daysToInitialStage });

  const days = analysisOriginal.inputData?.stage + (daysToInitialStage - 1);
  const dictType = analysisOriginal.phaseType === stockingPhaseTypes.LARVAE ? 'larvae' : 'juvenile';
  const stage = analysisOriginal.phaseType === stockingPhaseTypes.LARVAE ? `${animalDaysUnit.PL} ${analysisOriginal.inputData?.stage} - ${days} ${t('analysis.days')}` : `${analysisOriginal.inputData?.stage} ${t('analysis.days')}`;
  const uniformityWeight = analysisOriginal.resultData.uniformity;
  const uniformityLength = 100 - analysisOriginal.resultData.variationCoefficientLength;

  function renderAnalysisDate () {
    const date = moment(analysisOriginal.createdAt).utc().toString();

    return (

      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {formatUTCDate(date, hours)} {formatUTCHour(date, hours)}
        </Text>
        <Text className={styles.field}>
          {t('analysis.createdAt')}
        </Text>
      </div>
    );
  }

  const renderStockingStartDate = () => {
    const stocking = analysisOriginal.sowingId;
    if (!stocking) {
      return null;
    }

    const startDate = getStartDateStocking({ stocking, phaseType: analysisOriginal.phaseType });
    const startDateFixed = fixStockingStartDate(startDate, hours);

    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {formatMonthDayYear(startDateFixed)}
        </Text>
        <Text className={styles.field}>
          {t('stockings.startDate')}
        </Text>
      </div>
    );
  };

  const renderAvgGrowth = () => {
    if (analysisOriginal.type !== analysisTypes.GENERAL_ADULT_ANALYSIS && analysisOriginal.type !== analysisTypes.CONSOLIDATED_ADULT_ANALYSIS) {
      return null;
    }

    const averageWeight = analysisOriginal.resultData.averageWeight;
    const stage = analysisOriginal.inputData.stage;
    const gramPerWeek = calcAvgGrowth({ averageWeight, stage, phaseType: analysisOriginal.phaseType });

    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {gramPerWeek}
        </Text>
        <Text className={styles.field}>
          {t('analysis.resultData.avgGrowth')}
        </Text>
      </div>
    );
  };

  const renderDensity = () => {
    const stocking = analysisOriginal.sowingId;
    if (!stocking) {
      return null;
    }

    const density = calcDensity(stocking);
    const densityUnit = getUnitDensity(stocking);

    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {density} {densityUnit}
        </Text>
        <Text className={styles.field}>
          {t('detail.extraInformation.density')}
        </Text>
      </div>
    );
  };

  const renderAnimalsNumber = () => {
    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {analysisOriginal.resultData?.larvaeNumber}
        </Text>
        <Text className={styles.field}>
          {t(`analysis.resultData.${dictType}Number`)}
        </Text>
      </div>
    );
  };

  const renderLarvaePerGram = () => {
    if (analysisOriginal.phaseType !== stockingPhaseTypes.LARVAE) {
      return null;
    }

    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {analysisOriginal.resultData?.larvaePerGram}
        </Text>
        <Text className={styles.field}>
          {t('analysis.resultData.larvaePerGram')}
        </Text>
      </div>
    );
  };

  const renderStage = () => {
    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {stage}
        </Text>
        <Text className={styles.field}>
          {t(`analysis.inputData.${dictType}Stage`)}
        </Text>
      </div>
    );
  };

  const renderMaturationName = () => {
    if (!analysisOriginal.inputData?.maturationId?.name) {
      return null;
    }

    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {analysisOriginal.inputData.maturationId.name}
        </Text>
        <Text className={styles.field}>
          {t('analysis.inputData.maturation')}
        </Text>
      </div>
    );
  };

  const renderAverageWeight = () => {
    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {roundWeight({ value: analysisOriginal.resultData?.averageWeight })}
        </Text>
        <Text className={styles.field}>
          {`${t('detail.weight')} ${t('analysis.resultData.average')}`}
        </Text>
      </div>
    );
  };

  const renderUniformityWeight = () => {
    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {uniformityWeight.toFixed(2)} %
        </Text>
        <Text className={styles.field}>
          {`${t('analysis.resultData.unif')} ${t('detail.weight')}`}
        </Text>
      </div>
    );
  };

  const renderSampleWeight = () => {
    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {analysisOriginal.inputData?.sampleWeight} g
        </Text>
        <Text className={styles.field}>
          {t('analysis.inputData.sampleWeight')}
        </Text>
      </div>
    );
  };

  const renderAverageLength = () => {
    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {roundLength({ value: analysisOriginal.resultData?.averageLength })}
        </Text>
        <Text className={styles.field}>
          {`${t('detail.length')} ${t('analysis.resultData.average')}`}
        </Text>
      </div>
    );
  };

  const renderUniformityLength = () => {
    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {uniformityLength.toFixed(2)} %
        </Text>
        <Text className={styles.field}>
          {`${t('analysis.resultData.unif')} ${t('detail.length')}`}
        </Text>
      </div>
    );
  };

  const renderCVWeight = () => {
    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {analysisOriginal.resultData.variationCoefficient.toFixed(2)} %
        </Text>
        <Text className={styles.field}>
          {t('analysis.resultData.variationCoefficient')}
        </Text>
      </div>
    );
  };

  const renderCVLength = () => {
    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {analysisOriginal.resultData.variationCoefficientLength.toFixed(2)} %
        </Text>
        <Text className={styles.field}>
          {t('analysis.resultData.variationCoefficientLength')}
        </Text>
      </div>
    );
  };

  return <div className={styles.gridContainer}>
    <div className={styles.row}>
      {renderAnimalsNumber()}
      {renderLarvaePerGram()}
      {renderStage()}
      {renderAverageWeight()}
      {renderAverageLength()}
      {renderAvgGrowth()}
      {renderDensity()}
      {renderAnalysisDate()}
    </div>

    <div className={styles.row}>
      {renderUniformityWeight()}
      {renderUniformityLength()}
      {renderCVWeight()}
      {renderCVLength()}
      {renderSampleWeight()}
      {renderMaturationName()}
      {renderStockingStartDate()}
    </div>

  </div>;
}
