import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import { getMinStage } from '../../Analysis/helpers';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { DataSource, Point } from '../../home/interfaces';
import { changeHeader } from '../../AppHeader/headerSlice';
import { handleResizeEvent } from '../../../utils/dimensions';
import { stockingPhaseTypes, THEME } from '../../../config/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { MaturationStageMetrics } from '../../ActivityDashboard/interfaces';
import { getLabelAxisX, getParameter } from '../../../helpers/stocking.helpers';
import D3ShadedPlot from '../../../common/components/charts/ShadedPlot/D3ShadedPlot';
import { calcStages, rectColor, colorsPoints, getDefaultDataByStage, getNewColor, metricsStatuses, typeParam, typeScale, typesChart, lineColor, selectedTickStoke } from '../../../common/components/charts/ShadedPlot/helpers';

import styles from './GeneticsAnalysis.module.scss';
import * as geneticsAnalysisSlice from './geneticsAnalysisSlice';
import { GeneticsAnalysisFilters } from './GeneticsAnalysisFilters';
import { getHeightOfTheOtherElements, getWidthOfTheOtherElements } from './helpers';
import { StockingsInfoModal } from './StockingsInfoModal';

let chart: D3ShadedPlot | null;
const maxSelectedMaturation = 10;

const GeneticsAnalysis = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const refChart = useRef<HTMLDivElement>(null);
  const refFilters = useRef<HTMLDivElement>(null);
  const refMadurationPanel = useRef<HTMLDivElement>(null);
  const refSlider = useRef<HTMLDivElement>(null);

  const {
    hasMinimumAnalysis,
    metrics,
    isLoadingMaturationMetrics,
    isLoadingCompanyMaturations,
    maturations,
    filters,
    dataSource,
    firstStage,
    lastStage,
  } = useSelector((state: Store) => state.geneticsAnalysis);

  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);

  const {
    scale,
    parameter,
    minimumDate,
    maximumDate,
    unit,
    referenceCurve,
  } = filters;

  const { maturationMetrics, maturationCodeMetrics } = metrics;

  const [amountMaturation, setAmountMaturation] = useState<number>(0);

  const [height, setHeight] = useState(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, slider: refSlider }));
  const [width, setWidth] = useState(window.innerWidth - getWidthOfTheOtherElements({ maturationPanel: refMadurationPanel }));

  const minStage = getMinStage(phaseType);
  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;
  const colorFillRect = isLightTheme ? rectColor.light : rectColor.dark;
  const colorLine = isLightTheme ? lineColor.light : lineColor.dark;
  const tickStroke = isLightTheme ? selectedTickStoke.light : selectedTickStoke.dark;

  useEffect(() => {
    dispatch(changeHeader({ title: 'geneticsInsights.title' }));

    return () => {
      chart = null;
      dispatch(geneticsAnalysisSlice.resetGeneticsAnalysis());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(geneticsAnalysisSlice.fetchGlobalReferenceCurves({ type: parameter, phaseType }));
  }, [dispatch, phaseType, parameter]);

  useEffect(() => {
    if (!selectedCompany._id) {
      return;
    }

    dispatch(geneticsAnalysisSlice.fetchCompanyReferenceCurves({ companyId: selectedCompany._id, type: parameter, phaseType }));
  }, [dispatch, selectedCompany._id, phaseType, parameter]);

  useEffect(() => {
    dispatch(geneticsAnalysisSlice.setDataSource([]));

    if (parameter === typeParam.PLG && phaseType !== stockingPhaseTypes.LARVAE) {
      dispatch(geneticsAnalysisSlice.setParameter(typeParam.AVG_WEIGHT));
    }
  }, [phaseType]);

  useEffect(() => {
    if (!selectedCompany._id) {
      return;
    }

    dispatch(geneticsAnalysisSlice.fetchCompanyActivity(selectedCompany._id));
  }, [dispatch, selectedCompany._id]);

  useEffect(() => {
    if (maturations.length === 0 || !selectedCompany._id) {
      return;
    }

    const params = { companyId: selectedCompany._id, type: parameter, phaseType, minimumDate, maximumDate, unitId: unit };
    dispatch(geneticsAnalysisSlice.fetchMaturationMetrics(params));
  }, [dispatch, selectedCompany._id, parameter, maturations, phaseType, minimumDate, maximumDate, unit]);

  useEffect(() => {
    const maturationDataSource: DataSource[] = [];

    let index = 0;
    for (const [maturationName, maturationStageMetrics] of Object.entries(maturationMetrics)) {
      const maturationByStageList: Point[] = [];

      const dataSourceSelected = dataSource.find((data) => data.id === maturationName);
      const dataSourceLength = dataSource.filter((data) => data.enabled && data.show).length;

      const color = dataSourceSelected?.color || colorsPoints[index];
      const enabled = dataSourceSelected?.enabled === undefined ? dataSourceLength < maxSelectedMaturation : dataSourceSelected.enabled;
      const show = dataSourceSelected?.show || true;

      for (const [stage, maturationStage] of Object.entries(maturationStageMetrics)) {
        const x: number = parseInt(stage);
        const y: number = maturationStage.mean;
        const analysesNumber = maturationStage.number;
        const maturationByStage: Point = getDefaultDataByStage({ x, y, analysesNumber, stageRange: maturationStage.stageRange });
        maturationByStageList.push(maturationByStage);
      }

      const maturationData: DataSource = { id: maturationName, name: maturationName, color, enabled, show, points: [], avgPoint: maturationByStageList };
      maturationDataSource.push(maturationData);
      index++;
    }

    for (const [maturationName, maturationCodeMetric] of Object.entries(maturationCodeMetrics)) {
      for (const [maturationCode, maturationStage] of Object.entries(maturationCodeMetric)) {
        const key = `${maturationName}_${maturationCode}`;
        const maturationByStageList: Point[] = [];
        const dataSourceSelected = dataSource.find((data) => data.id === key);
        const color = dataSourceSelected?.color;
        const enabled = dataSourceSelected?.enabled || false;
        const show = dataSourceSelected?.show || true;

        // eslint-disable-next-line
        for (const [stage, value] of Object.entries(maturationStage)) {
          const x: number = parseInt(stage);
          const y: number = value.mean;
          const analysesNumber = value.number;
          const maturationByStage: Point = getDefaultDataByStage({ x, y, analysesNumber, stageRange: value.stageRange });
          maturationByStageList.push(maturationByStage);
        }
        const maturationData: DataSource = { id: key, name: `${maturationName}: ${maturationCode}`, color, enabled, show, points: [], avgPoint: maturationByStageList };
        maturationDataSource.push(maturationData);
      }
    }

    dispatch(geneticsAnalysisSlice.setDataSource(maturationDataSource));
  }, [maturationMetrics]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth - getWidthOfTheOtherElements({ maturationPanel: refMadurationPanel }));
      setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, slider: refSlider }));
    });
  }, []);

  useEffect(() => {
    if (!selectedCompany._id || !dataSource.length) {
      return;
    }

    setWidth(window.innerWidth - getWidthOfTheOtherElements({ maturationPanel: refMadurationPanel }));
    setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, slider: refSlider }));
  }, [selectedCompany._id, dataSource]);

  useEffect(() => {
    chart && chart.resize(width, height);
  }, [width, height]);

  useEffect(() => {
    if (!dataSource.length || !hasMinimumAnalysis) {
      return;
    }

    if (dataSource.length === amountMaturation) {
      return;
    }

    const updateRange = () => {
      const stages = calcStages(dataSource, minStage);
      const firstStage = stages[0];
      const lastStage = stages[1];

      dispatch(geneticsAnalysisSlice.setFirstStage(firstStage));
      dispatch(geneticsAnalysisSlice.setLastStage(lastStage));

      dispatch(geneticsAnalysisSlice.setFirstStageZoom(firstStage));
      dispatch(geneticsAnalysisSlice.setLastStageZoom(lastStage));
    };

    setAmountMaturation(dataSource.length);
    updateRange();
  }, [dataSource, amountMaturation, hasMinimumAnalysis]);

  useEffect(() => {
    if (!dataSource.length || !hasMinimumAnalysis || (firstStage === undefined || !lastStage) || !selectedCompany._id) {
      return;
    }

    const renderD3Chart = () => {
      const dataSourceFiltered = dataSource.filter((data) => data.enabled && data.show && data.color);
      const colorsPoints = dataSourceFiltered.map((dataSource) => `${dataSource.color}`);
      const colorsStroke = colorsPoints;

      if (dataSourceFiltered.length === 0 && !!chart) {
        chart.refreshChart({ dataMetric: referenceCurve?.values || [], dataSource: cloneDeep(dataSourceFiltered), firstStage, lastStage, width, height, phaseType, colorsPoints, colorsStroke, parameter, isMaturationCurves: true, typeMetric: metricsStatuses.REFERENCE_CURVE, scale, showMovingAverage: false, colorFillRect, colorLine, selectedTickStoke: tickStroke });
        return;
      }

      if (chart) {
        chart.refreshChart({ dataMetric: referenceCurve?.values || [], dataSource: cloneDeep(dataSourceFiltered), firstStage, lastStage, width, height, phaseType, colorsPoints, colorsStroke, parameter, isMaturationCurves: true, typeMetric: metricsStatuses.REFERENCE_CURVE, scale, showMovingAverage: false, colorFillRect, colorLine, selectedTickStoke: tickStroke });
        return;
      }

      const props = {
        colorFillRect,
        colorLine,
        companyData: selectedCompany,
        container: refChart.current,
        dataMetric: referenceCurve?.values || [],
        dataSource: cloneDeep(dataSourceFiltered),
        firstStage,
        height,
        lastStage,
        parameter,
        phaseType,
        scale: typeScale.LINEAR,
        showLabels: false,
        typeChart: typesChart.MATURATIONS,
        typeMetric: metricsStatuses.REFERENCE_CURVE,
        isMaturationCurves: true,
        width,
        colorsPoints,
        colorsStroke,
        selectedTickStoke: tickStroke,
        showMovingAverage: false,
        dispatch,
      };
      chart = new D3ShadedPlot(props);
    };

    renderD3Chart();
  }, [dispatch, dataSource, scale, parameter, firstStage, lastStage, width, height, selectedCompany, phaseType, hasMinimumAnalysis, colorFillRect, colorLine, tickStroke, referenceCurve]);

  const disabledCheckbox = () => {
    const dataSourceLength = dataSource.filter((data) => data.enabled && data.show).length;
    return dataSourceLength >= maxSelectedMaturation;
  };

  const disabledShowButton = (maturationCodeLength: number) => {
    const dataSourceLength = dataSource.filter((data) => data.enabled && data.show).length;
    return (dataSourceLength + maturationCodeLength) >= maxSelectedMaturation;
  };

  const enableItem = (checked: boolean, key: string) => {
    if (disabledCheckbox() && checked) {
      return;
    }

    const dataSourceCopy: DataSource[] = cloneDeep(dataSource);

    dataSourceCopy.forEach((data) => {
      if (data.id === key) {
        const colors: string[] = [];
        dataSource.forEach((data) => {
          if (data.color && !colors.includes(data.color)) {
            colors.push(data.color);
          }
        });

        const newColor = getNewColor(colors);
        data.enabled = checked;
        data.color = checked ? newColor : undefined;
      }
    });

    dataSourceCopy.sort((a, b) => Number(b.enabled) - Number(a.enabled));
    dispatch(geneticsAnalysisSlice.setDataSource(dataSourceCopy));
  };

  const hideMaturation = (props: {
    show: boolean;
    maturationName: string;
    maturationKey: string;
    maturationCodeMetric?: {
      [key: string]: MaturationStageMetrics;
    }
  }
  ) => {
    const { show, maturationName, maturationKey, maturationCodeMetric } = props;
    const dataSourceCopy: DataSource[] = cloneDeep(dataSource);

    dataSourceCopy.forEach((data) => {
      if (data.id === maturationKey) {
        const colors: string[] = [];
        dataSource.forEach((data) => {
          if (data.color && !colors.includes(data.color)) {
            colors.push(data.color);
          }
        });

        const newColor = getNewColor(colors);
        data.show = show;
        data.color = show ? newColor : undefined;
      }
    });

    if (!maturationCodeMetric) {
      dataSourceCopy.sort((a, b) => Number(b.enabled) - Number(a.enabled));
      dataSourceCopy.sort((a, b) => Number(b.show) - Number(a.show));
      dispatch(geneticsAnalysisSlice.setDataSource(dataSourceCopy));
      return;
    }

    for (const [maturationCode,] of Object.entries(maturationCodeMetric)) {
      const maturationCodeKey = `${maturationName}_${maturationCode}`;
      const dataSourceFind = dataSourceCopy.find((data) => data.id === maturationCodeKey);

      if (!dataSourceFind?.enabled) {
        continue;
      }

      if (dataSourceFind.id === maturationCodeKey) {
        const colors: string[] = [];
        dataSourceCopy.forEach((data) => {
          if (data.color && !colors.includes(data.color)) {
            colors.push(data.color);
          }
        });

        const newColor = getNewColor(colors);
        dataSourceFind.show = show;
        dataSourceFind.color = show ? newColor : undefined;
      }
    }

    dataSourceCopy.sort((a, b) => Number(b.enabled) - Number(a.enabled));
    dataSourceCopy.sort((a, b) => Number(b.show) - Number(a.show));
    dispatch(geneticsAnalysisSlice.setDataSource(dataSourceCopy));
  };

  const renderMaturationOptions = () => {
    const items: JSX.Element[] = [];

    for (const [maturationName, maturationStageMetrics] of Object.entries(maturationMetrics)) {
      if (!(maturationName in maturationCodeMetrics)) {
        const maturationData = dataSource.find((data) => data.id === maturationName);
        const maturationCodeLength = Object.keys(maturationStageMetrics).length;

        const itemMaturation = (
          <div className={styles.header}>
            <Checkbox
              onChange={event => enableItem(event.target.checked, maturationName)}
              checked={(maturationData?.enabled && maturationData?.show)}
              className={(maturationData?.enabled && maturationData?.show) ? cx(styles.checkbox, styles.checkboxEnabled) : styles.checkbox}
              disabled={!maturationData?.show || (!maturationData?.enabled && disabledCheckbox())}
            >
              <div
                className={styles.label}
                style={{ color: (maturationData?.enabled && maturationData?.show) ? `${maturationData?.color}` : '' }}
              >
                {maturationName}
              </div>
            </Checkbox>

            <Button
              id='hide_button'
              type='text'
              icon={<Icon name={maturationData?.show ? 'eye' : 'eye-off'} className={styles.icon} />}
              onClick={() => hideMaturation({ show: !(maturationData?.show), maturationName, maturationKey: maturationName })}
              disabled={!maturationData?.show && disabledShowButton(maturationCodeLength)}
            />
          </div>
        );

        const item = (
          <div
            key={maturationName}
            className={cx(styles.maturation, isLightTheme ? styles.maturationLight : styles.maturationDark)}
          >
            {itemMaturation}
          </div>
        );

        items.push(item);
      }

    }

    for (const [maturationName, maturationCodeMetric] of Object.entries(maturationCodeMetrics)) {
      const maturationData = dataSource.find((data) => data.id === maturationName);
      const maturationCodeLength = Object.keys(maturationCodeMetric).length;

      const itemMaturation = (
        <div className={styles.header}>
          <Checkbox
            onChange={event => enableItem(event.target.checked, maturationName)}
            checked={(maturationData?.enabled && maturationData?.show)}
            className={(maturationData?.enabled && maturationData?.show) ? cx(styles.checkbox, styles.checkboxEnabled) : styles.checkbox}
            disabled={!maturationData?.show || (!maturationData?.enabled && disabledCheckbox())}
          >
            <div
              className={styles.label}
              style={{ color: (maturationData?.enabled && maturationData?.show) ? `${maturationData?.color}` : '' }}
            >
              {maturationName}
            </div>
          </Checkbox>

          <Button
            id='hide_button'
            type='text'
            icon={<Icon name={maturationData?.show ? 'eye' : 'eye-off'} className={styles.icon} />}
            onClick={() => hideMaturation({ show: !(maturationData?.show), maturationName, maturationKey: maturationName, maturationCodeMetric })}
            disabled={!maturationData?.show && disabledShowButton(maturationCodeLength)}
          />
        </div>
      );

      const itemsGeneticCode = [];
      for (const [maturationCode,] of Object.entries(maturationCodeMetric)) {
        const key = `${maturationName}_${maturationCode}`;
        const maturationCodeData = dataSource.find((data) => data.id === key);

        const itemGeneticCode = (
          <Checkbox
            key={key}
            onChange={event => enableItem(event.target.checked, key)}
            checked={(maturationCodeData?.enabled && maturationData?.show)}
            className={(maturationCodeData?.enabled && maturationData?.show) ? cx(styles.checkbox, styles.checkboxEnabled) : styles.checkbox}
            disabled={!maturationData?.show || (!maturationCodeData?.enabled && disabledCheckbox())}
          >
            <div
              className={styles.label}
              style={{ color: (maturationCodeData?.enabled && maturationData?.show) ? `${maturationCodeData?.color}` : '' }}
            >
              {maturationCode}
            </div>
          </Checkbox>
        );

        itemsGeneticCode.push(itemGeneticCode);
      }

      const item = (
        <div
          key={maturationName}
          className={cx(styles.maturation, isLightTheme ? styles.maturationLight : styles.maturationDark)}
        >
          {itemMaturation}
          <div className={styles.geneticCode}>
            {itemsGeneticCode}
          </div>
        </div>
      );

      items.push(item);
    }

    return (
      <div ref={refMadurationPanel} className={cx(styles.maturationOptions, isLightTheme ? styles.maturationOptionsLight : styles.maturationOptionsDark)}>
        {items}
      </div>
    );
  };

  const showChart = () => {
    return hasMinimumAnalysis && dataSource.length > 0 && !isLoadingMaturationMetrics && !isLoadingCompanyMaturations;
  };

  const renderChart = () => {
    return (
      <div className={styles.chart}>
        {
          showChart() &&
          <div className={styles.containerIcons}>
            <div className={styles.labelAxisY}>
              <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={getParameter({ parameter, stockingPhaseType: phaseType })} />
            </div>
          </div>
        }

        <div style={{ display: showChart() ? '' : 'none' }}>
          <div ref={refChart} className={styles.maturationChart} />
        </div>

        {
          showChart() &&
          <div className={styles.labelAxisX}>
            <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={getLabelAxisX(phaseType, typesChart.MATURATIONS)} />
          </div>
        }
      </div>
    );
  };

  const renderContent = () => {
    if (isLoadingMaturationMetrics || isLoadingCompanyMaturations) {
      return (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}>
            <DotSpinner />
          </div>
        </div>
      );
    }

    if (!maturations || maturations.length === 0 || !hasMinimumAnalysis || !dataSource.length) {
      return (
        <div className={styles.center} >
          <LrvEmpty description={t('maturations.emptyCompare')} theme={theme} />
        </div>
      );
    }

    return null;
  };

  return (
    <div className={styles.geneticsAnalysis} >
      <GeneticsAnalysisFilters refFilters={refFilters} theme={theme} />
      {renderContent()}

      <div className={styles.main}>
        {showChart() && renderMaturationOptions()}
        {renderChart()}
      </div>
      <StockingsInfoModal />
    </div >
  );
};

export default GeneticsAnalysis;
