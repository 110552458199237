import { Col, Form, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../../utils/select';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../../common/components/LrvInput/LrvInput';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { LrvInputNumber } from '../../../common/components/LrvInputNumber/LrvInputNumber';
import { frequencyTypes, stockingParameterTypes, aggregationMethods } from '../../../config/commons';

import { CompanyStockingParameterBody } from './interfaces';
import { disabledForm, getAggregationMethod, getFrequencyType } from './helpers';
import * as companyStockingParameterSlice from './companyStockingParameterSlice';

interface Props {
  theme?: 'dark' | 'light';
}

const { Option } = Select;

export default function EditStockingParameterForm (props: Props) {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { company } = useSelector((state: Store) => state.header);
  const {
    companyStockingParameter,
    showEditParameterModal,
    isCreatingCompanyStockingParameterModal,
  } = useSelector((state: Store) => state.companyStockingParameter);

  const [parameterType, setParameterType] = useState<string>(stockingParameterTypes.NUMERIC);
  const [stockingParameter, setStockingParameter] = useState<string>('');
  const [options, setOptions] = useState<string[]>([]);
  const [unit, setUnit] = useState<string>('');
  const [minimum, setMinimum] = useState<number | undefined>();
  const [maximum, setMaximum] = useState<number | undefined>();

  const [frequencyType, setFrequencyType] = useState<string>(frequencyTypes.INDIVIDUAL);
  const [aggregationMethod, setAggregationMethod] = useState<string | undefined>();

  const companyId = company._id;

  useEffect(() => {
    if (!companyStockingParameter) {
      return;
    }

    form.setFieldsValue({
      unit: companyStockingParameter.unit,
      parameter: companyStockingParameter.key,
      parameterType: companyStockingParameter.type,
      options: companyStockingParameter.options,
      minimum: companyStockingParameter.min,
      maximum: companyStockingParameter.max,
      frequencyType: companyStockingParameter.frequencyType,
      aggregationMethod: companyStockingParameter.aggregationMethod,
    });

    setParameterType(companyStockingParameter.type);
    setStockingParameter(companyStockingParameter.key);
    setOptions(companyStockingParameter.options);
    setUnit(companyStockingParameter.unit || '');
    setMinimum(companyStockingParameter.min);
    setMaximum(companyStockingParameter.max);
    setFrequencyType(companyStockingParameter.frequencyType);
    setAggregationMethod(companyStockingParameter.aggregationMethod);
  }, [companyStockingParameter, showEditParameterModal]);

  const closeModal = () => {
    dispatch(companyStockingParameterSlice.setShowEditParameterModal(false));

    form.resetFields();

    setStockingParameter('');
    setOptions([]);
    setUnit('');
    setMinimum(undefined);
    setMaximum(undefined);
  };

  const saveStockingParameter = () => {
    if (!companyStockingParameter?._id) {
      return;
    }

    const body: CompanyStockingParameterBody = {
      companyId,
      key: stockingParameter,
      options,
      type: parameterType,
      unit: unit || undefined,
      min: minimum,
      max: maximum,
      frequencyType: getFrequencyType({ options, frequencyType }),
      aggregationMethod: getAggregationMethod({ options, frequencyType, aggregationMethod }),
    };

    const props = {
      id: companyStockingParameter._id,
      body,
      onSuccess: closeModal,
    };

    dispatch(companyStockingParameterSlice.editCompanyStockingParameter(props));
  };

  const renderStockingParameter = () => {
    return (
      <Form.Item
        name='parameter'
        label={t('stockingParameter.parameter')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvInput
          id='stocking-parameters-input'
          theme='light'
          value={unit}
          placeholder={t('stockingParameter.parameter')}
          onChange={(e) => setStockingParameter(e.target.value)}
          readOnly={!!companyStockingParameter?.globalParameterId}
        />
      </Form.Item>
    );
  };

  const renderParameterType = () => {
    return (
      <Form.Item
        name='parameterType'
        label={t('stockingParameter.form.type')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvSelect
          id='stocking-parameters-dropdown'
          theme='light'
          showSearch
          disabled={!stockingParameter}
          autoFocus
          placeholder={t('stockingParameter.form.type')}
          onChange={(value) => setParameterType(value)}
          removeIcon={<Icon name='close' />}
          dropdownMatchSelectWidth={false}
          filterOption={filterOptionSelect}
          value={parameterType}
          suffixIcon={<Icon name='arrow-down-s' />}
        >
          <Option key={stockingParameterTypes.CATEGORICAL} value={stockingParameterTypes.CATEGORICAL}>{t('stockingParameter.form.categorical')}</Option>)
          <Option key={stockingParameterTypes.NUMERIC} value={stockingParameterTypes.NUMERIC}>{t('stockingParameter.form.numeric')}</Option>)
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderOptionsDropdown = () => {
    return (
      <Form.Item
        name='options'
        label={parameterType === stockingParameterTypes.CATEGORICAL ? t('stockingParameter.options') : t('stockingParameter.form.frequency')}
        required={parameterType === stockingParameterTypes.CATEGORICAL}
        rules={[{ required: parameterType === stockingParameterTypes.CATEGORICAL, message: t('common.requiredField') }]}
      >
        <LrvSelect
          id='options-dropdown'
          theme='light'
          showSearch
          mode='tags'
          autoFocus
          disabled={!stockingParameter}
          placeholder={parameterType === stockingParameterTypes.CATEGORICAL ? t('stockingParameter.options') : t('stockingParameter.form.frequency')}
          onChange={(values: string[]) => {
            setOptions(values);
          }}
          removeIcon={<Icon name='close' />}
          dropdownMatchSelectWidth={false}
          filterOption={filterOptionSelect}
          value={options}
        >
          {options.map((option) =>
            <Option key={option} value={option}>{option}</Option>)
          }
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderFrequencyTypeDropdown = () => {
    if (parameterType === stockingParameterTypes.CATEGORICAL || options.length <= 1) {
      return;
    }

    return (
      <Form.Item
        name='frequencyType'
        label={t('stockingParameter.form.frequencyType')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvSelect
          id='stocking-parameters-dropdown'
          theme='light'
          showSearch
          disabled={!stockingParameter}
          autoFocus
          placeholder={t('stockingParameter.form.frequencyType')}
          onChange={(value) => setFrequencyType(value)}
          removeIcon={<Icon name='close' />}
          dropdownMatchSelectWidth={false}
          filterOption={filterOptionSelect}
          value={frequencyType || undefined}
          suffixIcon={<Icon name='arrow-down-s' />}
        >
          <Option key={frequencyTypes.INDIVIDUAL} value={frequencyTypes.INDIVIDUAL}>{t('stockingParameter.form.individual')}</Option>)
          <Option key={frequencyTypes.AGGREGATED} value={frequencyTypes.AGGREGATED}>{t('stockingParameter.form.aggregate')}</Option>)
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderAggregationMethodDropdown = () => {
    if (parameterType === stockingParameterTypes.CATEGORICAL || options.length <= 1 || !frequencyType || frequencyType === frequencyTypes.INDIVIDUAL) {
      return;
    }

    return (
      <Form.Item
        name='aggregationMethod'
        label={t('stockingParameter.form.aggregationMethod')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvSelect
          id='stocking-parameters-dropdown'
          theme='light'
          showSearch
          disabled={!stockingParameter}
          autoFocus
          placeholder={t('stockingParameter.form.aggregationMethod')}
          onChange={(value) => setAggregationMethod(value)}
          removeIcon={<Icon name='close' />}
          dropdownMatchSelectWidth={false}
          filterOption={filterOptionSelect}
          value={aggregationMethod || undefined}
          suffixIcon={<Icon name='arrow-down-s' />}
        >
          <Option key={aggregationMethods.SUM} value={aggregationMethods.SUM}>{t('stockingParameter.form.sum')}</Option>)
          <Option key={aggregationMethods.AVERAGE} value={aggregationMethods.AVERAGE}>{t('stockingParameter.form.average')}</Option>)
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderUnitInput = () => {
    if (parameterType !== stockingParameterTypes.NUMERIC) {
      return;
    }

    return (
      <Form.Item
        name='unit'
        label={t('stockingParameter.unit')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvInput
          id='unit-input'
          theme='light'
          value={unit}
          disabled={!stockingParameter}
          placeholder={t('stockingParameter.unit')}
          onChange={(e) => setUnit(e.target.value)}
        />
      </Form.Item>
    );
  };

  const renderMinValue = () => {
    if (parameterType !== stockingParameterTypes.NUMERIC) {
      return;
    }

    return (
      <Form.Item
        name='minimum'
        label={t('stockingParameter.form.min')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvInputNumber
          theme='light'
          min={0}
          max={maximum}
          value={minimum}
          disabled={!stockingParameter}
          placeholder={t('stockingParameter.form.min')}
          onChange={(value) => {
            if (value === undefined || value === null) {
              return;
            }

            setMinimum(parseInt(value.toString()));
          }}
        />
      </Form.Item>
    );
  };

  const renderMaxValue = () => {
    if (parameterType !== stockingParameterTypes.NUMERIC) {
      return;
    }

    return (
      <Form.Item
        name='maximum'
        label={t('stockingParameter.form.max')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvInputNumber
          theme='light'
          min={minimum}
          value={maximum}
          disabled={!stockingParameter}
          placeholder={t('stockingParameter.form.max')}
          onChange={(value) => {
            if (!value) {
              return;
            }

            setMaximum(parseInt(value.toString()));
          }}
        />
      </Form.Item>
    );
  };

  return (
    <LrvModal
      theme='light'
      title={t('stockingParameter.form.edit')}
      open={showEditParameterModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit-stocking-parameter',
        htmlType: 'submit',
        form: 'form',
        loading: isCreatingCompanyStockingParameterModal,
        disabled: disabledForm({ aggregationMethod, frequencyType, options, parameterType, stockingParameter, maximum, minimum }),
      }}
      onOk={saveStockingParameter}
      okText={t('maturations.accept')}
      cancelText={t('maturations.cancel')}
      onCancel={closeModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form-stocking-parameter'
        layout='vertical'
      >
        {renderStockingParameter()}
        {renderParameterType()}
        {renderOptionsDropdown()}

        <Row gutter={16}>
          <Col span={12}>
            {renderFrequencyTypeDropdown()}
          </Col>
          <Col span={12}>
            {renderAggregationMethodDropdown()}
          </Col>
        </Row>

        {renderUnitInput()}
        {renderMinValue()}
        {renderMaxValue()}
      </LrvForm>
    </LrvModal>
  );
}