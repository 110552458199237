import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { COMPANIES_URL } from '../../../config/config.api';
import { getUserSession } from '../../../utils/userSession';
import { UserSession } from '../../../common/interfaces/auth';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { openSuccessNotification } from '../../../common/notification/Notification';

import { Company, CompanySettingState } from './interfaces';

const initialState: CompanySettingState = {
  company: {
    weightUnit: '',
  },
};

export const companySettingSlice = createSlice({
  name: 'companySetting',
  initialState,
  reducers: {
    setCompanySetting: (state: CompanySettingState, action: PayloadAction<Company>) => {
      state.company.weightUnit = action.payload.weightUnit;
    },
  },
});

export const {
  setCompanySetting,
} = companySettingSlice.actions;

export const fetchCompanySetting = () => async (dispatch: Function) => {
  const userSession: UserSession = getUserSession();
  const url = `${COMPANIES_URL}/${userSession.companyId}`;
  const params = {
    $select: ['weightUnit'],
  };

  try {
    const response = await axios.get<Company>(url, { params });
    dispatch(setCompanySetting(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateCompanySetting = (props: { weightUnit: string }) => async (dispatch: Function) => {
  const { weightUnit } = props;

  const userSession: UserSession = getUserSession();
  const url = `${COMPANIES_URL}/${userSession.companyId}`;
  const body = { weightUnit };

  try {
    const response = await axios.patch<Company>(url, body);
    dispatch(setCompanySetting(response.data));
    openSuccessNotification(i18next.t('weightUnit.success'));
  } catch (e) {
    console.log(e?.response);
  }
};

export default companySettingSlice.reducer;
