import cx from 'classnames';
import { RuleObject } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { CountryCode } from 'libphonenumber-js/types';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';

import { PATTERN } from '../../config/commons';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../utils/select';
import { validatePasswordPolicy } from '../../utils/validations';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import PasswordPolicy from '../../common/components/LrvPopover/PasswordPolicy';

import './RegisterForm.scss';
import styles from './RegisterForm.module.scss';
import { RegisterForm as IRegisterForm } from './interfaces';
import { setMessage, registerClient, setOkCode, registerClientWithCoupon } from './registerSlice';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const searchParam = (param: string) => {
  const { search } = window.location;
  return new URLSearchParams(search).get(param);
};

export default function RegisterForm () {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const email = searchParam('email');
  const code = searchParam('code');

  const { message, okCode, isLoading } = useSelector((state: Store) => state.registerClient);
  const [disabledButtonForm, setDisabledButtonForm] = useState(false);
  const [readOnlyEmailInput, setReadOnlyEmailInput] = useState(false);

  const [phonePrefix, setPhonePrefix] = useState('593');
  const [countryCode, setCountryCode] = useState('EC');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (email) {
      setReadOnlyEmailInput(true);
      form.setFieldsValue({
        email: email,
      });
    }
  }, [form, email]);

  const validatePhoneInputGroup = (_: RuleObject, value: string) => {
    const phoneInput = form.getFieldValue('phone');
    const countryCodeSelect = form.getFieldValue('countryCode');

    if (value && phoneInput && countryCodeSelect) {
      return Promise.resolve();
    }

    if (!countryCodeSelect) {
      return Promise.reject(
        new Error(t('common.requiredField'))
      );
    }

    return Promise.reject(
      new Error(t('common.requiredField'))
    );
  };

  function renderError () {
    if (message === '') {
      return null;
    }

    return (
      <div id='alert_error_register' className={styles.alertError}>
        <div role='alert'>{t(message)}</div>
      </div>
    );
  }

  function renderMessageConfirmAccount () {
    if (okCode === 0) {
      return null;
    }

    return (
      <div id='alert_info_register' className={styles.alertInfo}>
        <div role='alert'>{t('register.success')}</div>
      </div>
    );
  }

  return (
    <Form
      name='register'
      form={form}
      initialValues={{
        countryCode,
      }}
      {...formItemLayout}
      className={cx('registerForm', styles.registerForm)}
      onFinish={(data) => {
        dispatch(setMessage(''));
        dispatch(setOkCode(0));
        const registerData = {
          ...data,
          phonePrefix,
        };

        if (code) {
          data.code = code;
          dispatch(registerClientWithCoupon(registerData as IRegisterForm));
          return;
        }

        dispatch(registerClient(registerData as IRegisterForm));
      }}
      onFieldsChange={() => {
        setDisabledButtonForm(
          !form.isFieldsTouched(['countryCode', 'firstName', 'lastName', 'companyName', 'email', 'phone', 'password']) ||
          form.getFieldsError(['countryCode', 'firstName', 'lastName', 'companyName', 'email', 'countryCode', 'phone', 'password']).filter(({ errors }) => errors.length).length > 0
        );
      }
      }
    >
      <Form.Item
        name='companyName'
        label={t('register.companyName')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <Input
          className={styles.input}
        />
      </Form.Item>

      <Form.Item
        name='firstName'
        label={t('register.firstName')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <Input
          className={styles.input}
        />
      </Form.Item>

      <Form.Item
        name='lastName'
        label={t('register.lastName')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <Input
          className={styles.input}
        />
      </Form.Item>

      <Form.Item
        name='email'
        label={t('register.email')}
        rules={[{ required: true, pattern: PATTERN.EMAIL, message: t('common.requiredField') }]}
      >
        <Input
          className={cx(styles.input, readOnlyEmailInput ? styles.notAllowed : '')}
          readOnly={readOnlyEmailInput}
        />
      </Form.Item>

      <Form.Item
        label={t('register.phone')}
        className={styles.phoneFormItem}
        name='phone'
        rules={[
          { required: false, message: t('common.requiredField') },
          { validator: (_, value) => validatePhoneInputGroup(_, value) },
        ]}
        validateTrigger={['onBlur', 'onChange']}
      >
        <Input.Group className={styles.inputGroup} compact>
          <Form.Item
            name='countryCode'
            noStyle
          >
            <LrvSelect
              showSearch
              theme='dark'
              className={styles.countryCodeSelect}
              containerClassName={styles.countryCodeContainer}
              style={{ width: '100%' }}
              suffixIcon={<Icon name='arrow-down-s' />}
              removeIcon={<Icon name='close' />}
              placeholder={t('clients.select')}
              optionFilterProp='children'
              filterOption={filterOptionSelect}
              onChange={value => {
                const code = value as CountryCode;
                setPhonePrefix(getCountryCallingCode(code));
                setCountryCode(value);
                form.setFieldValue('countryCode', value);
              }}
              value={countryCode}
            >
              {
                getCountries().map((country) => {
                  return (
                    <Select.Option
                      key={country}
                      value={country}
                    >
                      {`${country} +${getCountryCallingCode(country)}`}
                    </Select.Option>
                  );
                })
              }
            </LrvSelect>
          </Form.Item>

          <Form.Item
            noStyle
          >
            <Input
              id='txt_phone'
              placeholder={t('clients.phone')}
              className={styles.input}
              value={phone}
              onChange={(e) => {
                form.setFieldValue('phone', e.target.value);
                setPhone(e.target.value);
              }}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item
        label={
          <span>
            {t('register.password')}&nbsp;
            <PasswordPolicy />
          </span>
        }
        required
        name='password'
        rules={[() => ({ validator (rule, value) { return validatePasswordPolicy(value); } })]}
      >
        <Input.Password
          className={styles.input}
          type='password'
        />
      </Form.Item>

      <Form.Item
        {...tailFormItemLayout}
      >
        {renderError()}
        {renderMessageConfirmAccount()}
      </Form.Item>

      <Form.Item
        {...tailFormItemLayout}
      >
        <div className={styles.formOptions}>
          <Form.Item>
            <Button
              id='submit_register'
              type='primary'
              htmlType='submit'
              disabled={disabledButtonForm}
              loading={isLoading}
            >
              {t('register.register')}
            </Button>
          </Form.Item>

          <a className={styles.forgotPassword} href='/login'>
            {t('register.signIn')}
          </a>
        </div>
      </Form.Item>
    </Form>
  );
}
