import { Descriptions } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { Store } from '../../state/store.interfaces';
import { formatMonthDayYear } from '../../utils/date';
import { applyThousandsSeparator } from '../../utils/strings';
import IconButton from '../../common/components/buttons/IconButton';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvTooltip } from '../../common/components/LrvTooltip/LrvTooltip';
import { lrvConfirm } from '../../common/components/LrvConfirm/LrvConfirm';
import { LrvDescriptions } from '../../common/components/LrvDescriptions/LrvDescriptions';
import { convertPoundsToKilograms, fixStockingStartDate } from '../../helpers/stocking.helpers';
import { roundTwoDecimals, stockingPhaseTypes, transferTypes, weightUnits } from '../../config/commons';

import { PartialHarvest } from './interfaces';
import styles from './HarvestsStockingInfo.module.scss';
import * as harvestsStockingSlice from './harvestsStockingSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export const HarvestsStockingInfoModal = (props: Props) => {
  const { theme = 'dark' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const pathname = history.location.pathname;

  const {
    showHarvestsInfo,
    harvests,
    isLoadingHarvests,
  } = useSelector((state: Store) => state.partialHarvests);

  const { company } = useSelector((state: Store) => state.header);

  const {
    currentPage,
    selectedCampus,
    selectedModuleId,
    selectedStocking,
    selectedTankId,
    filters,
  } = useSelector((state: Store) => state.stockings);

  const {
    stockingsToShow,
  } = filters;

  const renderConfirmationUndoHarvestStocking = (harvest: PartialHarvest) => {
    const title = t('stockings.confirmUndoHarvest.title');
    const description = t('stockings.confirmUndoHarvest.description');
    const idOkButton = 'confirm_button';
    const idCancelButton = 'cancel_button';

    lrvConfirm({
      theme: 'light',
      title: title,
      content: description,
      icon: <ExclamationCircleOutlined />,
      okText: t('stockings.confirmUndoHarvest.yes').toUpperCase(),
      cancelText: t('stockings.confirmUndoHarvest.no').toUpperCase(),
      okButtonProps: { id: idOkButton },
      cancelButtonProps: { id: idCancelButton },
      onOk () {
        const paramsToFetchStocking = { companyId: company._id, campusId: selectedCampus?._id, moduleId: selectedModuleId, tankId: selectedTankId, page: currentPage, phaseType: selectedStocking.phaseType, stockingsToShow };
        const makeFetchStockings = pathname === '/production/stockings';
        const params = { originStockingId: selectedStocking._id, partialHarvestId: harvest._id, paramsToFetchStocking, makeFetchStockings };
        dispatch(harvestsStockingSlice.undoPartialHarvestStocking(params));
      },
    });
  };

  const getHarvestWeightUnit = () => {
    switch (selectedStocking.phaseType) {
      case stockingPhaseTypes.LARVAE:
        return weightUnits.MG;

      default:
        return weightUnits.G;
    }
  };

  const getColumnsType = () => {
    const columnsType: ColumnsType<PartialHarvest> = [
      {
        key: 1,
        width: '18%',
        title: t('production.stockingInfoModal.harvestDate'),
        responsive: ['lg'] as Breakpoint[],
        dataIndex: 'harvestDate',
      },
      {
        key: 2,
        width: '18%',
        title: t(`stockings.finishStockingLabels.${selectedStocking.phaseType}.harvested`),
        render: (record: PartialHarvest) => applyThousandsSeparator(record.harvestQuantity),
      },
    ];

    if (selectedStocking.phaseType === stockingPhaseTypes.LARVAE) {
      columnsType.push(
        {
          key: 3,
          width: '18%',
          title: t('stockings.finishStockingLabels.LARVAE.plg'),
          dataIndex: 'larvaePerGram',
        },
      );
    }

    if (selectedStocking.phaseType !== stockingPhaseTypes.LARVAE) {
      columnsType.push(
        {
          key: 4,
          width: '18%',
          title: `${t(`stockings.finishStockingLabels.${selectedStocking.phaseType}.averageWeight`)} ${getHarvestWeightUnit()}`,
          dataIndex: 'averageHarvestedWeight',
        },
      );
    }

    if (selectedStocking.phaseType === stockingPhaseTypes.ADULT) {
      columnsType.push(
        {
          key: 5,
          width: '18%',
          title: t('stockings.finishStockingLabels.ADULT.kilograms'),
          render: (record: PartialHarvest) => {
            const { poundsHarvested } = record;
            const kg = convertPoundsToKilograms(poundsHarvested);
            return roundTwoDecimals(kg);
          }
        },
      );

      columnsType.push(
        {
          key: 6,
          width: '18%',
          title: t('stockings.finishStockingLabels.ADULT.pounds'),
          render: (record: PartialHarvest) => {
            const { poundsHarvested } = record;
            return roundTwoDecimals(poundsHarvested);
          }
        },
      );
    }

    columnsType.push(
      {
        key: 7,
        width: '30%',
        responsive: ['lg'] as Breakpoint[],
        title: t('stockings.comment'),
        dataIndex: 'comment',
      }
    );

    columnsType.push(
      {
        key: 8,
        width: '8%',
        render: (record: PartialHarvest) => {
          return (
            <div className={styles.options}>
              <LrvTooltip
                themeStyle='light'
                title={t('stockings.delete')}
              >
                <IconButton
                  theme='light'
                  iconName='delete-bin'
                  onClick={() => renderConfirmationUndoHarvestStocking(record)}
                  className={styles.actionButton}
                />
              </LrvTooltip>
            </div>
          );
        }
      },
    );

    return columnsType;
  };

  const dataSource = harvests.map((harvest: PartialHarvest) => {
    const harvestDate = fixStockingStartDate(harvest.harvestDate);

    return {
      ...harvest,
      harvestDate: formatMonthDayYear(harvestDate),
    };
  });

  const renderFooterButtons = () => {
    if (selectedStocking.status !== transferTypes.PARTIAL_TRANSFER) {
      return <div></div>;
    }
    
    return (
      <div className={styles.footerModal}>
        <ActionButton
          theme='light'
          type='text'
          onClick={() => dispatch(harvestsStockingSlice.setShowHarvestsInfo(false))}
        >
          <span>{t('stockings.cancel')}</span>
        </ActionButton>
      </div>
    );
  };

  return (
    <LrvModal
      cancelButtonProps={{ style: { display: 'none' } }}
      className={styles.harvestsStockingInfoModal}
      destroyOnClose={true}
      onCancel={() => dispatch(harvestsStockingSlice.setShowHarvestsInfo(false))}
      footer={renderFooterButtons()}
      open={showHarvestsInfo}
      theme={theme}
      title={t('stockings.harvestsInfo')}
      width={820}
    >
      <LrvDescriptions
        column={1}
        theme={theme}
      >
        <Descriptions.Item label={t('stockings.stocking')}>
          {selectedStocking.name}
        </Descriptions.Item>
      </LrvDescriptions>

      <LrvTable
        id='partial_harvests_stockings_table'
        columns={getColumnsType()}
        loading={isLoadingHarvests}
        dataSource={dataSource}
        size='small'
        theme={theme}
        pagination={false}
      />
    </LrvModal>
  );
};