import { LogBalance, User } from '../Users/interfaces';

export interface ProfileState {
  _id: string;
  firstName: string;
  lastName: string;
  language: string;
  defaultPhase: string;
  email: string;
  active: boolean;
  theme?: 'dark' | 'light' | undefined;
  isLoading?: boolean;
  historyManagement: HistoryManagement;
  imageAPI: APIToken;
  dataAPI: APIToken;
  allowDataApi: boolean;
  allowImageApi: boolean;
  settingTab?: SettingsTab;
}

export interface HistoryManagementPayload {
  total: number;
  data: LogBalance[];
}

export interface HistoryManagement {
  isLoading?: boolean;
  total: number;
  currentPage: number;
  data: LogBalance[];
  users: User[];
  filters: {
    userIdSelected?: string;
    logBalanceOrigin: string;
  };
}

export interface HistoryManagementFilter {
  userIdSelected?: string;
  logBalanceOrigin: string;
}

export interface APIToken {
  _id: string;
  companyId: string;
  url: string;
  apiKey: string;
  token: string;
}

export interface Banner {
  companyIds: string[];
  en: string;
  es: string;
  pt: string;
  showAlert: boolean;
  persistentMode: boolean;
  endDate?: string;
  backgroundColor: string;
  icon: string;
  alertClosed?: boolean;
}

export interface ScheduleRuleState {
  _id: string;
  companyId: string;
  hours: string[];
  isLoading: boolean;
}

export interface ScheduleRule {
  _id: string;
  companyId: string;
  hours: string[];
}

export type SettingsTab = 'USER' | 'COMPANY' | 'STOCKING_PARAMETER' | 'ANALYSIS_CARD' |
  'PASSWORD' | 'HISTORY_MANAGEMENT' | 'API_SERVICE' | 'COUPON' |
  'BANNER' | 'SCHEDULE' | 'NOVELTIES';

export const settingsTabs: Record<SettingsTab, SettingsTab> = {
  USER: 'USER',
  COMPANY: 'COMPANY',
  STOCKING_PARAMETER: 'STOCKING_PARAMETER',
  ANALYSIS_CARD: 'ANALYSIS_CARD',
  PASSWORD: 'PASSWORD',
  HISTORY_MANAGEMENT: 'HISTORY_MANAGEMENT',
  API_SERVICE: 'API_SERVICE',
  COUPON: 'COUPON',
  BANNER: 'BANNER',
  SCHEDULE: 'SCHEDULE',
  NOVELTIES: 'NOVELTIES',
};
