import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMinStage } from '../../Analysis/helpers';
import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { DataSource, Point } from '../../home/interfaces';
import { changeHeader } from '../../AppHeader/headerSlice';
import { ItemReferenceCurves } from '../../Units/interfaces';
import { handleResizeEvent } from '../../../utils/dimensions';
import { stockingPhaseTypes, THEME } from '../../../config/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import D3ShadedPlot from '../../../common/components/charts/ShadedPlot/D3ShadedPlot';
import { calcStages, rectColor, colorsPoints, getDefaultDataByStage, metricsStatuses, typeScale, typesChart, lineColor, selectedTickStoke } from '../../../common/components/charts/ShadedPlot/helpers';

import { Legends } from './Legends';
import styles from './LaboratoryChart.module.scss';
import * as laboratoryChartSlice from './laboratoryChartSlice';
import { LaboratoryChartFilters } from './LaboratoryChartFilters';
import { getHeightOfTheOtherElements, getWidthOfTheOtherElements } from './helpers';

let chart: D3ShadedPlot | null;

export const LaboratoryChart = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const refChart = useRef<HTMLDivElement>(null);
  const refFilters = useRef<HTMLDivElement>(null);
  const refLegends = useRef<HTMLDivElement>(null);

  const {
    isLoading,
    filters,
    firstStage,
    lastStage,
    dataSource,
    metrics,
  } = useSelector((state: Store) => state.laboratoryChart);

  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);

  const {
    parameter,
    scale,
    minimumDate,
    maximumDate,
  } = filters;

  const [width, setWidth] = useState(window.innerWidth - getWidthOfTheOtherElements());
  const [height, setHeight] = useState(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, legends: refLegends }));
  const [amountLaboratories, setAmountLaboratories] = useState<number>(0);

  const minStage = getMinStage(phaseType);
  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;
  const colorFillRect = isLightTheme ? rectColor.light : rectColor.dark;
  const colorLine = isLightTheme ? lineColor.light : lineColor.dark;
  const tickStroke = isLightTheme ? selectedTickStoke.light : selectedTickStoke.dark;

  useEffect(() => {
    dispatch(changeHeader({ title: 'laboratory.title' }));

    return (() => {
      chart = null;
    });
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCompany._id) {
      return;
    }

    const params = {
      companyId: selectedCompany._id,
      parameter,
      minimumDate,
      maximumDate,
      phaseType,
    };

    dispatch(laboratoryChartSlice.fetchLaboratoryMetrics(params));
  }, [dispatch, phaseType, selectedCompany._id, minimumDate, maximumDate, parameter]);

  useEffect(() => {
    const maturationDataSource: DataSource[] = [];

    let index = 0;
    for (const [laboratoryName, laboratoryStageMetrics] of Object.entries(metrics)) {
      const laboratoryByStageList: Point[] = [];

      const dataSourceSelected = dataSource.find((data) => data.id === laboratoryName);
      const dataSourceLength = dataSource.filter((data) => data.enabled && data.show).length;

      const color = dataSourceSelected?.color || colorsPoints[index];
      const enabled = dataSourceSelected?.enabled === undefined ? dataSourceLength < 5 : dataSourceSelected.enabled;
      const show = dataSourceSelected?.show || true;

      for (const [stage, laboratoryStage] of Object.entries(laboratoryStageMetrics)) {
        const x: number = parseInt(stage);
        const y: number = laboratoryStage.mean;
        const analysesNumber = laboratoryStage.count;
        const maturationByStage: Point = getDefaultDataByStage({ x, y, analysesNumber, stageRange: laboratoryStage.stageRange });
        laboratoryByStageList.push(maturationByStage);
      }

      const maturationData: DataSource = { id: laboratoryName, name: laboratoryName, color, enabled, show, points: [], avgPoint: laboratoryByStageList };
      maturationDataSource.push(maturationData);
      index++;
    }

    dispatch(laboratoryChartSlice.setDataSource(maturationDataSource));
  }, [metrics]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth - getWidthOfTheOtherElements());
      setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, legends: refLegends }));
    });
  }, []);

  useEffect(() => {
    if (!selectedCompany._id || !dataSource.length) {
      return;
    }

    setWidth(window.innerWidth - getWidthOfTheOtherElements());
    setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, legends: refLegends }));
  }, [selectedCompany._id, dataSource]);

  useEffect(() => {
    chart && chart.resize(width, height);
  }, [width, height]);

  useEffect(() => {
    if ((firstStage === undefined || !lastStage) || !selectedCompany._id) {
      return;
    }

    const renderD3Chart = () => {
      const data: ItemReferenceCurves[] = [];
      const dataSourceFiltered = dataSource.filter((data) => data.enabled && data.show && data.color);
      const colorsPoints = dataSourceFiltered.map((dataSource) => `${dataSource.color}`);
      const colorsStroke = colorsPoints;

      if (dataSourceFiltered.length === 0 && !!chart) {
        chart.refreshChart({ dataMetric: data, dataSource: cloneDeep(dataSourceFiltered), firstStage, lastStage, width, height, phaseType, colorsPoints, colorsStroke, parameter, typeMetric: metricsStatuses.COMPANY, scale, colorFillRect, colorLine, selectedTickStoke: tickStroke });
        return;
      }

      if (chart) {
        chart.refreshChart({ dataMetric: data, dataSource: cloneDeep(dataSourceFiltered), firstStage, lastStage, width, height, phaseType, colorsPoints, colorsStroke, parameter, typeMetric: metricsStatuses.COMPANY, scale, colorFillRect, colorLine, selectedTickStoke: tickStroke });
        return;
      }

      const props = {
        colorFillRect,
        colorLine,
        dispatch,
        companyData: selectedCompany,
        container: refChart.current,
        dataMetric: data,
        dataSource: cloneDeep(dataSourceFiltered),
        firstStage,
        height,
        lastStage,
        parameter,
        phaseType,
        scale: typeScale.LINEAR,
        showLabels: false,
        typeChart: typesChart.LABORATORY,
        typeMetric: metricsStatuses.COMPANY,
        width,
        colorsPoints,
        colorsStroke,
        selectedTickStoke: tickStroke,
      };
      chart = new D3ShadedPlot(props);
    };

    renderD3Chart();
  }, [dispatch, dataSource, scale, parameter, firstStage, lastStage, width, height, selectedCompany, phaseType, colorFillRect, colorLine, tickStroke]);

  useEffect(() => {
    if (!dataSource.length) {
      return;
    }

    const updateRange = () => {
      const stages = calcStages(dataSource, minStage);
      const firstStage = stages[0];
      const lastStage = stages[1];

      dispatch(laboratoryChartSlice.setFirstStage(firstStage));
      dispatch(laboratoryChartSlice.setLastStage(lastStage));

      dispatch(laboratoryChartSlice.setFirstStageZoom(firstStage));
      dispatch(laboratoryChartSlice.setLastStageZoom(lastStage));
    };

    if (dataSource.length !== amountLaboratories) {
      setAmountLaboratories(dataSource.length);
    }

    updateRange();
  }, [dataSource, amountLaboratories, minStage]);

  const showChart = () => {
    return dataSource.length > 0 && !isLoading && phaseType !== stockingPhaseTypes.LARVAE;
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}>
            <DotSpinner />
          </div>
        </div>
      );
    }

    if (phaseType === stockingPhaseTypes.LARVAE) {
      return (
        <div className={styles.center} >
          <LrvEmpty description={t('laboratory.larvae')} theme={theme} />
        </div>
      );
    }

    if (!dataSource.length) {
      return (
        <div className={styles.center} >
          <LrvEmpty description={t('laboratory.empty')} theme={theme} />
        </div>
      );
    }

    return null;
  };

  const renderChart = () => {
    return (
      <div className={styles.chart}>
        {showChart() && <Legends refLegends={refLegends} theme={theme} />}

        <div style={{ display: showChart() ? '' : 'none' }}>
          <div ref={refChart} className={styles.chartSvg} />
        </div>

        {showChart() && <div className={styles.labelAxisX}>
          <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('shadedplot.days')} />
        </div>}
      </div>
    );
  };

  return (
    <div className={styles.container} >
      <LaboratoryChartFilters refFilters={refFilters} theme={theme} />
      {renderContent()}
      {renderChart()}
    </div>
  );
};
