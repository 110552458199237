import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';

import { LrvPopover } from '../LrvPopover/LrvPopover';
import { useGetPrevStocking } from '../../../hooks/useGetPrevStocking';
import { formatMonthDayYear } from '../../../utils/date';

import styles from './ProductionCycleInfo.module.scss';

interface Props {
  label?: string;
  tankId: string;
}

export const ProductionCycleInfo = (props: Props) => {
  const { label, tankId } = props;
  const [t] = useTranslation();
  const { previousStocking } = useGetPrevStocking(tankId);

  const renderContent = () => {
    if (!previousStocking) {
      return (
        <div>
          <div>{t('stockings.emptyPreviousStocking')}</div>
        </div>
      );
    }

    return (
      <div>
        <div>{`${t('stockings.name')}: ${previousStocking.name}`}</div>
        <div>{`${t('stockings.productionCycle')}: ${previousStocking.code}`}</div>
        <div>{`${t('stockings.endDate')}: ${formatMonthDayYear(previousStocking.endDate)}`}</div>
      </div>
    );
  };

  if (!tankId) {
    return (
      <div className={styles.container}>
        {label && <div className={styles.label}>{label}</div>}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {label && <div className={styles.label}>{label}</div>}
      <LrvPopover content={renderContent()} title={t('stockings.previousStocking')}>
        <InfoCircleOutlined />
      </LrvPopover>
    </div>
  );
};
