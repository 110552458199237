import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as harvestsSlice from '../harvestsSlice';
import Icon from '../../../common/components/Icon/Icon';
import { Store } from '../../../state/store.interfaces';
import { formatMonthDayYear } from '../../../utils/date';
import { applyThousandsSeparator } from '../../../utils/strings';
import { Consolidated, Histogram, Stocking } from '../interfaces';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import * as stockingAnalysisSlice from '../../Sowings/Analysis/stockingAnalysisSlice';
import { calcDensityLarvae, getUnitPhaseTypeFromStocking } from '../../../helpers/stocking.helpers';
import { animalDaysUnit, harvestOptions, lengthUnits, roundTwoDecimals, weightUnits } from '../../../config/commons';
import { calculateBinCenters, calculateCoefficientOfVariation, calculateWeightedAverage, calculateWeightedStdDev } from '../helpers';

import styles from './ExtraInformation.module.scss';

interface Props {
  theme?: 'dark' | 'light';
  consolidated: Consolidated | undefined;
  harvest?: {
    histogramWeight: Histogram;
    histogramLength: Histogram;
    stocking?: Stocking | undefined;
  }
}

export const ExtraInformation = (props: Props) => {
  const {
    theme = 'light',
    consolidated,
    harvest,
  } = props;

  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { company, phaseType } = useSelector((state: Store) => state.header);
  const { harvestsToShow } = useSelector((state: Store) => state.harvests);

  const showCurrentHarvests = harvestsToShow === harvestOptions.CURRENT;

  const renderRow = (props: { value: React.ReactNode; label: string; }) => {
    const { label, value } = props;

    return (
      <tr>
        <td>
          <LrvText className={styles.bold} theme={theme} text={label} />
        </td>
        <td>
          <LrvText theme={theme} text={value} />
        </td>
      </tr>
    );
  };

  const navigateToStockingComparisonGraph = (props: { unitPhaseType: string; unitId: string; moduleId: string; containerId: string; stockingId: string }) => {
    const { containerId, moduleId, stockingId, unitId, unitPhaseType } = props;

    dispatch(stockingAnalysisSlice.fetchUnits({ companyId: company._id, phaseType: unitPhaseType, modulePhaseType: phaseType, selectFirstItem: false }));
    dispatch(stockingAnalysisSlice.setUnitSelected(unitId));
    dispatch(stockingAnalysisSlice.fetchModules({ unitId, phaseType: phaseType, selectFirstItem: false }));
    dispatch(stockingAnalysisSlice.setModuleSelected(moduleId));
    dispatch(stockingAnalysisSlice.fetchContainers({ unitId, moduleId }));
    dispatch(stockingAnalysisSlice.setContainerSelected(containerId));
    dispatch(stockingAnalysisSlice.fetchStocking({ stockingId }));

    history.push('/production/insights');
  };

  const renderGraphRow = (props: { value: React.ReactNode; label: string; }) => {
    const { label, value } = props;

    return (
      <tr>
        <td>
          <LrvText className={styles.bold} theme={theme} text={label} />
        </td>
        <td
          className={showCurrentHarvests ? styles.cursor : ''}
          onClick={() => {
            if (!harvest?.stocking?._id) {
              return;
            }

            const unitPhaseType = getUnitPhaseTypeFromStocking(phaseType);
            dispatch(stockingAnalysisSlice.setBrowsingFromStocking(true));

            const unitId = harvest.stocking.campusId._id;
            const moduleId = harvest.stocking.moduleId._id;
            const containerId = harvest.stocking.tankId._id;
            const stockingId = harvest.stocking._id;

            navigateToStockingComparisonGraph({ containerId, moduleId, stockingId, unitId, unitPhaseType });
          }}
        >
          <LrvText theme={theme} text={value} />
        </td>
      </tr>
    );
  };

  const isSelectedHarvest = (props: { harvestIds?: string[]; index: number }) => {
    const { index, harvestIds } = props;
    return harvestIds && harvest?.stocking?._id === harvestIds[index];
  };

  const renderHarvestName = (props: { values: React.ReactNode[]; label: string; harvestIds: string[]; harvestedAnimals: number[] }) => {
    const { label, values, harvestIds, harvestedAnimals } = props;

    return (
      <tr>
        <td>
          <LrvText className={styles.bold} theme={theme} text={label} />
        </td>

        {values.map((value, index) => (
          <td
            className={harvestIds && showCurrentHarvests ? styles.cursor : ''}
            onClick={() => {
              if (!harvestIds || !harvestedAnimals || !showCurrentHarvests) {
                return;
              }

              if (isSelectedHarvest({ index, harvestIds })) {
                dispatch(harvestsSlice.resetHistograms());
                return;
              }

              dispatch(harvestsSlice.fetchHarvestHistogram({ harvestId: harvestIds[index], harvestedAnimal: harvestedAnimals[index] }));
            }}
          >
            {harvestIds && showCurrentHarvests && <>
              <Icon name={isSelectedHarvest({ index, harvestIds }) ? 'eye' : 'eye-off'} className={cx(styles.expandIcon, styles.expandIconLight)} />
              &nbsp;
            </>
            }
            <LrvText className={cx(isSelectedHarvest({ index, harvestIds }) ? styles.bold : '')} theme={theme} text={value} />
          </td>
        ))}
      </tr>
    );
  };

  const renderConsolidatedHarvestRow = (props: { values: React.ReactNode[]; label: string; classNameValue?: string; }) => {
    const { label, values, classNameValue } = props;

    return (
      <tr>
        <td>
          <LrvText className={styles.bold} theme={theme} text={label} />
        </td>

        {values.map((value) => (
          <td>
            <LrvText className={classNameValue} theme={theme} text={value} />
          </td>
        ))}
      </tr>
    );
  };

  const renderGraphOption = (props: { values: React.ReactNode[]; label: string; }) => {
    const { label, values } = props;

    return (
      <tr>
        <td>
          <LrvText className={styles.bold} theme={theme} text={label} />
        </td>

        {values.map((value, index) => (
          <td
            className={showCurrentHarvests ? styles.cursor : ''}
            onClick={() => {
              if (!consolidated?._id) {
                return;
              }

              const unitPhaseType = getUnitPhaseTypeFromStocking(phaseType);
              dispatch(stockingAnalysisSlice.setBrowsingFromStocking(true));

              const unitId = consolidated.campusIds[index]._id;
              const moduleId = consolidated.moduleIds[index]._id;
              const containerId = consolidated.tankIds[index]._id;
              const stockingId = consolidated.stockingIds[index]._id;

              navigateToStockingComparisonGraph({ containerId, moduleId, stockingId, unitId, unitPhaseType });
            }}
          >
            <LrvText theme={theme} text={value} />
          </td>
        ))}
      </tr>
    );
  };

  if (consolidated?._id) {
    const harvestQuantity = consolidated.harvestedAnimals.reduce((acc, value) => acc + value, 0);

    const binCentersWeight = calculateBinCenters(consolidated.histogramWeight.limits);
    const averageWeight = calculateWeightedAverage(binCentersWeight, consolidated.histogramWeight.frequencies);
    const stdDevWeight = calculateWeightedStdDev(binCentersWeight, consolidated.histogramWeight.frequencies, averageWeight);
    const variationCoefficientWeight = calculateCoefficientOfVariation(stdDevWeight, averageWeight) * 100;

    const binCentersLength = calculateBinCenters(consolidated.histogramLength.limits);
    const averageLength = calculateWeightedAverage(binCentersLength, consolidated.histogramLength.frequencies);
    const stdDevLength = calculateWeightedStdDev(binCentersLength, consolidated.histogramLength.frequencies, averageLength);
    const variationCoefficientLength = calculateCoefficientOfVariation(stdDevLength, averageLength) * 100;

    const stockings = consolidated.stockingIds.map((stocking) => stocking.name);
    const stockingIds = consolidated.stockingIds.map((stocking) => stocking._id);
    const unitNames = consolidated.campusIds.map((unit) => unit.name);
    const moduleNames = consolidated.moduleIds.map((module) => module.name);
    const containerNames = consolidated.tankIds.map((container) => container.name);
    const stages = consolidated.stockingIds.map((stocking) => `${stocking.stage} ${animalDaysUnit.PL}`);
    const stockingBindingCodes = consolidated.stockingIds.map((stocking) => stocking.stockingBindingCode);
    const larvaePerGrams = consolidated.stockingIds.map((stocking) => stocking.larvaePerGram);
    const animals = consolidated.harvestedAnimals.map((animals) => applyThousandsSeparator(animals));
    const percents = consolidated.harvestedAnimals.map((animals) => roundTwoDecimals(animals * 100 / harvestQuantity) + ' %');

    return (
      <>
        <table className={styles.table}>
          <tbody className={styles.body}>
            {
              renderHarvestName({
                label: t('harvests.harvest'),
                values: stockings,
                harvestIds: stockingIds,
                harvestedAnimals: consolidated.harvestedAnimals,
              })
            }

            {
              renderConsolidatedHarvestRow({
                label: t('harvests.animalsHarvested'),
                values: animals,
              })
            }

            {
              renderConsolidatedHarvestRow({
                label: `% ${t('harvests.animalsHarvested')}`,
                values: percents,
              })
            }

            {
              renderConsolidatedHarvestRow({
                label: t('harvests.detail.stage'),
                values: stages,
              })
            }

            {
              renderConsolidatedHarvestRow({
                label: t('harvests.detail.larvaePerGram'),
                values: larvaePerGrams,
              })
            }

            {
              renderConsolidatedHarvestRow({
                label: t('harvests.unit'),
                values: unitNames,
              })
            }

            {
              renderConsolidatedHarvestRow({
                label: t('harvests.module'),
                values: moduleNames,
              })
            }

            {
              renderConsolidatedHarvestRow({
                label: t('harvests.container'),
                values: containerNames,
              })
            }

            {
              renderConsolidatedHarvestRow({
                label: t('harvests.detail.stockingBindingCode'),
                values: stockingBindingCodes,
                classNameValue: styles.bold,
              })
            }

            {renderGraphOption({
              label: t('production.filters.title'),
              values: [t('stockings.viewCurves'), t('stockings.viewCurves')],
            })}
          </tbody>
        </table>

        <br />

        <table className={styles.table}>
          <tbody className={styles.body}>
            {renderRow({ label: t('harvests.detail.harvested'), value: applyThousandsSeparator(harvestQuantity) })}
            {renderRow({ label: t('harvests.detail.averageWeight'), value: roundTwoDecimals(averageWeight) + ' ' + weightUnits.MG })}
            {renderRow({ label: t('harvests.detail.averageLength'), value: roundTwoDecimals(averageLength) + ' ' + lengthUnits.MM })}
            {renderRow({ label: t('harvests.detail.variationCoefficientWeight'), value: `${roundTwoDecimals(variationCoefficientWeight)} %` })}
            {renderRow({ label: t('harvests.detail.variationCoefficientLength'), value: `${roundTwoDecimals(variationCoefficientLength)} %` })}
            {renderRow({ label: t('harvests.harvestDate'), value: formatMonthDayYear(consolidated.endDate) })}
          </tbody>
        </table>
      </>
    );
  }

  if (harvest?.stocking?._id) {
    return (
      <table className={styles.table}>
        <tbody className={styles.body}>
          {renderRow({ label: t('harvests.detail.animals'), value: applyThousandsSeparator(harvest.stocking.naupliusNumber) })}
          {renderRow({ label: t('harvests.detail.harvested'), value: applyThousandsSeparator(harvest.stocking.harvestQuantity) })}
          {renderRow({ label: t('harvests.detail.liters'), value: applyThousandsSeparator(harvest.stocking.litersNumber) })}
          {renderRow({ label: t('harvests.detail.density'), value: `${calcDensityLarvae({ litersNumber: harvest.stocking.litersNumber, naupliusNumber: harvest.stocking.naupliusNumber })} ${t('stockings.densityNauplii')}` })}
          {renderRow({ label: t('harvests.detail.larvaePerGram'), value: harvest.stocking.larvaePerGram })}
          {renderRow({ label: t('harvests.detail.stage'), value: `${harvest.stocking.stage} ${animalDaysUnit.PL}` })}
          {renderRow({ label: t('harvests.detail.survival'), value: `${roundTwoDecimals(harvest.stocking.survivalRate)}%` })}
          {renderRow({ label: t('harvests.harvestDate'), value: formatMonthDayYear(harvest.stocking.endDate) })}
          {renderRow({ label: t('harvests.unit'), value: harvest.stocking.campusId.name })}
          {renderRow({ label: t('harvests.module'), value: harvest.stocking.moduleId.name })}
          {renderRow({ label: t('harvests.container'), value: harvest.stocking.tankId.name })}
          {renderGraphRow({ label: t('production.filters.title'), value: t('stockings.viewCurves') })}
        </tbody>
      </table>
    );
  }

  return null;
};
