import i18next from 'i18next';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getLanguage } from '../../../utils/language';
import { downloadFile } from '../../../utils/download';
import { formatYearMonthDay, getHoursOffset } from '../../../utils/date';
import { typeErrorStocking } from '../../../config/commons';
import { axiosClient as axios, ApiClient } from '../../../utils/axios_instance';
import { getAveragePigmentationByPoint } from '../../../common/components/charts/ShadedPlot/helpers';
import { openErrorNotification, openSuccessNotification } from '../../../common/notification/Notification';
import { MULTIPHASE_CHART_PDF_URL, MULTIPHASE_URL, STOCKINGS_URL, STOCKING_BINDING_CODE_URL } from '../../../config/config.api';

import { DataMultiphaseMetric, StockingMultiphaseState, Stocking } from './interfaces';

const apiClient: ApiClient = new ApiClient(false);

const initialState: StockingMultiphaseState = {
  isDownloadingFile: false,
  isFetchMultiphase: false,
  multiphase: {
    analysis: [],
    maturationName: '',
    stockings: {
      larvaes: [],
      juveniles: [],
      growOut: {
        stockingId: '',
        density: 0,
        name: '',
        phaseType: '',
        unit: '',
        companyName: '',
        moduleName: '',
        tankName: '',
        unitName: '',
        startDate: '',
        stockings: [],
      },
      juvenile: {
        stockingId: '',
        density: 0,
        name: '',
        phaseType: '',
        unit: '',
        companyName: '',
        moduleName: '',
        tankName: '',
        unitName: '',
        startDate: '',
        stockings: [],
      },
      larvae: {
        stockingId: '',
        density: 0,
        name: '',
        phaseType: '',
        unit: '',
        companyName: '',
        moduleName: '',
        tankName: '',
        unitName: '',
        startDate: '',
        stockings: [],
      },
    },
  },
  selectedStocking: {
    _id: '',
    name: '',
    stockingBindingCode: '',
    bindingStockings: [],
  },
  phaseTypesSelected: [],
  phaseTypesLegend: [],
  juvenileStockingId: '',
  larvaeStockingId: '',
};

export const stockingMultiphaseSlice = createSlice({
  name: 'stockingMultiphase',
  initialState,
  reducers: {
    setIsDownloadingFile: (state: StockingMultiphaseState, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },
    setStocking: (state: StockingMultiphaseState, action: PayloadAction<Stocking>) => {
      state.selectedStocking._id = action.payload._id;
      state.selectedStocking.stockingBindingCode = action.payload.stockingBindingCode;
      state.selectedStocking.bindingStockings = action.payload.bindingStockings;
    },
    setMultiphases: (state: StockingMultiphaseState, action: PayloadAction<DataMultiphaseMetric>) => {
      state.multiphase = action.payload;
    },
    setIsFetchMultiphase: (state: StockingMultiphaseState, action: PayloadAction<boolean>) => {
      state.isFetchMultiphase = action.payload;
    },
    setPhaseTypesSelected: (state: StockingMultiphaseState, action: PayloadAction<string[]>) => {
      state.phaseTypesSelected = action.payload;
    },
    setPhaseTypesLegend: (state: StockingMultiphaseState, action: PayloadAction<string[]>) => {
      state.phaseTypesLegend = action.payload;
    },
    setJuvenileStockingId: (state: StockingMultiphaseState, action: PayloadAction<string>) => {
      state.juvenileStockingId = action.payload;
    },
    setLarvaeStockingId: (state: StockingMultiphaseState, action: PayloadAction<string>) => {
      state.larvaeStockingId = action.payload;
    },
  },
});

export const {
  setIsDownloadingFile,
  setStocking,
  setMultiphases,
  setIsFetchMultiphase,
  setPhaseTypesSelected,
  setPhaseTypesLegend,
  setJuvenileStockingId,
  setLarvaeStockingId,
} = stockingMultiphaseSlice.actions;

interface PropsStockingBindingCode {
  stockingId: string;
  companyId: string;
  onFinishingRequest?: Function;
}

const catchStockingBindingCode = (props: { e: AxiosError; }) => {
  const { e } = props;
  const errorType = e?.response?.data?.data?.error;

  switch (errorType) {
    case typeErrorStocking.ALREADY_HAS_BINDING_CODE:
      openErrorNotification(i18next.t('stockings.errors.stockingAlreadyHasBindingCode'));
      break;
  }
};

export const createStockingBindingCode = async (props: PropsStockingBindingCode) => {
  const { companyId, stockingId, onFinishingRequest } = props;
  const body = { companyId, stockingId };

  try {
    await axios.post(STOCKING_BINDING_CODE_URL, body);
    openSuccessNotification(i18next.t('stockings.stockingAlreadyHasBindingCode'));

    if (onFinishingRequest) {
      onFinishingRequest();
    }
  } catch (e) {
    console.log(e?.response);
    catchStockingBindingCode({ e });
  }
};

interface ParamsMultiphasePdf {
  companyId: string;
  stockingId: string;
  stockingName: string;
  parameter: string;
  scale: string;
  stockingPhaseType: string;
  phaseTypeSelected?: string;
  juvenileStockingId?: string;
  larvaeStockingId?: string;
}

export const fetchUrlMultiphasePdf = (params: ParamsMultiphasePdf) => async (dispatch: Function) => {
  const { companyId, stockingId, stockingName, parameter, scale, stockingPhaseType, phaseTypeSelected, juvenileStockingId, larvaeStockingId } = params;
  dispatch(setIsDownloadingFile(true));

  try {
    const response = await axios.get(MULTIPHASE_CHART_PDF_URL);
    const language = getLanguage();
    const accessToken = localStorage.getItem('accessToken');
    const url = `${response.data.url}?companyId=${companyId}&stockingId=${stockingId}&parameter=${parameter}&scale=${scale}&accessToken=${accessToken}&language=${language}&stockingPhaseType=${stockingPhaseType}&phaseTypeSelected=${phaseTypeSelected}&hoursOffset=${getHoursOffset()}&juvenileStockingId=${juvenileStockingId}&larvaeStockingId=${larvaeStockingId}`;
    console.log('url', url);
    await downloadFile(url, stockingName, 'pdf');
  } catch (e) {
    console.log(e.response);
  }

  dispatch(setIsDownloadingFile(false));
};

export const fetchMultiphase = (props: { stockingId: string; phaseType: string; token?: string; larvaeStockingId?: string; juvenileStockingId?: string }) => async (dispatch: Function) => {
  const { stockingId, phaseType, token, larvaeStockingId, juvenileStockingId } = props;
  const params = { stockingId, phaseType, larvaeStockingId, juvenileStockingId };

  let response;
  dispatch(setIsFetchMultiphase(true));

  try {
    if (token) {
      response = await apiClient.axios.get<DataMultiphaseMetric>(MULTIPHASE_URL, {
        headers: { 'Authorization': token },
        params: params,
      });

      dispatch(setIsFetchMultiphase(false));
      const multiphases = injectPigmentation(response.data);
      dispatch(setMultiphases(multiphases));
      multiphases.stockings.larvae?.stockingId && dispatch(setLarvaeStockingId(multiphases.stockings.larvae.stockingId));
      multiphases.stockings.juvenile?.stockingId && dispatch(setJuvenileStockingId(multiphases.stockings.juvenile.stockingId));

      return;
    }

    response = await axios.get<DataMultiphaseMetric>(MULTIPHASE_URL, { params: params });
    dispatch(setIsFetchMultiphase(false));
    const multiphases = injectPigmentation(response.data);
    dispatch(setMultiphases(multiphases));
    multiphases.stockings.larvae?.stockingId && dispatch(setLarvaeStockingId(multiphases.stockings.larvae.stockingId));
    multiphases.stockings.juvenile?.stockingId && dispatch(setJuvenileStockingId(multiphases.stockings.juvenile.stockingId));
  } catch (e) {
    console.log(e);
    console.log(e?.response);
  }
};

const injectPigmentation = (multiphasesMetric: DataMultiphaseMetric) => {
  for (let index = 0; index < multiphasesMetric.analysis.length; index++) {
    multiphasesMetric.analysis[index].resultData.pigmentation = getAveragePigmentationByPoint(multiphasesMetric.analysis[index].resultData.histogramPigmentation);
    multiphasesMetric.analysis[index].createdAt = formatYearMonthDay(multiphasesMetric.analysis[index].createdAt);
  }

  return multiphasesMetric;
};

export const resetMultiphases = () => (dispatch: Function) => {
  const params = {
    analysis: [],
    maturationName: '',
    stockings: {
      larvaes: [],
      juveniles: [],
      growOut: {
        stockingId: '',
        density: 0,
        name: '',
        phaseType: '',
        unit: '',
        companyName: '',
        moduleName: '',
        tankName: '',
        unitName: '',
        startDate: '',
        stockings: [],
      },
      juvenile: {
        stockingId: '',
        density: 0,
        name: '',
        phaseType: '',
        unit: '',
        companyName: '',
        moduleName: '',
        tankName: '',
        unitName: '',
        startDate: '',
        stockings: [],
      },
      larvae: {
        stockingId: '',
        density: 0,
        name: '',
        phaseType: '',
        unit: '',
        companyName: '',
        moduleName: '',
        tankName: '',
        unitName: '',
        startDate: '',
        stockings: [],
      },
    },
  };
  dispatch(setMultiphases(params));
};

export const fetchStocking = (id: string, token?: string) => async (dispatch: Function) => {
  dispatch(setStocking(initialState.selectedStocking));

  const params = {
    $select: ['bindingStockings'],
  };

  try {
    if (token) {
      const response = await apiClient.axios.get(`${STOCKINGS_URL}/${id}`, {
        headers: { 'Authorization': token },
        params,
      });
      dispatch(setStocking(response.data));
      return;
    }

    const response = await axios.get(`${STOCKINGS_URL}/${id}`, { params });
    dispatch(setStocking(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export default stockingMultiphaseSlice.reducer;