import { BindingStocking, SORT_TYPE, StockingsInBatch, Container, BindingStockingTable } from './interfaces';

export const initialBindingStocking = { _id: '', key: 0, bindingCode: '', code: '', name: '', isValid: false, animals: 0, maturationId: '', maturationCode: '', labId: '', labName: '', endDate: '', stage: 0, validatedCode: false, phaseType: '' };
export const defaultDestinationStocking = { animalsNumber: 0, campusId: '', code: '', labId: '', labName: '', moduleId: '', name: '', startDate: '', tankId: '', type: '' };
export const initialStockingsInBatch: StockingsInBatch = { campusId: '', code: '', startDate: '', moduleId: '', tanks: [], daysToInitialStage: 0, };
export const defaultContainerData: Container = { _id: '', code: '', name: '', status: '', type: '', volume: 0 };

export const getSortType = (sortType: SORT_TYPE) => {
  if (sortType === SORT_TYPE.ASC) {
    return SORT_TYPE.DESC;
  }
  if (sortType === SORT_TYPE.DESC) {
    return SORT_TYPE.DEFAULT;
  }
  return SORT_TYPE.ASC;
};

export const getBindingStockingByMaxAnimals = (bindingStockings: BindingStocking[]) => {
  const bindingStocking = bindingStockings.reduce((maxStockingBinding, currentStockingBinding) => {
    if (currentStockingBinding.animals > maxStockingBinding.animals) {
      return currentStockingBinding;
    }

    return maxStockingBinding;
  }, bindingStockings[0]);

  return bindingStocking;
};

export const getTotalAnimals = (bindingStockings: BindingStocking[]) => {
  return bindingStockings.reduce((total, currentStockingBinding) => total + currentStockingBinding.animals, 0);
};

export const isValidStockingBinding = (bindingStockings: BindingStocking[]) => {
  if (!bindingStockings.length) {
    return false;
  }

  for (let index = 0; index < bindingStockings.length; index++) {
    const bindingStocking = bindingStockings[index];
    if (!bindingStocking.isValid || bindingStocking.animals <= 0) {
      return false;
    }
  }

  return true;
};

export const stockingBindingRowIsFull = (bindingStockings: BindingStocking[]) => {
  if (!bindingStockings.length) {
    return false;
  }

  for (let index = 0; index < bindingStockings.length; index++) {
    const bindingStocking = bindingStockings[index];
    if (!bindingStocking.bindingCode || bindingStocking.animals <= 0) {
      return false;
    }
  }

  return true;
};

export const getBindingStockingData = (bindingStockings: BindingStocking[]): BindingStockingTable[] => {
  const data: { key: number; bindingCode: string; animals: number; isValid: boolean; validatedCode: boolean }[] = [];

  for (let i = 0; i < bindingStockings.length; ++i) {
    data.push({
      key: i,
      bindingCode: bindingStockings[i].bindingCode,
      animals: bindingStockings[i].animals,
      isValid: bindingStockings[i].isValid,
      validatedCode: bindingStockings[i].validatedCode,
    });
  }

  return data;
};

export const isValidStockingBindingRow = (bindingStocking: BindingStocking) => {
  return !!bindingStocking.bindingCode && bindingStocking.animals > 0;
};
