import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { formatMonthDayYear } from '../../utils/date';

import styles from './Footer.module.scss';

export default function Footer () {
  const [t] = useTranslation();

  const date = moment();
  const initialDate = formatMonthDayYear(date.toString());
  const footer = t('stockings.pdf.footer', { initialDate: initialDate });

  return (
    <div className={styles.footer}>
      {footer}
    </div>
  );
}
