import cx from 'classnames';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { useState, useEffect, useRef, MutableRefObject, Key } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { FilterConfirmProps, FilterDropdownProps } from 'antd/lib/table/interface';
import { Space, Button, Row, Form, Select, Tooltip, Col, Dropdown, Menu, InputRef } from 'antd';

import '../Clients/Clients.scss';
import { RootState } from '../../state/store';
import { Client } from '../Clients/interfaces';
import { filterOptionSelect } from '../../utils/select';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { changeHeader } from '../AppHeader/headerSlice';
import { getUserSession } from '../../utils/userSession';
import { LrvTag } from '../../common/components/LrvTag/LrvTag';
import { GenericParam } from '../../common/interfaces/commons';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { LrvSwitch } from '../../common/components/LrvSwitch/LrvSwitch';
import PasswordPolicy from '../../common/components/LrvPopover/PasswordPolicy';
import { LrvCheckbox } from '../../common/components/LrvCheckbox/LrvCheckbox';
import { LrvPassword } from '../../common/components/LrvPassword/LrvPassword';
import { ExtraActionsButton } from '../../common/components/buttons/ExtraActionsButton';
import { isFieldsTouchedCustom, validatePasswordPolicy } from '../../utils/validations';
import { setOnboardingType, setShouldRun, setRun, goToOnboardingNextStep, setStepIndex, setExistsUserData } from '../../common/components/Onboarding/OnboardingSlice';
import { isPromoterRole, isSalesRole, isSuperAdmin, LARVIA_ID, onboardingTypes, roles, maxNumberOfCampusToShow, PATTERN, isBusinessManagerRole, isOwnerRole, isDistributorCompany, isSalesManagerRole, userOptions, THEME } from '../../config/commons';

import styles from './Users.module.scss';
import * as usersSlice from './usersSlice';
import { UserSubHeader } from './UserSubHeader';
import AssigmentBalances from './AssigmentBalances';
import { getValueActiveUsers, USERS } from './helpers';
import { User, Campus, UserMapData, CreateUser, EditUser } from './interfaces';

const { Option } = Select;
const ALL_UNITS = 'ALL_UNITS';

function Users () {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newUserFirstName, setNewUserFirstName] = useState('');
  const [newUserLastName, setNewUserLastName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState(['']);
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newSeller, setNewSeller] = useState<string | undefined>(undefined);
  const [newUserCampus, setNewUserCampus] = useState(['']);
  const [newPromoters, setNewPromoters] = useState<string[] | undefined>([]);
  const [allowXlsxAnalysisReport, setAllowXlsxAnalysisReport] = useState<boolean>(false);
  const [allowCompanyBalanceConsumption, setAllowCompanyBalanceConsumption] = useState(true);

  const [disabledButtonFormNewUser, setDisabledButtonFormNewUser] = useState(true);
  const [disabledButtonFormEditUser, setDisabledButtonFormEditUser] = useState(false);
  const [disabledButtonFormResetPassword, setDisabledButtonFormResetPassword] = useState(true);

  const [updateOwnerRole, setUpdateOwnerRole] = useState('');
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showChangeRoleModal, setShowChangeRoleModal] = useState(false);

  const [assignOwnerUser, setAssignOwnerUser] = useState('');
  const [showAssignOwnerModal, setShowAssignOwnerModal] = useState(false);

  const [userSessionPassword, setUserSessionPassword] = useState('');
  const [userTargetNewPassword, setUserTargetNewPassword] = useState('');

  const users = useSelector((state: RootState) => state.users.users);
  const activeUsers = useSelector((state: RootState) => state.users.activeUsers);
  const selectedUser: User = useSelector((state: RootState) => state.users.selectedUser as User);
  const sellers = useSelector((state: RootState) => state.users.sellers);
  const promoters = useSelector((state: RootState) => state.users.promoters);
  const companyCampus = useSelector((state: RootState) => state.users.campus);
  const { firstName, lastName, email, campus: userCampus } = selectedUser;
  const isUserLoading = useSelector((state: RootState) => state.users.isUserLoading);
  const isUsersLoading = useSelector((state: RootState) => state.users.isUsersLoading);
  const isResettingPassword = useSelector((state: RootState) => state.users.isResettingPassword);
  const hasNewPasswordError = useSelector((state: RootState) => state.users.hasNewPasswordError);
  const canCloseResettingPasswordModal = useSelector((state: RootState) => state.users.canCloseResettingPasswordModal);
  const hasCurrentPasswordError = useSelector((state: RootState) => state.users.hasCurrentPasswordError);
  const passwordResetErrorKey = useSelector((state: RootState) => state.users.passwordResetErrorKey);
  const isFormCreateLoading = useSelector((state: RootState) => state.users.isFormCreateLoading);
  const isFormUpdateLoading = useSelector((state: RootState) => state.users.isFormUpdateLoading);
  const hasEmailErrorCreate = useSelector((state: RootState) => state.users.hasEmailErrorCreate);
  const hasEmailErrorUpdate = useSelector((state: RootState) => state.users.hasEmailErrorUpdate);

  const owner: Client = useSelector((state: RootState) => state.users.owner as Client);
  const isUpdatingOwner = useSelector((state: RootState) => state.users.isUpdatingOwner);
  const isAssigningOwner = useSelector((state: RootState) => state.users.isAssigningOwner);
  const userStatus = useSelector((state: RootState) => state.users.filters.userStatus);
  const { company } = useSelector((state: RootState) => state.header);

  const [activeUser, setActiveUser] = useState(true);
  const [keepOpenNewUserModal, setKeepOpenNewUserModal] = useState(false);

  const refSearchName = useRef<InputRef | null>(null);
  const refSearchEmail = useRef<InputRef | null>(null);

  const passwordRef = useRef<InputRef | null>(null);
  const usersDivRef = useRef<HTMLDivElement>(null);

  const {
    existsUserData,
    run: isRunningOnboarding,
    user: onboardingUserData,
  } = useSelector((state: RootState) => state.onboarding);

  const userSession = getUserSession();
  const showBusinessManagerRole = (((isSuperAdmin() || isSalesRole() || isSalesManagerRole()) && company.isDistributor) || (isDistributorCompany() && isOwnerRole()));
  const hasPermissionToManageCompanies = isSuperAdmin() || isSalesRole() || isSalesManagerRole() || isPromoterRole() || isBusinessManagerRole();

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    return () => {
      dispatch(setExistsUserData(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (existsUserData && !onboardingUserData.onboarding?.usersSection) {
      setTimeout(() => {
        dispatch(setStepIndex(0));
        dispatch(setOnboardingType(onboardingTypes.USERS_SECTION));
        dispatch(setShouldRun(true));
        dispatch(setRun(true));
      }, 500);
    }
  }, [dispatch, onboardingUserData, existsUserData]);

  useEffect(() => {
    dispatch(changeHeader({ title: 'users.title' }));
  }, [dispatch]);

  useEffect(() => {
    if (!company._id) {
      return;
    }

    if (hasPermissionToManageCompanies) {
      const active = getValueActiveUsers(USERS.ACTIVE);
      dispatch(usersSlice.fetchUsers({ company, active }));
      dispatch(usersSlice.setSelectedUser({ _id: '', companyId: '', firstName: '', lastName: '', email: '', active: true, roles: [], allowCompanyBalanceConsumption: false }));
      return;
    }

    dispatch(usersSlice.fetchUsers({ company, active: true }));
    dispatch(usersSlice.fetchCampus());
  }, [dispatch, company._id, hasPermissionToManageCompanies]);

  useEffect(() => {
    if (canCloseResettingPasswordModal && !isResettingPassword) {
      setShowResetPasswordModal(false);
      setDisabledButtonFormResetPassword(true);

      form.setFieldsValue({
        currentPassword: undefined,
        newPassword: undefined,
      });

      setUserSessionPassword('');
      setUserTargetNewPassword('');
    }
  }, [canCloseResettingPasswordModal, form, isResettingPassword]);

  useEffect(() => {
    if (selectedUser._id) {
      const campusId = userCampus?.map((campus) => campus._id) || [''];
      const promoters = selectedUser.promoters?.map((promoter) => promoter._id) || [''];

      setActiveUser(selectedUser.active);
      setNewUserFirstName(firstName);
      setNewUserLastName(lastName);
      setNewUserEmail(email);
      setNewUserRole(selectedUser.roles);
      setNewUserCampus(campusId);

      setNewPromoters(promoters);
      setAllowCompanyBalanceConsumption(selectedUser.allowCompanyBalanceConsumption);

      if (selectedUser.allowXlsxAnalysisReport === undefined) {
        setAllowXlsxAnalysisReport(false);
      } else {
        setAllowXlsxAnalysisReport(selectedUser.allowXlsxAnalysisReport);
      }

      form.setFieldsValue({
        editFirstName: firstName,
        editLastName: lastName,
        editEmail: email,
        editRole: selectedUser.roles,
        editCampuses: campusId,
        editSeller: selectedUser.sellerId,
        editPromoters: promoters,
        allowXlsxAnalysisReport: selectedUser.allowXlsxAnalysisReport,
        allowCompanyBalanceConsumption: selectedUser.allowCompanyBalanceConsumption,
      });
    }
  }, [form, selectedUser._id, selectedUser.active, firstName, lastName, email, selectedUser.roles, userCampus, selectedUser.sellerId, selectedUser.promoters, selectedUser.allowXlsxAnalysisReport, selectedUser.allowCompanyBalanceConsumption]);

  useEffect(() => {
    if (!isUpdatingOwner) {
      setShowChangeRoleModal(false);
      dispatch(usersSlice.resetOwner());
      form.resetFields();
    }
  }, [dispatch, form, isUpdatingOwner]);

  useEffect(() => {
    if (!isAssigningOwner) {
      setShowAssignOwnerModal(false);
      form.resetFields();
    }
  }, [form, isAssigningOwner]);

  const usersData: UserMapData[] = users ? users.map((user: User, index) => {
    return {
      key: index,
      _id: user._id,
      name: `${user.firstName} ${user.lastName}`,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      active: user.active,
    };
  }) : [];

  //const columns: any = [
  const columns: ColumnsType<UserMapData> = [
    {
      key: 1,
      title: t('users.user'),
      dataIndex: 'name',
      ellipsis: { showTitle: false },
      width: '30%',
      ...getColumnSearchProps('name', refSearchName),
    },
    {
      key: 2,
      title: t('users.email'),
      dataIndex: 'email',
      ellipsis: { showTitle: false },
      width: '30%',
      responsive: ['sm'] as Breakpoint[],
      ...getColumnSearchProps('email', refSearchEmail),
    },
    {
      key: 3,
      dataIndex: 'active',
      width: '30%',
      render: (_, record: UserMapData) => {
        return (
          <Space align='center' className={styles.statusCell} size={5}>
            {renderStatusTag(record)}
          </Space>
        );
      },
    },
    {
      key: 4,
      width: '10%',
      render: (_, record: UserMapData) => {
        return (
          <div className={styles.optionsCell}>
            {renderDropdownUserOptions(record)}
          </div>
        );
      },
    }
  ];

  const renderStatusTag = (record: UserMapData) => {
    if (record.active) {
      // if return null, table will display an "-" in the empty cell
      return <div></div>;
    }

    const id = `tag_${record._id}`;
    return (
      <LrvTag id={id} type='info' className={styles.statusTag}>{t('users.userInactive')}</LrvTag>
    );
  };

  const renderDropdownUserOptions = (user: UserMapData) => {
    return (
      <Dropdown
        overlay={() => menuItemsUserOptions(user)}
        trigger={['click']}
        placement='bottomRight'
      >
        <ExtraActionsButton
          onClick={(e) => e.stopPropagation()}
          id={`button_${user._id}`}
        />
      </Dropdown>
    );
  };

  const menuItemsUserOptions = (user: UserMapData) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case userOptions.EDIT:
              onEditUserOption(user);
              break;

            case userOptions.CHANGE_PASSWORD:
              onChangePasswordOption(user);
              break;
          }
        }}
      >
        <Menu.Item id='menu_option_edit' key={userOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' theme={theme} className={styles.icon} />
          <span>{t('users.editUser')}</span>
        </Menu.Item>

        <Menu.Item disabled={isRunningOnboarding} id='menu_option_reset_password' key={userOptions.CHANGE_PASSWORD} className={styles.menuItemOptions}>
          <Icon name='refresh' theme={theme} className={styles.icon} />
          <span>{t('users.resetPassword')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  function onEditUserOption (user: UserMapData) {
    if (company._id === LARVIA_ID) {
      dispatch(usersSlice.fetchSellers());
      dispatch(usersSlice.fetchUnassignedPromoters());
    }

    setShowEditModal(true);
    dispatch(usersSlice.fetchUser(user._id));
    if (isRunningOnboarding) {
      dispatch(goToOnboardingNextStep(1000));
    }
  }

  function onChangePasswordOption (user: UserMapData) {
    setShowResetPasswordModal(true);
    dispatch(usersSlice.fetchUser(user._id));
    if (isRunningOnboarding) {
      dispatch(goToOnboardingNextStep(1000));
    }
  }

  const searchUsers = (props: { dataIndex: string; confirm: (param?: FilterConfirmProps | undefined) => void; active?: boolean | undefined; value: Key[] }) => {
    const { dataIndex, confirm, active, value } = props;
    confirm();

    switch (dataIndex) {
      case 'name':
        dispatch(usersSlice.fetchSearchUsers({ companyId: company._id, active: active, name: value[0] }));
        break;

      case 'email':
        dispatch(usersSlice.fetchSearchUsers({ companyId: company._id, active: active, email: value[0] }));
        break;
    }
  };

  function getColumnSearchProps (dataIndex: string, ref: MutableRefObject<InputRef | null>) {
    const active = getValueActiveUsers(userStatus);
    const label = `users.paramsSearch.${dataIndex}`;
    const placeholder = `${t('users.search')} ${t(label)}`;

    return {
      filterDropdown: (props: FilterDropdownProps) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
        return (
          <div className={styles.menuSearch}>
            <LrvInput
              theme='light'
              id={`txt_${dataIndex}_search`}
              ref={ref}
              placeholder={placeholder}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => {
                if (selectedKeys[0]) {
                  clearFilters && clearFilters();
                  searchUsers({ dataIndex, confirm, active, value: selectedKeys });
                }
              }}
              className={styles.inputSearch}
            />
            <Space>
              <Button
                id={`btn_${dataIndex}_search`}
                type='primary'
                onClick={() => {
                  clearFilters && clearFilters();
                  searchUsers({ dataIndex, confirm, active, value: selectedKeys });
                }}
                icon={<SearchOutlined />}
                size='small'
                className={styles.buttonSearch}
                disabled={!selectedKeys[0]}
              >
                {t('users.search')}
              </Button>
              <Button
                id={`btn_${dataIndex}_reset`}
                onClick={() => {
                  clearFilters && clearFilters();
                  dispatch(usersSlice.fetchUsers({ company: company, active: active }));
                }}
                size='small'
                className={styles.buttonReset}
              >
                {t('users.reset')}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => <SearchOutlined className={isLightTheme ? styles.searchOutlinedIconLight : styles.searchOutlinedIconDark} style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value: boolean | Key, record: UserMapData) => {
        const item: GenericParam = record;
        const field: string = item[dataIndex].toString().toLowerCase();
        return field.includes(`${value}`.toLowerCase());
      },
      onFilterDropdownOpenChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => ref.current?.select(), 100);
        }
      },
    };
  }

  function renderSelectRole () {
    return <LrvSelect
      theme='light'
      mode='multiple'
      value={newUserRole}
      suffixIcon={<Icon name='arrow-down-s' />}
      placeholder={t('users.campusPlaceHolder')}
      onChange={onChangeRole}
      removeIcon={<Icon name='close' />}
    >
      <Option value={roles.CLIENT_OPERATOR}>
        <div id='edit_role_operator'>{t('users.clientOperator')}</div>
      </Option>
      <Option value={roles.CLIENT_MANAGER}>
        <div id='edit_role_manager'>{t('users.clientManager')}</div>
      </Option>

      <Option value={roles.TRADING}>
        <div id='edit_role_trading'>{t('users.trading')}</div>
      </Option>

      {
        (hasPermissionToManageCompanies) &&
        <Option value={roles.CLIENT_OWNER} >
          <div id='edit_role_owner'> {t('users.clientOwner')} </div>
        </Option>
      }

      {
        showBusinessManagerRole &&
        <Option value={roles.BUSINESS_MANAGER} >
          <div id='edit_role_business_manager'> {t('users.businessManager')} </div>
        </Option>
      }

      {
        (isSuperAdmin() || isSalesRole() || isSalesManagerRole()) && company._id === LARVIA_ID &&
        <Option value={roles.PROMOTER} >
          <div id='edit_role_promoter'> {t('users.clientPromoter')} </div>
        </Option>
      }

      {!(isSuperAdmin() || isSalesRole() || isSalesManagerRole() || isSalesManagerRole() || isPromoterRole() || isBusinessManagerRole()) && newUserRole.includes(roles.CLIENT_OWNER) ?
        <Option value={roles.CLIENT_OWNER}>
          <div id='edit_role_owner'> {t('users.clientOwner')} </div>
        </Option> : null}
    </LrvSelect>;
  }

  function generateRandomPassword () {
    let randomPassword = '';
    const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const initialIndex = 0;

    randomPassword += upperCase[getRandomInteger(initialIndex, upperCase.length - 1)];
    randomPassword += upperCase[getRandomInteger(initialIndex, upperCase.length - 1)];
    randomPassword += lowerCase[getRandomInteger(initialIndex, lowerCase.length - 1)];
    randomPassword += lowerCase[getRandomInteger(initialIndex, lowerCase.length - 1)];
    randomPassword += numbers[getRandomInteger(initialIndex, numbers.length - 1)];
    randomPassword += numbers[getRandomInteger(initialIndex, numbers.length - 1)];
    return randomPassword;
  }

  const getRandomInteger = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  function renderGeneratePasswordButton () {
    return <Tooltip trigger='click' title={t('users.passwordCopied')}>
      <Button
        id='generate_password_button'
        htmlType='button'
        className={styles.btnGeneratePassword}
        onClick={() => {
          const randomPassword = generateRandomPassword();
          setUserTargetNewPassword(randomPassword);
          form.setFieldsValue({
            newPassword: randomPassword,
          });

          setDisabledButtonFormResetPassword(
            userSessionPassword === '' ||
            form.getFieldsError().filter(({ errors }) => errors.length).length > 0
          );

          if (navigator.clipboard) {
            navigator.clipboard.writeText(randomPassword);
          }
        }}
      >{t('users.generatePassword')}</Button>
    </Tooltip>;
  }

  function validateEditUser () {
    if (!selectedUser.roles.includes(roles.CLIENT_OWNER) && newUserRole.includes(roles.CLIENT_OWNER)) {
      dispatch(usersSlice.fetchClient(selectedUser.companyId));
      return setShowChangeRoleModal(true);
    }

    if (selectedUser.roles.includes(roles.CLIENT_OWNER) && !newUserRole.includes(roles.CLIENT_OWNER)) {
      dispatch(usersSlice.fetchActiveUsers(selectedUser.companyId));
      return setShowAssignOwnerModal(true);
    }

    editUserSelected();
  }

  function getUpdateSellersData () {
    const updateSellersData = [];

    if (!selectedUser.roles.includes(roles.SALES)) {
      return [];
    }

    const selectedPromoters = selectedUser.promoters?.map((promoter) => promoter._id) || [];
    const unassignedPromoters = promoters.map((promoter) => promoter._id) || [];

    const newPromotersIds = newPromoters?.filter(promoter => unassignedPromoters.includes(promoter)) || [];
    const oldPromotersIds = selectedPromoters?.filter(promoter => !newPromoters?.includes(promoter)) || [];

    for (let index = 0; index < newPromotersIds.length; index++) {
      const newPromoterId = newPromotersIds[index];

      const data = {
        userId: newPromoterId,
        companyId: selectedUser.companyId,
        sellerId: selectedUser._id,
      };
      updateSellersData.push(data);
    }

    for (let index = 0; index < oldPromotersIds.length; index++) {
      const oldPromoterId = oldPromotersIds[index];

      const data = {
        userId: oldPromoterId,
        companyId: selectedUser.companyId,
        sellerId: null,
      };
      updateSellersData.push(data);
    }

    return updateSellersData;
  }

  function editUserSelected () {
    const userData: EditUser = {
      userId: selectedUser._id,
      firstName: newUserFirstName,
      lastName: newUserLastName,
      email: newUserEmail,
      roles: newUserRole,
      company: company,
      active: activeUser,
      campus: newUserCampus,
      allowXlsxAnalysisReport,
      allowCompanyBalanceConsumption,
    };

    if (newUserRole.includes(roles.PROMOTER)) {
      userData.sellerId = newSeller;
    } else if (selectedUser.roles.includes(roles.PROMOTER)) {
      userData.sellerId = null;
    }

    const activeUsers = getValueActiveUsers(userStatus);
    const updateSellersData = getUpdateSellersData();
    dispatch(usersSlice.updateUser(userData, activeUsers, updateSellersData, resetEditForm));
  }

  const onChangeRole = (value: string[]) => {
    if (newSeller && !value.includes(roles.PROMOTER)) {
      setNewSeller(undefined);
      form.setFieldsValue({
        seller: undefined,
        editSeller: undefined,
      });
    }
    setNewUserRole(value);
  };

  const resetCreateForm = () => {
    if (!keepOpenNewUserModal) {
      setShowCreateModal(false);
    }

    setDisabledButtonFormNewUser(true);
    setAllowXlsxAnalysisReport(false);
    setAllowCompanyBalanceConsumption(true);
    form.resetFields();
  };

  const resetEditForm = () => {
    setShowEditModal(false);
    setDisabledButtonFormNewUser(true);
    form.resetFields();
  };

  const createUser = () => {
    const campusIds = newUserCampus.includes(ALL_UNITS) ? companyCampus.map((item: Campus) => item._id) : newUserCampus;

    const userData: CreateUser = {
      firstName: newUserFirstName,
      lastName: newUserLastName,
      email: newUserEmail,
      password: newUserPassword,
      roles: newUserRole,
      campus: campusIds,
      company: company,
      allowCompanyBalanceConsumption,
      allowXlsxAnalysisReport,
    };

    if (newUserRole.includes(roles.PROMOTER)) {
      userData.sellerId = newSeller;
    }

    const activeUsers = getValueActiveUsers(userStatus);
    dispatch(usersSlice.createUser(userData, activeUsers, resetCreateForm));
  };

  const onClickAddUser = () => {
    setNewUserFirstName('');
    setNewUserLastName('');
    setNewUserEmail('');
    setNewUserPassword('');
    setNewUserRole([]);
    setNewUserCampus([]);
    setNewSeller(undefined);
    setKeepOpenNewUserModal(false);
    setAllowCompanyBalanceConsumption(true);
    setAllowXlsxAnalysisReport(false);
    dispatch(usersSlice.setSelectedUser({ _id: '', companyId: '', firstName: '', lastName: '', email: '', active: true, roles: [], allowCompanyBalanceConsumption: false }));
    setShowCreateModal(true);

    if (company._id === LARVIA_ID) {
      dispatch(usersSlice.fetchSellers());
    }
  };

  const onCloseEditModal = () => {
    setShowEditModal(false);
    form.resetFields();

    if (isRunningOnboarding) {
      dispatch(goToOnboardingNextStep(800));
    }
  };

  return <div className={styles.users}>
    <UserSubHeader onClickAddUser={onClickAddUser} theme={theme} />

    <Row className={styles.rowFlex}>
      <LrvTable
        className={styles.tableUsers}
        rowClassName={(record) => cx(styles.userRow, selectedUser._id === record._id ? styles.selectedRow : '')}
        columns={columns}
        dataSource={usersData}
        loading={isUsersLoading}
        scroll={{ y: '' }}
        theme={theme}
        size='small'
        pagination={{
          size: 'default',
        }}
      />
    </Row>

    <LrvModal
      theme='light'
      isLoading={isUserLoading || isFormUpdateLoading}
      wrapClassName={styles.editUserModal}
      title={t('users.editUser')}
      open={showEditModal}
      closeIcon={<Icon id='close_edit_user_modal' name='close' />}
      destroyOnClose={true}
      footer={[
        <div key='right'>
          <Button
            key='cancel'
            id='cancel_new_user'
            onClick={onCloseEditModal}
          >
            {t('users.cancel')}
          </Button>
          <Button
            id='submit_edit_user'
            style={{ float: 'right' }}
            disabled={disabledButtonFormEditUser}
            type='primary'
            htmlType='submit'
            form='formEditUser'
            className={styles.button}
            loading={isFormUpdateLoading}
          >
            {t('users.save')}
          </Button>
        </div>,
      ]}
      onCancel={onCloseEditModal}
    >
      <div className={styles.contentHeader}>
        <div className={styles.title}>{email ? `${firstName} ${lastName}` : t('users.user')}</div>
        <div className={styles.switch}>
          <div> {t('users.userActive')} </div>
          <LrvSwitch
            theme='light'
            id='active_user_switch'
            className='switchActiveUser'
            checked={activeUser}
            onChange={(value) => setActiveUser(value)}
          />
        </div>
      </div>

      <div className={styles.userData} style={{ height: usersDivRef.current?.offsetHeight }}>
        <LrvForm
          theme='light'
          form={form}
          name='formEditUser'
          id='formEditUser'
          layout='vertical'
          onFieldsChange={() => {
            setDisabledButtonFormEditUser(
              form.getFieldsError().filter(({ errors }) => errors.length).length > 0
            );
          }}
          onFinish={validateEditUser}
        >
          <Form.Item
            label={t('users.firstName')}
            name='editFirstName'
            rules={[{ required: true, message: t('users.firstNameRequired') }]}
          >
            <LrvInput theme='light' placeholder={t('users.userFirstName')} name='firstName' value={newUserFirstName} onChange={(e) => setNewUserFirstName(e.target.value)} />
          </Form.Item>

          <Form.Item
            label={t('users.lastName')}
            name='editLastName'
            rules={[{ required: true, message: t('users.lastNameRequired') }]}
          >
            <LrvInput theme='light' placeholder={t('users.userLastName')} value={newUserLastName} onChange={(e) => setNewUserLastName(e.target.value)} />
          </Form.Item>

          <Form.Item
            label={t('users.email')}
            required
            validateStatus={hasEmailErrorUpdate ? 'error' : undefined}
            help={hasEmailErrorUpdate ? t('users.emailRepeat') : undefined}
            name='editEmail'
            rules={[{ required: true, pattern: PATTERN.EMAIL, message: t('users.emailRequired') }]}
          >
            <LrvInput theme='light' placeholder={t('users.userEmail')} value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} />
          </Form.Item>

          <Form.Item
            label={t('users.role')}
            name='editRole'
            rules={[{ required: true, message: t('users.roleRequired') }]}
          >
            {renderSelectRole()}
          </Form.Item>

          {
            newUserRole.includes(roles.PROMOTER) &&
            <Form.Item
              label={t('users.seller')}
              name='editSeller'
              rules={[{ required: true, message: t('users.sellerRequired') }]}
            >
              <LrvSelect
                theme='light'
                value={newSeller}
                onChange={e => setNewSeller(e)}
                suffixIcon={<Icon name='arrow-down-s' />}
                placeholder={t('users.campusPlaceHolder')}
              >
                {sellers.map((seller) =>
                  <Option key={seller._id} value={seller._id}>
                    {seller.firstName} {seller.lastName}
                  </Option>)}
              </LrvSelect>
            </Form.Item>
          }

          <Form.Item
            label={t('users.campuses')}
            name='editCampuses'
            rules={[{ required: true, message: t('users.campusRequired') }]}
          >
            <LrvSelect
              theme='light'
              mode='multiple'
              placeholder={t('users.campusPlaceHolder')}
              value={newUserCampus}
              suffixIcon={<Icon name='arrow-down-s' />}
              maxTagCount={maxNumberOfCampusToShow}
              onChange={setNewUserCampus}
              removeIcon={<Icon name='close' />}
            >
              {companyCampus && companyCampus.map((item: Campus, index) =>
                <Option key={index} value={item._id}>{item.name}</Option>
              )}
            </LrvSelect>
          </Form.Item>

          {
            (newUserRole.includes(roles.SALES) || newUserRole.includes(roles.SALES_MANAGER)) &&
            <Form.Item
              label={t('users.promoters')}
              name='editPromoters'
            >
              <LrvSelect
                theme='light'
                mode='multiple'
                value={newPromoters}
                onChange={e => setNewPromoters(e)}
                suffixIcon={<Icon name='arrow-down-s' />}
                placeholder={t('users.campusPlaceHolder')}
                removeIcon={<Icon name='close' />}
              >
                {selectedUser.promoters?.map((user) =>
                  <Option key={user._id} value={user._id}>
                    {user.firstName} {user.lastName}
                  </Option>
                )}

                {promoters.map((user) =>
                  <Option key={user._id} value={user._id}>
                    {user.firstName} {user.lastName}
                  </Option>
                )}
              </LrvSelect>
            </Form.Item>
          }

          {
            (company.allowXlsxAnalysisReport && hasPermissionToManageCompanies) &&
            <Form.Item
              name='allowXlsxAnalysisReport'
              label={t('users.allowXlsxAnalysisReport')}
            >
              <LrvSwitch
                theme='light'
                id='allow_xlsx_switch'
                checked={allowXlsxAnalysisReport}
                onChange={(value) => setAllowXlsxAnalysisReport(value)}
              />
            </Form.Item>
          }

          <Form.Item
            name='allowCompanyBalanceConsumption'
            label={t('users.allowCompanyBalanceConsumption')}
          >
            <LrvSwitch
              theme='light'
              id='allow_company_balance_consumption'
              checked={allowCompanyBalanceConsumption}
              onChange={(value) => setAllowCompanyBalanceConsumption(value)}
            />
          </Form.Item>
        </LrvForm>
      </div>
    </LrvModal>

    <LrvModal
      theme='light'
      className='newUserModal'
      title={t('users.newUser')}
      open={showCreateModal}
      destroyOnClose={true}
      closeIcon={<Icon id='close_new_user_modal' name='close' />}
      footer={[
        <div key='left' className={styles.keepOpen}>
          <LrvCheckbox
            theme='light'
            checked={keepOpenNewUserModal}
            onChange={event => setKeepOpenNewUserModal(event.target.checked)}
          >
            {t('users.keepOpen')}
          </LrvCheckbox>
        </div>,

        <div key='right'>
          <Button
            key='cancel'
            id='cancel_new_user'
            onClick={() => {
              setShowCreateModal(false);
              form.resetFields();
            }}
          >
            {t('users.cancel')}
          </Button>
          <Button
            type='primary'
            key='submit'
            id='submit_new_user'
            disabled={disabledButtonFormNewUser}
            htmlType='submit'
            form='formNewUser'
            loading={isFormCreateLoading}
            onClick={createUser}
          >
            {t('users.create')}
          </Button>
        </div>,
      ]}
      onCancel={() => {
        setShowCreateModal(false);
        form.resetFields();
      }}
    >
      <LrvForm
        theme='light'
        form={form}
        name='formNewUser'
        id='formNewUser'
        layout='vertical'
        onFieldsChange={() => {
          const formFields = Object.keys(form.getFieldsValue());
          const fieldsNotRequired = ['allowCompanyBalanceConsumption', 'allowXlsxAnalysisReport'];
          const validFormFields = formFields.filter((element) => !fieldsNotRequired.includes(element));

          setDisabledButtonFormNewUser(
            !isFieldsTouchedCustom(form, validFormFields) ||
            form.getFieldsError().filter(({ errors }) => errors.length).length > 0
          );
        }}
      >
        <Form.Item
          label={t('users.firstName')}
          name='firstName'
          rules={[{ required: true, message: t('users.firstNameRequired') }]}
        >
          <LrvInput
            theme='light'
            autoFocus
            value={newUserFirstName}
            onChange={(e) => setNewUserFirstName(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={t('users.lastName')}
          name='lastName'
          rules={[{ required: true, message: t('users.lastNameRequired') }]}
        >
          <LrvInput
            theme='light'
            value={newUserLastName}
            onChange={(e) => setNewUserLastName(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={t('users.email')}
          name='email'
          validateStatus={hasEmailErrorCreate ? 'error' : undefined}
          help={hasEmailErrorCreate ? t('users.emailRepeat') : undefined}
          rules={[{ required: true, pattern: PATTERN.EMAIL, message: t('users.emailRequired') }]}
        >
          <LrvInput
            theme='light'
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={t('users.role')}
          name='role'
          rules={[{ required: true, message: t('users.roleRequired') }]}
        >
          <LrvSelect
            theme='light'
            mode='multiple'
            value={newUserRole}
            onChange={onChangeRole}
            suffixIcon={<Icon name='arrow-down-s' />}
            placeholder={t('users.campusPlaceHolder')}
            removeIcon={<Icon name='close' />}
          >
            <Option value={roles.CLIENT_OPERATOR}>
              <div id='role_operator'>{t('users.clientOperator')}</div>
            </Option>
            <Option value={roles.CLIENT_MANAGER}>
              <div id='role_manager'>{t('users.clientManager')}</div>
            </Option>

            {
              (isSuperAdmin() || isSalesRole() || isSalesManagerRole()) && company._id === LARVIA_ID &&
              <Option value={roles.PROMOTER}>
                <div id='role_manager'>{t('users.clientPromoter')}</div>
              </Option>
            }

            {
              showBusinessManagerRole &&
              <Option value={roles.BUSINESS_MANAGER} >
                <div id='edit_role_business_manager'> {t('users.businessManager')} </div>
              </Option>
            }

          </LrvSelect>
        </Form.Item>

        {
          newUserRole.includes(roles.PROMOTER) &&
          <Form.Item
            label={t('users.seller')}
            name='seller'
            rules={[{ required: true, message: t('users.sellerRequired') }]}
          >
            <LrvSelect
              theme='light'
              value={newSeller}
              onChange={e => setNewSeller(e)}
              suffixIcon={<Icon name='arrow-down-s' />}
              placeholder={t('users.campusPlaceHolder')}
            >
              {sellers.map((seller) =>
                <Option key={seller._id} value={seller._id}>
                  {seller.firstName} {seller.lastName}
                </Option>)}
            </LrvSelect>
          </Form.Item>
        }

        <Form.Item
          label={t('users.campuses')}
          name='campus'
          rules={[{ required: true, message: t('users.campusRequired') }]}
        >
          <LrvSelect
            theme='light'
            mode='multiple'
            style={{ width: '100%' }}
            placeholder={t('users.campusPlaceHolder')}
            onChange={setNewUserCampus}
            removeIcon={<Icon name='close' />}
            showSearch
            optionFilterProp='children'
            filterOption={filterOptionSelect}
          >
            {companyCampus.length > 0 && <Option key={ALL_UNITS} value={ALL_UNITS}>{t('users.allUnits')}</Option>}
            {companyCampus && companyCampus.map((item: Campus, index) =>
              <Option key={index} value={item._id}>{item.name}</Option>
            )}
          </LrvSelect>
        </Form.Item>

        <Form.Item
          label={
            <span>
              {t('users.password')} &nbsp;
              <PasswordPolicy />
            </span>
          }
          required
          name='password'
          rules={[() => ({ validator (rule, value) { return validatePasswordPolicy(value); } })]}
        >
          <LrvPassword
            theme='light'
            autoComplete='new-password'
            value={newUserPassword}
            onChange={(e) => setNewUserPassword(e.target.value)}
          />
        </Form.Item>

        {
          (company.allowXlsxAnalysisReport && hasPermissionToManageCompanies) &&
          <Form.Item
            name='allowXlsxAnalysisReport'
            label={t('users.allowXlsxAnalysisReport')}
          >
            <LrvSwitch
              theme='light'
              id='allow_xlsx_switch'
              checked={allowXlsxAnalysisReport}
              onChange={(value) => setAllowXlsxAnalysisReport(value)}
            />
          </Form.Item>
        }

        <Form.Item
          name='allowCompanyBalanceConsumption'
          label={t('users.allowCompanyBalanceConsumption')}
        >
          <LrvSwitch
            theme='light'
            id='allow_company_balance_consumption'
            checked={allowCompanyBalanceConsumption}
            onChange={(value) => setAllowCompanyBalanceConsumption(value)}
          />
        </Form.Item>
      </LrvForm>
    </LrvModal>

    <AssigmentBalances theme='light' />

    <LrvModal
      theme='light'
      className={styles.resetPasswordModal}
      title={t('users.resetPassword')}
      open={showResetPasswordModal}
      destroyOnClose={true}
      okButtonProps={{ id: 'submit_reset_password', disabled: disabledButtonFormResetPassword, htmlType: 'submit', form: 'formResetPassword', loading: isResettingPassword }}
      onOk={() => {
        dispatch(usersSlice.resetUserPassword(selectedUser._id, userSessionPassword, userTargetNewPassword));
      }}
      okText={t('users.save')}
      cancelText={t('users.cancel')}
      closeIcon={<Icon id='close_reset_password_modal' name='close' />}
      cancelButtonProps={{ id: 'cancel_reset_password' }}
      onCancel={() => {
        setShowResetPasswordModal(false);
        form.setFieldsValue({
          currentPassword: undefined,
          newPassword: undefined,
        });
        dispatch(usersSlice.setIsResettingPassword(false));
        dispatch(usersSlice.setHasNewPasswordError(false));
        dispatch(usersSlice.setHasCurrentPasswordError(false));
        dispatch(usersSlice.setPasswordResetErrorKey(''));
        setDisabledButtonFormResetPassword(true);
        setUserSessionPassword('');
        setUserTargetNewPassword('');
      }}
    >
      <LrvForm
        form={form}
        theme='light'
        name='formResetPassword'
        id='formResetPassword'
        layout='vertical'
        onFieldsChange={() => {
          setDisabledButtonFormResetPassword(
            (userTargetNewPassword === '' || userSessionPassword === '') ||
            form.getFieldsError().filter(({ errors }) => errors.length).length > 0
          );
        }}
      >
        <Form.Item
          className={styles.label}
          label={t('users.yourCurrentPassword', { name: userSession.firstName + ' ' + userSession.lastName })}
          name='currentPassword'
          validateStatus={hasCurrentPasswordError ? 'error' : undefined}
          help={['incorrectPassword'].includes(passwordResetErrorKey) ? t(`users.resetPasswordErrors.${passwordResetErrorKey}`) : null}
          rules={[{ required: true, message: t('users.passwordRequired') }]}
        >
          <LrvPassword
            theme='light'
            autoFocus
            id='currentPassword'
            autoComplete='off'
            value={userSessionPassword}
            onChange={(e) => setUserSessionPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span className={styles.label}>
              {t('users.newUserPassword', { name: selectedUser.firstName + ' ' + selectedUser.lastName })}&nbsp;
              <PasswordPolicy />
            </span>
          }
          name='newPassword'
          validateStatus={hasNewPasswordError ? 'error' : undefined}
          help={['passwordPolicy', 'whiteSpaces'].includes(passwordResetErrorKey) ? t(`users.resetPasswordErrors.${passwordResetErrorKey}`) : null}
          rules={[
            { required: true, message: t('users.passwordRequired') },
            () => ({ validator (rule, value) { return validatePasswordPolicy(value); } })
          ]}
        >
          <Row justify='space-between'>
            <Col span={18}>
              <LrvPassword
                theme='light'
                ref={passwordRef}
                id='newPassword'
                autoComplete='off'
                value={userTargetNewPassword}
                onChange={(e) => setUserTargetNewPassword(e.target.value)}
              />
            </Col>
            {renderGeneratePasswordButton()}
          </Row>
        </Form.Item>
      </LrvForm>
    </LrvModal>

    <LrvModal
      theme='light'
      className='updateOwnerModal'
      title={t('users.ownerUpdated.title')}
      open={showChangeRoleModal}
      destroyOnClose={true}
      okButtonProps={{ id: 'submit_update_owner', disabled: !updateOwnerRole, htmlType: 'submit', form: 'formChangeRole', loading: isUpdatingOwner }}
      cancelButtonProps={{ id: 'cancel_update_owner' }}
      closeIcon={<Icon id='close_update_owner_modal' name='close' />}
      onOk={() => {
        const currentOwnerData = { userId: owner.owner._id, roles: [updateOwnerRole], companyId: selectedUser.companyId };

        dispatch(usersSlice.updateOwner(currentOwnerData));
        editUserSelected();
        setUpdateOwnerRole('');
      }}
      okText={t('users.save')}
      cancelText={t('users.cancel')}
      onCancel={() => {
        setUpdateOwnerRole('');
        dispatch(usersSlice.resetOwner());
        setShowChangeRoleModal(false);
        form.resetFields(['change_role']);
      }}
    >
      <LrvForm
        theme='light'
        form={form}
        name='formChangeRole'
        id='formChangeRole'
        layout='vertical'
      >
        <Form.Item
          name='text'
        >
          <div className={styles.changeOwner}>
            {t('users.ownerUpdated.currentOwnerDescription')}
            <br />
            <br />
            <ul>
              <li>{t('users.ownerUpdated.newOwner')}: {selectedUser?.firstName} {selectedUser?.lastName}</li>
              <li>{t('users.ownerUpdated.currentOwner')}: {owner.owner.firstName} {owner.owner.lastName}</li>
            </ul>
          </div>
        </Form.Item>

        <Form.Item
          label={t('users.role')}
          name='change_role'
          validateStatus={hasCurrentPasswordError ? 'error' : undefined}
          rules={[{ required: true, message: t('users.roleRequired') }]}
        >
          <LrvSelect
            theme='light'
            value={updateOwnerRole}
            suffixIcon={<Icon name='arrow-down-s' />}
            placeholder={t('users.campusPlaceHolder')}
            onChange={setUpdateOwnerRole}
            style={{ 'width': '100%' }}
          >
            <Option value={roles.CLIENT_OPERATOR}>
              <div id='change_role_operator'>{t('users.clientOperator')}</div>
            </Option>
            <Option value={roles.CLIENT_MANAGER}>
              <div id='change_role_manager'>{t('users.clientManager')}</div>
            </Option>
          </LrvSelect>
        </Form.Item>
      </LrvForm>
    </LrvModal>

    <LrvModal
      theme='light'
      className='assignOwnerModal'
      title={t('users.assignOwner.title')}
      open={showAssignOwnerModal}
      destroyOnClose={true}
      okButtonProps={{ id: 'submit_select_owner', disabled: !assignOwnerUser, htmlType: 'submit', form: 'formChangeRole', loading: isAssigningOwner }}
      cancelButtonProps={{ id: 'cancel_update_owner' }}
      closeIcon={<Icon id='close_update_owner_modal' name='close' />}
      onOk={() => {
        const currentOwnerData = { userId: assignOwnerUser, companyId: selectedUser.companyId };

        dispatch(usersSlice.assignOwner(currentOwnerData));
        editUserSelected();
        setAssignOwnerUser('');
      }}
      okText={t('users.save')}
      cancelText={t('users.cancel')}
      onCancel={() => {
        setAssignOwnerUser('');
        setShowAssignOwnerModal(false);
        form.resetFields(['assign_owner']);
      }}
    >
      <LrvForm
        theme='light'
        form={form}
        name='formAssignOwner'
        id='formAssignOwner'
        layout='vertical'
      >
        <Form.Item
          name='text'
        >
          <div className={styles.changeOwner}>
            {t('users.assignOwner.description')}
          </div>
        </Form.Item>

        <Form.Item
          label={t('users.user')}
          name='assign_owner'
          rules={[{ required: true, message: t('users.userRequired') }]}
        >
          <LrvSelect
            theme='light'
            value={assignOwnerUser}
            suffixIcon={<Icon name='arrow-down-s' />}
            showSearch
            optionFilterProp='children'
            filterOption={filterOptionSelect}
            dropdownMatchSelectWidth={false}
            placeholder={t('users.campusPlaceHolder')}
            onChange={(e: string) => {
              setAssignOwnerUser(e);
            }}
          >
            {
              activeUsers.map((user: User, index) => {
                return <Option key={index} value={user._id}>{user.firstName + ' ' + user.lastName}</Option>;
              })
            }
          </LrvSelect>
        </Form.Item>
      </LrvForm>
    </LrvModal>
  </div >;
}

export default Users;
