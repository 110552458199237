import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { showStockingParameterError } from '../helpers';
import { axiosClient as axios } from '../../../utils/axios_instance';
import * as stockingParameterSlice from '../../Analysis/stockingParameterSlice';
import { openSuccessNotification } from '../../../common/notification/Notification';
import { CompanyStockingParameter } from '../../Settings/TabStockingParameters/interfaces';
import { COMPANY_STOCKING_PARAMETER_URL, STOCKING_PARAMETER_URL } from '../../../config/config.api';

import { EditStockingParameter, EditStockingParameterState, StockingParameterData, StockingParameterDataPayload } from './interfaces';

const initialState: EditStockingParameterState = {
  showEditStockingParameterModal: false,
  stockingParameterSelected: {
    _id: '',
    companyId: '',
    data: [],
    date: '',
    stockingId: '',
  },
  companyStockingParameters: [],
  isLoadingEditStockingParameter: false,
};

export const detailStockingParameterSlice = createSlice({
  name: 'detailStockingParameter',
  initialState,
  reducers: {
    setCompanyStockingParameters: (state: EditStockingParameterState, action: PayloadAction<CompanyStockingParameter[]>) => {
      state.companyStockingParameters = action.payload;
    },

    setStockingParameterSelected: (state: EditStockingParameterState, action: PayloadAction<StockingParameterData>) => {
      state.stockingParameterSelected = action.payload;
    },

    setShowEditStockingParameterModal: (state: EditStockingParameterState, action: PayloadAction<boolean>) => {
      state.showEditStockingParameterModal = action.payload;
    },

    setIsLoadingEditStockingParameter: (state: EditStockingParameterState, action: PayloadAction<boolean>) => {
      state.isLoadingEditStockingParameter = action.payload;
    },
  },
});

export const {
  setCompanyStockingParameters,
  setStockingParameterSelected,
  setShowEditStockingParameterModal,
  setIsLoadingEditStockingParameter,
} = detailStockingParameterSlice.actions;

export const fetchCompanyStockingParameters = (props: { companyId: string }) => async (dispatch: Function) => {
  const { companyId } = props;

  const params = {
    $limit: -1,
    companyId: companyId,
  };

  try {
    const response = await axios.get<CompanyStockingParameter[]>(COMPANY_STOCKING_PARAMETER_URL, { params });
    dispatch(setCompanyStockingParameters(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchStockingParameter = (props: { _id: string }) => async (dispatch: Function) => {
  const { _id } = props;

  const url = `${STOCKING_PARAMETER_URL}/${_id}`;

  try {
    const response = await axios.get<StockingParameterData>(url);
    dispatch(setStockingParameterSelected(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchLastStockingParameter = async (props: { stockingId: string; companyId: string; }) => {
  const { stockingId, companyId } = props;

  const params = {
    companyId,
    stockingId,
    $limit: 1,
    '$sort[date]': -1,
  };

  const response = await axios.get<StockingParameterDataPayload>(STOCKING_PARAMETER_URL, { params });
  return response.data.data;
};

export const editStockingParameters = (props: { body: EditStockingParameter; onCloseForm: Function }) => async (dispatch: Function) => {
  const { body, onCloseForm } = props;

  const url = `${STOCKING_PARAMETER_URL}/${body._id}`;
  dispatch(setIsLoadingEditStockingParameter(true));

  try {
    await axios.patch(url, body);
    dispatch(setShowEditStockingParameterModal(false));
    openSuccessNotification(i18next.t('stockingParameter.formNew.success'));
    onCloseForm();
    dispatch(setIsLoadingEditStockingParameter(false));
  } catch (e) {
    console.log(e?.response);
    dispatch(setIsLoadingEditStockingParameter(false));

    if (e?.response?.data?.data?.error) {
      const { error, key } = e.response.data.data;
      showStockingParameterError({ error, key });
    }

    return;
  }

  dispatch(stockingParameterSlice.fetchStockingParameters({ page: 0, stockingId: body.stockingId, companyId: body.companyId }));
};

export default detailStockingParameterSlice.reducer;
