import cx from 'classnames';
import 'react-phone-number-input/style.css';
import { ColumnsType } from 'antd/lib/table';
import { LabeledValue } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { FilterConfirmProps, FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useState, useEffect, useRef, MutableRefObject, Key } from 'react';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import { Space, Button, Row, Col, Form, Select, Tooltip, Card, Collapse, Dropdown, Menu, Input, InputRef } from 'antd';

import { RootState } from '../../state/store';
import { Company } from '../AppHeader/interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { filterOptionSelect } from '../../utils/select';
import { changeHeader } from '../AppHeader/headerSlice';
import { getUserSession } from '../../utils/userSession';
import { GenericParam } from '../../common/interfaces/commons';
import { LrvTag } from '../../common/components/LrvTag/LrvTag';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { getAvailableBalance } from '../../helpers/users.helpers';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { LrvSwitch } from '../../common/components/LrvSwitch/LrvSwitch';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { lrvConfirm } from '../../common/components/LrvConfirm/LrvConfirm';
import PasswordPolicy from '../../common/components/LrvPopover/PasswordPolicy';
import { LrvPassword } from '../../common/components/LrvPassword/LrvPassword';
import { LrvCollapse } from '../../common/components/LrvCollapse/LrvCollapse';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { ExtraActionsButton } from '../../common/components/buttons/ExtraActionsButton';
import { formatMonthDayYear, formatYearMonthDay, getMonthName } from '../../utils/date';
import { applyThousandsSeparator, dollarUS } from '../../utils/strings';
import { validatePasswordPolicy, validateNumber, validateString, validatePhoneInputGroup } from '../../utils/validations';
import { setStepIndex, setOnboardingType, setShouldRun, setRun, goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';
import { companyBalanceLimits, isSuperAdmin, onboardingTypes, isAdminUser, DEFAULT_DAYS_TO_INITIAL_STAGE, DEFAULT_STAGE_MAX, DEFAULT_DAY_MAX_JUVENILE, DEFAULT_DAY_MAX_GROW_OUT, LARVIA_ID, isSalesRole, plansTypes, isPromoterRole, isBusinessManagerRole, isSalesManagerRole, clientOptions, THEME, isDistributorCompany, MIN_DAYS_TO_INITIAL_STAGE, MAX_DAYS_TO_INITIAL_STAGE } from '../../config/commons';

import './Clients.scss';
import styles from './Clients.module.scss';
import { ClientSubHeader } from './ClientSubHeader';
import { Client, Maturation, Plan, User } from './interfaces';
import { calculatePlanPrice, CLIENTS, getValueActiveUsers } from './clientsHelper';
import { createClient, fetchClients, setSelectedClient, fetchClient, updateClient, fetchSearchClients, fetchPlans, fetchCompanies, fetchCompaniesByIds, setActiveAssignedCompanies, setInactiveAssignedCompanies, fetchSellers, setSellers, setPromoters, fetchPromotersBySeller, updateRenuevalMonths, setShowEditClientModal, setShowCreateClientModal } from './clientsSlice';

const { Option, OptGroup } = Select;

const PERSONALIZED_PLAN = 'PERSONALIZED_PLAN';
const PREPAID_PLAN = 'PREPAID_PLAN';

export const MIN_DAYS_STAGE = 0;
export const MAX_DAYS_STAGE = 200;
export const MIN_DAYS_JUVENILE = 0;
export const MAX_DAYS_JUVENILE = 200;
export const MIN_DAYS_GROW_OUT = 0;
export const MAX_DAYS_GROW_OUT = 360;
const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const MAX_LENGTH_CODE = 8;

const discountPorcentage = {
  min: 5,
  max: 20,
};

function Clients () {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const { Panel } = Collapse;

  const [newCompany, setNewCompany] = useState('');
  const [newBusinessName, setNewBusinessName] = useState<string>();
  const [newUserFirstName, setNewUserFirstName] = useState('');
  const [newUserLastName, setUserLastName] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newDaysToInitialStage, setNewDaysToInitialStage] = useState<number | string>(DEFAULT_DAYS_TO_INITIAL_STAGE);
  const [newMaxStage, setNewMaxStage] = useState<number | string>(DEFAULT_STAGE_MAX);
  const [newMaxDayJuvenile, setNewMaxDayJuvenile] = useState<number | string>(DEFAULT_DAY_MAX_JUVENILE);
  const [newMaxDayGrowOut, settNewMaxDayGrowOut] = useState<number | string>(DEFAULT_DAY_MAX_GROW_OUT);

  const [newCode, setNewCode] = useState<string>();
  const [newOwner, setNewOwner] = useState('');
  const [assignedCompaniesActive, setAssignedCompaniesActive] = useState<string[]>([]);
  const [assignedNameCompaniesInactive, setAssignedNameCompaniesInactive] = useState<string[]>([]);
  const [newResponsible, setNewResponsible] = useState<string>();
  const [newPromoter, setNewPromoter] = useState<string>();
  const [activeUser, setActiveUser] = useState(true);
  const [newPlan, setNewPlan] = useState<string>('');
  const [newDiscountPercentage, setNewDiscountPercentage] = useState<number | undefined>(0);
  const [newPlanType, setNewPlanType] = useState<string | undefined>();
  const [newPlanPrice, setNewPlanPrice] = useState<number>(0);
  const [newQuota, setNewQuota] = useState<number>(0);
  const [newIsDistributionCompany, setNewIsDistributionCompany] = useState<boolean>(false);
  const [hasTrialPhase, setHasTrialPhase] = useState<boolean>(false);
  const [isInternational, setIsInternational] = useState<boolean>(false);
  const [newCompanyBalance, setNewCompanyBalance] = useState<string>('');
  const [selectedMaturations, setSelectedMaturations] = useState<LabeledValue[]>();
  const [newPlanPriceOverride, setNewPlanPriceOverride] = useState<number | string>('');
  const [disabledButtonFormEditClient, setDisabledButtonFormEditClient] = useState(false);
  const [ruc, setRuc] = useState<string>();
  const [phone, setPhone] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('EC');
  const [phonePrefix, setPhonePrefix] = useState<string>('593');
  const [address, setAddress] = useState<string>();
  const [allowXlsxAnalysisReport, setAllowXlsxAnalysisReport] = useState<boolean>(false);
  const [renuevalMonths, setRenuevalMonths] = useState<number[]>([]);
  const [allowAutomaticConsolidation, setAllowAutomaticConsolidation] = useState<boolean | undefined>(false);
  const [showStockingParameterSection, setShowStockingParameterSection] = useState<boolean>(false);

  const { showEditClientModal, showCreateClientModal } = useSelector((state: RootState) => state.clients);
  const clients = useSelector((state: RootState) => state.clients.clients);
  const { activeBaseMaturations } = useSelector((state: RootState) => state.baseMaturations);
  const plans = useSelector((state: RootState) => state.clients.plans as Plan[]);
  const selectedClient: Client = useSelector((state: RootState) => state.clients.selectedClient as Client);
  const companies: Company[] = useSelector((state: RootState) => state.clients.companies as Company[]);
  const sellers: User[] = useSelector((state: RootState) => state.clients.sellers as User[]);
  const promoters: User[] = useSelector((state: RootState) => state.clients.promoters as User[]);
  const activeAssignedCompanies: Company[] = useSelector((state: RootState) => state.clients.activeAssignedCompanies as Company[]);
  const inactiveAssignedCompanies: Company[] = useSelector((state: RootState) => state.clients.inactiveAssignedCompanies as Company[]);
  const isClientLoading = useSelector((state: RootState) => state.clients.isClientLoading);
  const isClientsLoading = useSelector((state: RootState) => state.clients.isClientsLoading);

  const isFormCreateLoading = useSelector((state: RootState) => state.clients.isFormCreateLoading);
  const isFormUpdateLoading = useSelector((state: RootState) => state.clients.isFormUpdateLoading);
  const hasEmailError = useSelector((state: RootState) => state.clients.hasEmailError);
  const { clientStatus } = useSelector((state: RootState) => state.clients.filters);
  const refSearchName = useRef(null);
  const refSearchEmail = useRef(null);

  const onboardingUserData = useSelector((state: RootState) => state.onboarding.user);
  const isRunningOnboarding = useSelector((state: RootState) => state.onboarding.run);
  const existsUserData = useSelector((state: RootState) => state.onboarding.existsUserData);
  const [copiedCode, setCopiedCode] = useState('');

  const userSession = getUserSession();
  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;
  const hasPermissionToManageCompanies = isSuperAdmin() || isSalesRole() || isSalesManagerRole() || isPromoterRole() || isBusinessManagerRole();

  const clientDefault = {
    _id: '',
    name: '',
    code: '',
    businessName: '',
    active: false,
    isDistributor: false,
    isInternational: false,
    daysToInitialStage: 0,
    allowAutomaticConsolidation: false,
    showStockingParameterSection: false,
    maxStage: 0,
    phonePrefix: '',
    countryCode: '',
    phone: '',
    maxDayJuvenile: 0,
    maxDayGrowOut: 0,
    sellerId: '',
    promoterId: '',
    planType: '',
    createdAt: '',
    owner: {
      _id: '',
      firstName: '',
      lastName: '',
      email: '',
    },
  };

  useEffect(() => {
    return () => {
      dispatch(setSelectedClient(clientDefault));
      dispatch(setSellers([]));
      dispatch(setPromoters([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (existsUserData && !onboardingUserData.onboarding?.clientsSection) {
      setTimeout(() => {
        dispatch(setStepIndex(0));
        dispatch(setOnboardingType(onboardingTypes.CLIENTS_SECTION));
        dispatch(setShouldRun(true));
        dispatch(setRun(true));
      }, 500);
    }
  }, [dispatch, onboardingUserData, existsUserData]);

  useEffect(() => {
    dispatch(changeHeader({ title: 'clients.title' }));
    const active = getValueActiveUsers(CLIENTS.ACTIVE);
    dispatch(fetchClients({ active }));
  }, [dispatch]);

  useEffect(() => {
    if (!selectedClient._id) {
      return;
    }

    const trialPhase = !!selectedClient.trialEndDate;
    setActiveUser(selectedClient.active);
    setNewIsDistributionCompany(selectedClient.isDistributor);
    setHasTrialPhase(trialPhase);
    setIsInternational(selectedClient.isInternational);
    setAllowAutomaticConsolidation(selectedClient.allowAutomaticConsolidation);
    setNewDaysToInitialStage(selectedClient.daysToInitialStage);
    setNewMaxStage(selectedClient.maxStage);
    setNewMaxDayJuvenile(selectedClient.maxDayJuvenile);
    settNewMaxDayGrowOut(selectedClient.maxDayGrowOut);
    setNewCompany(selectedClient.name);
    setNewBusinessName(selectedClient.businessName);
    setNewOwner(selectedClient.owner._id);
    setNewUserEmail(selectedClient.owner.email);
    setNewCode(selectedClient.code);
    setRuc(selectedClient.ruc);
    setPhone(selectedClient.phone);
    setCountryCode(selectedClient.countryCode);
    setPhonePrefix(selectedClient.phonePrefix);
    setAddress(selectedClient.address);
    setNewDiscountPercentage(selectedClient.balance?.discountPercentage);
    setNewResponsible(selectedClient.sellerId);
    setNewPromoter(selectedClient.promoterId);
    setNewPlanType(selectedClient.planType);
    setShowStockingParameterSection(selectedClient.showStockingParameterSection);
    dispatch(fetchSellers());

    const planPriceOverride = selectedClient.balance?.planPriceOverride;
    if (planPriceOverride !== undefined) {
      setNewPlanPriceOverride(planPriceOverride);
    }

    const renuevalMonths = selectedClient.balance?.renuevalMonths;
    setRenuevalMonths(renuevalMonths || []);
    setAllowXlsxAnalysisReport(selectedClient.allowXlsxAnalysisReport || false);

    const userIds = selectedClient.users?.map((user) => user._id);
    let editOwner = selectedClient.owner._id;

    if (userIds && userIds.includes(selectedClient.owner._id.toString())) {
      const user = selectedClient.users?.find((user) => user._id === selectedClient.owner._id.toString());
      editOwner = user?.firstName + ' ' + user?.lastName;
    }

    form.setFieldsValue({
      editCompanyName: selectedClient.name,
      editBusinessName: selectedClient.businessName,
      editOwner: editOwner,
      editOwnerEmail: selectedClient.owner.email,
      editCompanyBalance: selectedClient.balance?.quota,
      editAvailableBalance: getAvailableBalance(selectedClient),
      editPlanPrice: selectedClient.balance?.planPrice,
      editRenuevalMonths: renuevalMonths,
      editPlanPriceOverride: planPriceOverride || '',
      editHasTrialPhase: trialPhase,
      editIsInternational: selectedClient.isInternational,
      editAllowAutomaticConsolidation: selectedClient.allowAutomaticConsolidation,
      editCode: selectedClient.code,
      editDaysToInitialStage: selectedClient.daysToInitialStage,
      editMaxStage: selectedClient.maxStage,
      editMaxDayJuvenile: selectedClient.maxDayJuvenile,
      editMaxDayGrowOut: selectedClient.maxDayGrowOut,
      editRUC: selectedClient.ruc,
      editPhone: selectedClient.phone,
      editCountryCode: selectedClient.countryCode,
      editAddress: selectedClient.address,
      editDiscountPercentage: selectedClient.balance?.discountPercentage,
      editResponsible: selectedClient.sellerId,
      editPromoter: selectedClient.promoterId,
      editPlanType: selectedClient.planType,
      editCreatedAt: formatMonthDayYear(selectedClient.createdAt),
      allowXlsxAnalysisReport: selectedClient.allowXlsxAnalysisReport,
      showStockingParameterSection: selectedClient.showStockingParameterSection,
    });
  }, [dispatch, form, selectedClient, selectedClient.name, selectedClient.businessName, selectedClient.owner._id, selectedClient._id, selectedClient.owner.email, selectedClient.active, selectedClient.assignedCompanies, selectedClient.balance, selectedClient.isDistributor, selectedClient.trialEndDate, selectedClient.code, selectedClient.isInternational, selectedClient.daysToInitialStage, selectedClient.maxStage, selectedClient.maxDayJuvenile, selectedClient.maxDayGrowOut, selectedClient.sellerId, selectedClient.promoterId, selectedClient.planType, selectedClient.createdAt, selectedClient.allowAutomaticConsolidation]);

  useEffect(() => {
    if (newResponsible) {
      dispatch(fetchPromotersBySeller(newResponsible));
    }
  }, [dispatch, newResponsible]);

  useEffect(() => {
    if (!newIsDistributionCompany) {
      setAssignedCompaniesActive([]);
      setAssignedNameCompaniesInactive([]);
      return;
    }

    const ids = activeAssignedCompanies.map((company) => company._id);
    const names = inactiveAssignedCompanies.map((company) => company.name);

    setAssignedCompaniesActive(ids);
    setAssignedNameCompaniesInactive(names);

    form.setFieldsValue({
      editActiveAssignedCompanies: ids,
      editInactiveAssignedCompanies: names,
    });

  }, [form, newIsDistributionCompany, activeAssignedCompanies, inactiveAssignedCompanies]);

  useEffect(() => {
    if (!hasEmailError && !isFormCreateLoading) {
      dispatch(setShowCreateClientModal(false));
      form.resetFields();
    }
  }, [dispatch, form, hasEmailError, isFormCreateLoading]);

  useEffect(() => {
    const selectedMaturations: LabeledValue[] = selectedClient.maturations ? selectedClient.maturations.map((maturation: Maturation) => {
      return { key: maturation._id, label: maturation.name, value: maturation._id };
    }) : [];
    setSelectedMaturations(selectedMaturations);
  }, [selectedClient.maturations]);


  const clientsData = clients ? clients.map((client: Client, index) => {
    return {
      key: index,
      _id: client._id,
      active: client.active,
      name: client.name,
      email: client.owner?.email ? client.owner?.email : <LrvTag color='#f50'>{t('clients.notAssigned')}</LrvTag>,
      code: client.code,
      assignedCompanies: client.assignedCompanies,
      isDistributor: client.isDistributor,
    };
  }) : [];

  const getClientsColumns = () => {
    const columns: ColumnsType<Client> = [
      {
        key: 1,
        title: t('clients.company'),
        dataIndex: 'name',
        width: isSuperAdmin() ? '30%' : '20%',
        ...getColumnSearchProps('name', refSearchName),
      },
      {
        key: 2,
        title: t('clients.email'),
        dataIndex: 'email',
        width: isSuperAdmin() ? '35%' : '35%',
        responsive: ['sm'] as Breakpoint[],
        ...getColumnSearchProps('email', refSearchEmail),
      },
    ];

    if (isSuperAdmin()) {
      columns.push({
        key: 3,
        title: t('clients.code'),
        dataIndex: 'code',
        width: '20%',
        render: (text: string) => {
          if (!text) {
            return null;
          }

          return <Tooltip title={t('clients.clickToCopy')} placement='left'>
            <span
              className={cx(styles.clickToCopy, copiedCode === text ? styles.clickToToCopyActive : '')}
              onClick={(e) => {
                e.stopPropagation();
                setCopiedCode(text);
                if (navigator.clipboard) {
                  navigator.clipboard.writeText(text);
                }

                setTimeout(() => {
                  setCopiedCode('');
                }, 500);
              }}
            >
              {text}
            </span>
          </Tooltip >;
        }
      });
    }

    columns.push({
      key: 4,
      dataIndex: 'active',
      width: '10%',
      render: (_, record: Client) => {
        return (
          <Space align='center' className={styles.statusCell} size={5}>
            {renderStatusTag(record)}
          </Space>
        );
      },
    });

    columns.push({
      key: 5,
      width: isSuperAdmin() ?
        (isDesktop ? '30%' : '10%') :
        (isDesktop ? '20%' : '15%'),
      render: (_, record: Client) => {
        return (
          <div className={styles.optionsCell}>
            {renderDropdownClientOptions(record)}
          </div>
        );
      }
    });

    return columns;
  };

  const renderStatusTag = (record: Client) => {
    if (record.active) {
      // if return null, table will display an "-" in the empty cell
      return <div></div>;
    }

    const id = `tag_${record._id}`;
    return (
      <LrvTag id={id} type='info' className={styles.statusTag}>{t('users.userInactive')}</LrvTag>
    );
  };

  const renderDropdownClientOptions = (client: Client) => {
    return (
      <Dropdown
        overlay={() => menuItemsClientOptions(client)}
        trigger={['click']}
        placement='bottomRight'
      >
        <ExtraActionsButton
          id={`button_${client._id}`}
          onClick={(e) => e.stopPropagation()}
        />
      </Dropdown>
    );
  };

  const menuItemsClientOptions = (client: Client) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          if (key === clientOptions.EDIT) {
            onEditClientOption(client);
            return;
          }
        }}
      >
        <Menu.Item id='menu_option_edit' key={clientOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' theme={theme} className={styles.icon} />
          <span>{t('clients.editClient')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  function onEditClientOption (record: Client) {
    dispatch(setShowEditClientModal(true));
    dispatch(fetchClient(record._id, record.isDistributor));
    dispatch(setActiveAssignedCompanies([]));
    dispatch(setInactiveAssignedCompanies([]));

    if (record.isDistributor) {
      dispatch(fetchCompanies());

      if (record.assignedCompanies && record.assignedCompanies.length > 0) {
        dispatch(fetchCompaniesByIds(record.assignedCompanies));
      }
    }

    if (isRunningOnboarding) {
      dispatch(goToOnboardingNextStep(2500));
    }
  }

  const searchClients = (props: { dataIndex: string; confirm: (param?: FilterConfirmProps | undefined) => void; value: Key[] }) => {
    const { dataIndex, confirm, value } = props;
    confirm();

    const status = getValueActiveUsers(clientStatus);
    switch (dataIndex) {
      case 'name':
        dispatch(fetchSearchClients({ name: value[0], clientStatus: status }));
        break;

      case 'email':
        dispatch(fetchSearchClients({ email: value[0], clientStatus: status }));
        break;
    }
  };

  function getColumnSearchProps (dataIndex: string, ref: MutableRefObject<InputRef | null>) {
    const label = `clients.paramsSearch.${dataIndex}`;
    const placeholder = `${t('clients.search')} ${t(label)}`;

    return {
      filterDropdown: (props: FilterDropdownProps) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
        return (
          <div className={styles.menuSearch}>
            <LrvInput
              theme='light'
              id={`txt_${dataIndex}_search`}
              ref={ref}
              placeholder={placeholder}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => {
                if (selectedKeys[0]) {
                  clearFilters && clearFilters();
                  searchClients({ dataIndex, confirm, value: selectedKeys });
                }
              }}
              className={styles.inputSearch}
            />
            <Space>
              <Button
                id={`btn_${dataIndex}_search`}
                type='primary'
                onClick={() => {
                  clearFilters && clearFilters();
                  searchClients({ dataIndex, confirm, value: selectedKeys });
                }}
                icon={<SearchOutlined />}
                size='small'
                className={styles.buttonSearch}
                disabled={!selectedKeys[0]}
              >
                {t('clients.search')}
              </Button>
              <Button
                id={`btn_${dataIndex}_reset`}
                onClick={() => {
                  clearFilters && clearFilters();
                  const active = getValueActiveUsers(CLIENTS.ACTIVE);
                  dispatch(fetchClients({ active }));
                }}
                className={styles.buttonReset}
              >
                {t('clients.reset')}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => <SearchOutlined className={isLightTheme ? styles.searchOutlinedIconLight : styles.searchOutlinedIconDark} style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value: boolean | Key, record: Client) => {
        const item: GenericParam = record;
        const field: string = item[dataIndex].toString().toLowerCase();
        return field.includes(`${value}`.toLowerCase());
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => ref.current?.select(), 100);
        }
      },
    };
  }

  const baseMaturationsData = activeBaseMaturations ? activeBaseMaturations.map((baseMaturation: { _id: string; name: string }, index: number) => {
    return {
      index: index,
      _id: baseMaturation._id,
      name: baseMaturation.name,
    };
  }) : [];

  const childrenBaseMaturation = [];
  for (let i = 0; i < baseMaturationsData.length; i++) {
    const baseMaturation = baseMaturationsData[i];
    childrenBaseMaturation.push(<Option key={baseMaturation._id} value={baseMaturation}>{baseMaturation.name}</Option>);
  }

  const plansData = plans ? plans.map((plan, index: number) => {
    return {
      index: index,
      _id: plan._id,
      value: plan.value,
      amountAnalysis: plan.amountAnalysis,
    };
  }) : [];

  const childrenPlan = [];
  for (let i = 0; i < plansData.length; i++) {
    const plan = plansData[i];
    childrenPlan.push(<Option key={plan._id} value={plan._id}>{t('payments.descriptionPlan', { amountAnalysis: plan.amountAnalysis, value: dollarUS.format(plan.value) })}</Option>);
  }

  function handleChangePlans (value: string) {
    setNewPlan(value);
    updatePlanPrice({ value, newQuota: parseInt(newCompanyBalance) });
  }

  function updatePlanPrice (props: { value: string; newQuota: number; isDistributionCompany?: boolean }) {
    const { value, newQuota, isDistributionCompany = newIsDistributionCompany } = props;
    let quota = 0;
    let planPrice = 0;

    if (value === PREPAID_PLAN) {
      setNewPlanType(plansTypes.PREPAID);
      setNewQuota(quota);
      setNewPlanPrice(planPrice);
      return;
    }

    setNewPlanType(plansTypes.POSTPAID);

    if (value === PERSONALIZED_PLAN) {
      quota = newQuota;
      planPrice = calculatePlanPrice({ plans, newQuota, isDistributor: isDistributionCompany });

      setNewQuota(quota);
      setNewPlanPrice(planPrice);
      return;
    }

    const plan = plans.find((plan) => plan._id === value);
    if (plan) {
      quota = plan.amountAnalysis;

      if (isDistributionCompany) {
        planPrice = calculatePlanPrice({ plans, newQuota: quota, isDistributor: isDistributionCompany });
      } else {
        planPrice = plan.value;
      }
    }
    setNewQuota(quota);
    setNewPlanPrice(planPrice);
  }

  function renderMaturationsAssigned () {
    if (!selectedMaturations || selectedMaturations.length === 0) {
      return <LrvInput theme='light' placeholder={t('maturations.unassigned')} readOnly />;
    }

    return (
      <LrvSelect
        theme='light'
        labelInValue
        className={styles.maturationsSelect}
        mode='multiple'
        removeIcon={<Icon name='close' />}
        value={selectedMaturations}
        disabled
      >{null}
      </LrvSelect>
    );
  }

  function renderPlanPrice () {
    if (newPlanType !== plansTypes.POSTPAID || isDistributorCompany()) {
      return null;
    }

    return (
      <Form.Item
        label={t('clients.planPriceOverride')}
        name='editPlanPriceOverride'
      >
        <LrvInputNumber
          theme='light'
          value={newPlanPriceOverride}
          onChange={(value) => {
            if (value) {
              setNewPlanPriceOverride(value);
              return;
            }

            const planPrice = selectedClient.balance?.planPrice;

            if (planPrice !== undefined) {
              setNewPlanPriceOverride(planPrice);
            }
          }}
        />
      </Form.Item>
    );
  }

  const renderPlanAndPrice = () => {
    if (newIsDistributionCompany) {
      return null;
    }

    return (
      <Row gutter={16}>
        <Col span={12}>
          {renderPlanType()}
        </Col>

        <Col span={12}>
          {renderPlanPrice()}
        </Col>
      </Row>
    );
  };

  function renderResponsibleSelect () {
    return (
      <Form.Item
        required={selectedClient._id !== LARVIA_ID}
        label={t('clients.responsible')}
        name='editResponsible'
        rules={selectedClient._id === LARVIA_ID ? undefined : [() => ({ validator (rule, value) { return validateString(value); } })]}
      >
        <LrvSelect
          id='select_responsable_client'
          showSearch
          theme='light'
          placeholder={t('clients.select')}
          value={newResponsible}
          onChange={(value) => {
            setNewResponsible(value);
            form.setFieldsValue({
              editPromoter: undefined,
            });
            setNewPromoter(undefined);
          }}
          suffixIcon={<Icon name='arrow-down-s' />}
          filterOption={filterOptionSelect}
        >
          {sellers.map((seller, index) => {
            return (
              <Option
                key={index}
                value={seller._id}
              >
                {`${seller.firstName} ${seller.lastName}`}
              </Option>
            );
          })}
        </LrvSelect>
      </Form.Item>
    );
  }

  function renderPromoterSelect () {
    return <Form.Item
      name='editPromoter'
      label={`${t('clients.promoter')} (${t('common.optional')})`}
    >
      <LrvSelect
        id='select_promoter_client'
        theme='light'
        showSearch
        placeholder={t('clients.select')}
        value={newPromoter}
        onChange={(value) => {
          setNewPromoter(value);
        }}
        disabled={!newResponsible || promoters.length === 0}
        suffixIcon={<Icon name='arrow-down-s' />}
        filterOption={filterOptionSelect}
      >
        {promoters.map((promoter, index) => {
          return (
            <Option
              key={index}
              value={promoter._id}
            >
              {`${promoter.firstName} ${promoter.lastName}`}
            </Option>
          );
        })}
      </LrvSelect>
    </Form.Item>;
  }

  function renderPlanType () {
    return (
      <Form.Item
        required
        label={t('clients.planType')}
        name='editPlanType'
        rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
      >
        <LrvSelect
          theme='light'
          id='select_plan_type'
          placeholder={t('clients.select')}
          value={newPlanType}
          onChange={(value) => {
            setNewPlanType(value);
          }}
          suffixIcon={<Icon name='arrow-down-s' />}
        >
          <Option
            key={plansTypes.POSTPAID}
            value={plansTypes.POSTPAID}
          >
            {t('clients.postpaid').toUpperCase()}
          </Option>

          <Option
            key={plansTypes.PREPAID}
            value={plansTypes.PREPAID}
          >
            {t('clients.prepaid').toUpperCase()}
          </Option>
        </LrvSelect>
      </Form.Item>
    );
  }

  function renderMaturations () {
    return (
      <Form.Item
        className={styles.itemForm}
        name='editMaturations'
        label={
          <div className={styles.containerLabel}>
            <div>{t('users.maturations')}</div>
          </div>
        }
      >
        <div id='select_client_maturations'>
          {renderMaturationsAssigned()}
        </div>
      </Form.Item>
    );
  }

  function removeTrialPhaseConfirm (value: boolean) {
    const title = value ? t('clients.confirmActiveTrialPhase') : t('clients.confirmEndTrialPhase');

    lrvConfirm({
      theme: 'light',
      title: title,
      icon: <ExclamationCircleOutlined />,
      okButtonProps: { id: 'btnOkTrialPhase' },
      okText: t('clients.accept'),
      okType: 'primary',
      cancelText: t('clients.cancel'),
      onOk () {
        setHasTrialPhase(value);
      },
    });
  }

  const renderAssignedCompanies = () => {
    if (!newIsDistributionCompany) {
      return null;
    }

    return (
      <Form.Item>
        <Card
          className={styles.assignedCompanies}
          title={t('clients.assignedCompanies.title')}
          size='small'
        >
          {renderAssignedCompaniesActive()}
          {renderAssignedCompaniesInactive()}
        </Card>
      </Form.Item>
    );
  };

  const renderDiscountPercentage = () => {
    if (!newIsDistributionCompany) {
      return null;
    }

    return (
      <Form.Item
        required={true}
        label={t('clients.discountPercentage')}
        name='editDiscountPercentage'
        rules={[() => ({ validator (rule, value) { return validateNumber(value, true, discountPorcentage.min, discountPorcentage.max, t('users.balance.discountError', { min: discountPorcentage.min, max: discountPorcentage.max })); } })]}
      >
        <LrvInputNumber
          theme='light'
          id='txt_edit_discount_percentage'
          value={newDiscountPercentage}
          onChange={(value) => {
            if (value) {
              const discount = parseInt(value.toString());
              setNewDiscountPercentage(discount);
            }
          }}
        />
      </Form.Item>
    );
  };

  const renderRenuevalMonths = () => {
    if (!isAdminUser() || selectedClient.planType !== plansTypes.POSTPAID) {
      return null;
    }

    return (
      <Form.Item
        name='editRenuevalMonths'
        label={t('clients.renuevalMonths')}
      >
        <LrvSelect
          theme='light'
          showSearch
          mode='multiple'
          suffixIcon={<Icon name='arrow-down-s' />}
          removeIcon={<Icon name='close' />}
          placeholder={t('clients.select')}
          optionFilterProp='children'
          filterOption={filterOptionSelect}
          onChange={(e: number[]) => {
            setRenuevalMonths(e);
          }}
          value={renuevalMonths}
        >
          {
            months.map((month) => {
              return (
                <Option
                  key={month}
                  value={month}
                >
                  {getMonthName(month.toString())}
                </Option>
              );
            })
          }
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderCode = () => {
    return (
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            required={!hasTrialPhase}
            name='editCode'
            label={hasTrialPhase ? `${t('clients.code')} (${t('common.optional')})` : t('clients.code')}
            rules={[{ required: !hasTrialPhase, message: t('clients.codeRequired') }]}
          >
            <LrvInput
              theme='light'
              maxLength={MAX_LENGTH_CODE}
              onChange={(e) => setNewCode(e.target.value)}
              placeholder={t('clients.code')}
              value={newCode}
            />
          </Form.Item>
        </Col>
        <Col>
          {renderAllowAutomaticConsolidation()}
        </Col>
      </Row>
    );
  };

  const renderAllowAutomaticConsolidation = () => {
    return (
      <Form.Item
        name='editAllowAutomaticConsolidation'
        label={t('clients.automaticConsolidation')}
      >
        <LrvSwitch
          theme='light'
          id='edit_allow_automatic_consolidation_switch'
          checked={allowAutomaticConsolidation}
          onChange={(value) => setAllowAutomaticConsolidation(value)}
        />
      </Form.Item>
    );
  };

  function renderSwitchDistributorAndTrialPhase () {
    return (
      <Row gutter={16}>
        {
          !isDistributorCompany() &&
          <Col span={12}>
            <Form.Item
              name='editDistributionCompany'
              label={t('clients.distributionCompany')}
            >
              <LrvSwitch
                theme='light'
                id='edit_distribution_company_switch'
                checked={newIsDistributionCompany}
                onChange={(value) => setNewIsDistributionCompany(value)}
              />
            </Form.Item>
          </Col>
        }

        <Col span={12}>
          {renderSwitchIsInternational()}
        </Col>
      </Row>
    );
  }

  function renderSwitchAllowXlsxAndTrialPhase () {
    return (
      <Row gutter={16}>
        {
          hasPermissionToManageCompanies &&
          <>
            <Col span={12}>
              <Form.Item
                name='allowXlsxAnalysisReport'
                label={t('clients.allowXlsxAnalysisReport')}
              >
                <LrvSwitch
                  theme='light'
                  id='allow_xlsx_switch'
                  checked={allowXlsxAnalysisReport}
                  onChange={(value) => setAllowXlsxAnalysisReport(value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='showStockingParameterSection'
                label={t('clients.showStockingParameterSection')}
              >
                <LrvSwitch
                  theme='light'
                  id='allow_stocking_parameter_switch'
                  checked={showStockingParameterSection}
                  onChange={(value) => setShowStockingParameterSection(value)}
                />
              </Form.Item>
            </Col>
          </>
        }

        <Col span={12}>
          {
            selectedClient.trialEndDate &&
            <Form.Item
              className={styles.itemTrialPhase}
              name='editHasTrialPhase'
              label={
                <div className={styles.trialPhase}>
                  <div>{t('clients.trialPhase')} &nbsp;</div>
                  {
                    isAdminUser() &&
                    <Tooltip
                      title={t('clients.trialPhaseDateExpired', { date: formatYearMonthDay(selectedClient.trialEndDate) })}
                    >
                      <Button type='text' icon={<Icon name='information' className={styles.icon} />} />
                    </Tooltip>
                  }
                </div>}
            >
              <LrvSwitch
                theme='light'
                id='edit_trial_phase_switch'
                checked={hasTrialPhase}
                onChange={removeTrialPhaseConfirm}
              />
            </Form.Item>
          }
        </Col>
      </Row>
    );
  }

  function renderSwitchIsInternational () {
    return (
      <Form.Item
        name='editIsInternational'
        label={t('clients.international')}
      >
        <LrvSwitch
          theme='light'
          id='edit_is_international_switch'
          checked={isInternational}
          onChange={(value) => setIsInternational(value)}
        />
      </Form.Item>
    );
  }

  function renderAssignedCompaniesActive () {
    return (
      <Form.Item
        className={styles.itemForm}
        name='editActiveAssignedCompanies'
        label={t('clients.assignedCompanies.active')}
      >
        <div id='assigned_companies_dropdown'>
          <LrvSelect
            theme='light'
            showSearch
            mode='multiple'
            suffixIcon={<Icon name='arrow-down-s' />}
            placeholder={t('clients.select')}
            optionFilterProp='children'
            filterOption={filterOptionSelect}
            onChange={(e: string[]) => {
              setAssignedCompaniesActive(e);
            }}
            value={assignedCompaniesActive}
          >
            {companies?.map((company, index) => {
              if (company._id !== selectedClient._id) {
                return (
                  <Option
                    key={index}
                    value={company._id}
                  >
                    {`${company.name}`}
                  </Option>
                );
              }
            })}
          </LrvSelect>
        </div>
      </Form.Item>
    );
  }

  function renderAssignedCompaniesInactive () {
    if (assignedNameCompaniesInactive.length === 0) {
      return null;
    }

    return (
      <Form.Item
        className={styles.itemForm}
        name='editInactiveAssignedCompanies'
        label={t('clients.assignedCompanies.inactive')}
      >
        <div id='assigned_companies_inactive_dropdown'>
          <LrvSelect
            theme='light'
            mode='multiple'
            suffixIcon={<Icon name='arrow-down-s' />}
            placeholder={t('clients.select')}
            optionFilterProp='children'
            filterOption={filterOptionSelect}
            onChange={(e: string[]) => {
              setAssignedNameCompaniesInactive(e);
            }}
            value={assignedNameCompaniesInactive}
          >
            {null}
          </LrvSelect>
        </div>
      </Form.Item>
    );
  }

  const renderUserInput = () => {
    const userIds = selectedClient.users?.map((user) => user._id);

    if (userIds && userIds.includes(selectedClient.owner._id.toString())) {
      const user = selectedClient.users?.find((user) => user._id === selectedClient.owner._id.toString());
      const userName = user?.firstName + ' ' + user?.lastName;

      return (
        <LrvInput theme='light' className={styles.notAllowed} placeholder={t('clients.select')} readOnly value={userName} />
      );
    }

    return (
      <LrvSelect
        theme='light'
        showSearch
        suffixIcon={<Icon name='arrow-down-s' />}
        placeholder={t('clients.select')}
        optionFilterProp='children'
        filterOption={filterOptionSelect}
        onChange={(e: string) => {
          setNewOwner(e);
          setNewUserEmail((selectedClient.users?.find(user => user._id === e))?.email || '');
        }}
        value={newOwner}
      >
        {selectedClient.users?.map((user, index) => {
          return <Option
            key={index}
            value={user._id}
            disabled={selectedClient.owner._id === user._id}
          >
            {`${user.firstName} ${user.lastName}`}
          </Option>;
        })}
      </LrvSelect>
    );
  };

  const renderDaysToInitialStage = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='daysToInitialStage'
          label={t('clients.daysToInitialStage')}
          required
          rules={[() => ({ validator (rule, value) { return validateNumber(value, true, MIN_DAYS_TO_INITIAL_STAGE); } })]}
        >
          <LrvInputNumber
            theme='light'
            min={MIN_DAYS_TO_INITIAL_STAGE}
            max={MAX_DAYS_TO_INITIAL_STAGE}
            value={newDaysToInitialStage}
            onChange={(value) => {
              if (value) {
                setNewDaysToInitialStage(value);
              }
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderIsInternacional = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='isInternational'
          label={t('clients.international')}
        >
          <LrvSwitch
            theme='light'
            id='international_switch'
            checked={isInternational}
            onChange={(value) => setIsInternational(value)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderResponsibleSelectCreateForm = () => {
    return (
      <Form.Item
        required={isSuperAdmin()}
        label={t('clients.responsible')}
        name='responsible'
        rules={isSuperAdmin() ? [() => ({ validator (rule, value) { return validateString(value); } })] : undefined}
      >
        <LrvSelect
          theme='light'
          id='select_new_responsable_client'
          showSearch
          placeholder={t('clients.select')}
          value={newResponsible}
          onChange={(value) => {
            setNewResponsible(value);
            form.setFieldsValue({
              promoter: undefined,
            });
            setNewPromoter(undefined);
          }}
          suffixIcon={<Icon name='arrow-down-s' />}
          filterOption={filterOptionSelect}
        >
          {sellers.map((seller, index) => {
            return (
              <Option
                key={index}
                value={seller._id}
              >
                {`${seller.firstName} ${seller.lastName}`}
              </Option>
            );
          })}
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderPromoterSelectCreateForm = () => {
    return (
      <Form.Item
        name='promoter'
        label={`${t('clients.promoter')} (${t('common.optional')})`}
      >
        <LrvSelect
          theme='light'
          id='select_new_promoter_client'
          showSearch
          placeholder={t('clients.select')}
          value={newPromoter}
          onChange={(value) => {
            setNewPromoter(value);
          }}
          disabled={(!newResponsible && !isSalesRole() && !isSalesManagerRole()) || promoters.length === 0}
          suffixIcon={<Icon name='arrow-down-s' />}
          filterOption={filterOptionSelect}
        >
          {promoters.map((promoter, index) => {
            return (
              <Option
                key={index}
                value={promoter._id}
              >
                {`${promoter.firstName} ${promoter.lastName}`}
              </Option>
            );
          })}
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderResponsibleAndPromoterSelect = () => {
    const showPromoterSelect = isSuperAdmin() || isSalesRole() || isSalesManagerRole();
    const showResponsibleSelect = isSuperAdmin();
    if (showPromoterSelect && showResponsibleSelect) {
      return (
        <Row gutter={16}>
          <Col span={12}>
            {renderResponsibleSelectCreateForm()}
          </Col>
          <Col span={12}>
            {renderPromoterSelectCreateForm()}
          </Col>
        </Row>
      );
    }

    if (showPromoterSelect) {
      return renderPromoterSelect();
    }

    return null;
  };

  const renderDistributionCompany = () => {
    return (
      <Form.Item
        id='test'
        name='distributionCompany'
        label={t('clients.distributionCompany')}
      >
        <LrvSwitch
          theme='light'
          id='distribution_company_switch'
          checked={newIsDistributionCompany}
          onChange={(value) => {
            setNewIsDistributionCompany(value);
            updatePlanPrice({ value: newPlan, newQuota, isDistributionCompany: value });
          }}
        />
      </Form.Item>
    );
  };

  const resetSelectedClient = () => {
    dispatch(setSelectedClient(clientDefault));
  };

  const onButtonAddClient = () => {
    dispatch(fetchPlans());
    dispatch(setSellers([]));
    dispatch(setPromoters([]));
    dispatch(fetchSellers());

    if (isSalesRole() || isSalesManagerRole()) {
      dispatch(fetchPromotersBySeller(userSession._id));
    }

    resetSelectedClient();

    setHasTrialPhase(false);
    setIsInternational(false);
    setAllowAutomaticConsolidation(false);
    setNewPlan('');
    setNewPlanType(undefined);
    setNewIsDistributionCompany(false);
    dispatch(setShowCreateClientModal(true));

    setNewQuota(0);
    setNewPlanPrice(0);
    setNewDaysToInitialStage(DEFAULT_DAYS_TO_INITIAL_STAGE);
    setNewMaxStage(DEFAULT_STAGE_MAX);
    setNewMaxDayJuvenile(DEFAULT_DAY_MAX_JUVENILE);
    settNewMaxDayGrowOut(DEFAULT_DAY_MAX_GROW_OUT);

    setNewBusinessName(undefined);
    setRuc(undefined);
    setCountryCode('EC');
    setPhonePrefix('593');
    setPhone('');
    setAddress(undefined);
    setNewResponsible(undefined);
    setNewPromoter(undefined);
    setNewCode(undefined);
    setRenuevalMonths(months);
    setAllowXlsxAnalysisReport(false);

    form.setFieldsValue({
      daysToInitialStage: DEFAULT_DAYS_TO_INITIAL_STAGE,
      maxStage: DEFAULT_STAGE_MAX,
      maxDayJuvenile: DEFAULT_DAY_MAX_JUVENILE,
      maxDayGrowOut: DEFAULT_DAY_MAX_GROW_OUT,
      renuevalMonths: months,
    });
  };

  const addNewClient = () => {
    const activeUsers = getValueActiveUsers(clientStatus);
    const dataClient = {
      companyName: newCompany, businessName: newBusinessName,
      quota: newQuota, planPrice: newPlanPrice,
      firstName: newUserFirstName, lastName: newUserLastName,
      email: newUserEmail, password: newUserPassword,
      isDistributor: newIsDistributionCompany,
      hasTrialPhase: hasTrialPhase,
      isInternational: isInternational,
      daysToInitialStage: newDaysToInitialStage,
      maxStage: newMaxStage,
      maxDayJuvenile: newMaxDayJuvenile,
      maxDayGrowOut: newMaxDayGrowOut,
      ruc: ruc,
      code: newCode,
      phone: phone,
      countryCode: countryCode,
      phonePrefix: phonePrefix,
      address: address,
      sellerId: newResponsible,
      promoterId: newPromoter,
      planType: newIsDistributionCompany ? plansTypes.POSTPAID : newPlanType,
      renuevalMonths,
      discountPercentage: newDiscountPercentage,
      allowXlsxAnalysisReport,
    };

    dispatch(createClient(dataClient, activeUsers));
  };

  const resetEditForm = () => {
    dispatch(setShowEditClientModal(false));
    setDisabledButtonFormEditClient(true);
    form.resetFields();
  };

  const editClient = () => {
    if (newCompany !== '' && newOwner !== '') {
      const activeUsers = getValueActiveUsers(clientStatus);
      const currentPlanPriceOverride = selectedClient.balance?.planPriceOverride ? selectedClient.balance?.planPriceOverride.toString() : '0';
      const currentTrialPhase = !!selectedClient.trialEndDate;
      const activeCompanyIds = activeAssignedCompanies.map((company) => company._id);

      const inactiveCompanyIds: string[] = [];
      inactiveAssignedCompanies.forEach((company) => {
        if (assignedNameCompaniesInactive.includes(company.name)) {
          inactiveCompanyIds.push(company._id);
        }
      });
      const newAssignedCompanies = assignedCompaniesActive.concat(inactiveCompanyIds);

      const dataClient = {
        clientId: selectedClient._id,
        newName: newCompany,
        currentName: selectedClient.name,
        newBusinessName: newBusinessName,
        currentCode: selectedClient.code,
        newCode: newCode,
        currentBusinessName: selectedClient.businessName,
        newOwnerId: newOwner,
        currentOwnerId: selectedClient.owner._id,
        newActive: activeUser,
        currentActive: selectedClient.active,
        newAssignedCompaniesActive: assignedCompaniesActive,
        currentAssignedCompaniesActive: activeCompanyIds,
        newAssignedCompanies: newAssignedCompanies,
        currentAssignedCompanies: selectedClient.assignedCompanies,
        newPlanPriceOverride: newPlanPriceOverride,
        currentPlanPriceOverride: currentPlanPriceOverride,
        newIsDistributor: newIsDistributionCompany,
        currentIsDistributor: selectedClient.isDistributor,
        newTrialPhase: hasTrialPhase,
        currentTrialPhase: currentTrialPhase,
        newIsInternational: isInternational,
        currentIsInternational: selectedClient.isInternational,
        newAllowAutomaticConsolidation: allowAutomaticConsolidation,
        currentAllowAutomaticConsolidation: selectedClient.allowAutomaticConsolidation,
        newDaysToInitialStage: newDaysToInitialStage,
        currentDaysToInitialStage: selectedClient.daysToInitialStage,
        newMaxStage: newMaxStage,
        currentMaxStage: selectedClient.maxStage,
        newMaxDayJuvenile: newMaxDayJuvenile,
        currentMaxDayJuvenile: selectedClient.maxDayJuvenile,
        newMaxDayGrowOut: newMaxDayGrowOut,
        currentMaxDayGrowOut: selectedClient.maxDayGrowOut,
        newRuc: ruc,
        currentRuc: selectedClient.ruc,
        newPhone: phone,
        currentPhone: selectedClient.phone,
        newPhonePrefix: phonePrefix,
        currentPhonePrefix: selectedClient.phonePrefix,
        newCountryCode: countryCode,
        currentCountryCode: selectedClient.countryCode,
        newAddress: address,
        currentAddress: selectedClient.address,
        currentPromoter: selectedClient.promoterId,
        newPromoter: newPromoter,
        currentSeller: selectedClient.sellerId,
        newSeller: newResponsible,
        newPlanType: newPlanType,
        currentPlanType: selectedClient.planType,
        newAllowXlsxAnalysisReport: allowXlsxAnalysisReport,
        currentAllowXlsxAnalysisReport: selectedClient.allowXlsxAnalysisReport,
        newShowStockingParameterSection: showStockingParameterSection,
        currentShowStockingParameterSection: selectedClient.showStockingParameterSection,
      };

      const shouldUpdateCompanyBalance = renuevalMonths !== selectedClient.balance?.renuevalMonths || newDiscountPercentage !== selectedClient.balance?.discountPercentage;
      if (isAdminUser() && selectedClient.balance?._id && shouldUpdateCompanyBalance) {
        updateRenuevalMonths({ companyBalanceId: selectedClient.balance._id, renuevalMonths, discountPercentage: newDiscountPercentage });
      }

      dispatch(updateClient(dataClient, activeUsers, resetEditForm));
    }
  };

  return <div className={styles.clients}>
    <ClientSubHeader onClickAddClient={onButtonAddClient} theme={theme} />

    <Row className={styles.rowFlex}>
      <LrvTable
        id='table_clients'
        className={styles.tableClients}
        columns={getClientsColumns()}
        rowClassName={styles.clientsRow}
        dataSource={clientsData}
        loading={isClientsLoading}
        scroll={{ y: '' }}
        theme={theme}
        size='small'
        pagination={{
          showSizeChanger: false,
          size: 'default',
        }}
      />

      <LrvModal
        theme='light'
        className={styles.editClientModal}
        title={t('clients.editClient')}
        isLoading={isClientLoading || isFormUpdateLoading}
        open={showEditClientModal}
        closeIcon={<Icon id='close_edit_client_modal' name='close' />}
        destroyOnClose
        cancelButtonProps={{ id: 'cancel_new_user' }}
        onCancel={() => {
          dispatch(setShowEditClientModal(false));
          form.resetFields();

          if (isRunningOnboarding) {
            dispatch(goToOnboardingNextStep(800));
          }
        }}
        cancelText={t('users.cancel')}
        style={{ top: 20 }}
        okText={t('users.save')}
        okButtonProps={{ htmlType: 'submit', className: styles.button, form: 'formEditClient', type: 'primary', disabled: disabledButtonFormEditClient, loading: isFormUpdateLoading, id: 'btn_save_edit' }}
      >
        <div className={styles.contentHeader}>
          <LrvText className={styles.title} theme='light' text={selectedClient.name === '' ? t('clients.client') : selectedClient.name} />
          <div className={styles.switch}>
            <LrvText theme='light' text={t('users.userActive')} />
            <LrvSwitch
              theme='light'
              id='active_user_switch'
              checked={activeUser}
              onChange={(value) => setActiveUser(value)}
            />
          </div>
        </div>

        <LrvForm
          theme='light'
          form={form}
          id='formEditClient'
          name='formEditClient'
          layout='vertical'
          onFieldsChange={() => {
            setDisabledButtonFormEditClient(
              form.getFieldsError().filter(({ errors }) => errors.length).length > 0
            );
          }}
          onFinish={editClient}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t('clients.company')}
                name='editCompanyName'
                rules={[{ required: true, message: t('clients.companyNameRequired') }]}
              >
                <LrvInput
                  theme='light'
                  id='txt_edit_companyName'
                  placeholder={t('clients.companyName')}
                  value={newCompany}
                  onChange={(e) => setNewCompany(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                required={!hasTrialPhase}
                name='editBusinessName'
                label={hasTrialPhase ? `${t('clients.businessName')} (${t('common.optional')})` : t('clients.businessName')}
                rules={[{ required: !hasTrialPhase, message: t('clients.businessNameRequired') }]}
              >
                <LrvInput
                  theme='light'
                  id='txt_edit_businessName'
                  placeholder={t('clients.businessName')}
                  value={newBusinessName}
                  onChange={(e) => setNewBusinessName(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='editDaysToInitialStage'
                label={t('clients.daysToInitialStage')}
                required
                rules={[() => ({ validator (rule, value) { return validateNumber(value, true, MIN_DAYS_TO_INITIAL_STAGE); } })]}
              >
                <LrvInputNumber
                  theme='light'
                  min={MIN_DAYS_TO_INITIAL_STAGE}
                  max={MAX_DAYS_TO_INITIAL_STAGE}
                  value={newDaysToInitialStage}
                  onChange={(value) => {
                    if (value) {
                      setNewDaysToInitialStage(value);
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='editMaxStage'
                label={t('clients.maxStage')}
                required
                rules={[() => ({ validator (rule, value) { return validateNumber(value, true, Number(newDaysToInitialStage)); } })]}
              >
                <LrvInputNumber
                  theme='light'
                  min={newDaysToInitialStage}
                  max={MAX_DAYS_STAGE}
                  value={newMaxStage}
                  onChange={(value) => {
                    if (value) {
                      setNewMaxStage(value);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='editMaxDayJuvenile'
                label={t('clients.maxDayJuvenile')}
                required
                rules={[() => ({ validator (rule, value) { return validateNumber(value, true); } })]}
              >
                <LrvInputNumber
                  theme='light'
                  min={MIN_DAYS_JUVENILE}
                  max={MAX_DAYS_JUVENILE}
                  value={newMaxDayJuvenile}
                  onChange={(value) => {
                    if (value) {
                      setNewMaxDayJuvenile(value);
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='editMaxDayGrowOut'
                label={t('clients.maxDayGrowOut')}
                required
                rules={[() => ({ validator (rule, value) { return validateNumber(value, true); } })]}
              >
                <LrvInputNumber
                  theme='light'
                  min={MIN_DAYS_GROW_OUT}
                  max={MAX_DAYS_GROW_OUT}
                  value={newMaxDayGrowOut}
                  onChange={(value) => {
                    if (value) {
                      settNewMaxDayGrowOut(value);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                required={!hasTrialPhase}
                name='editRUC'
                label={hasTrialPhase ? `${t('clients.ruc')} (${t('common.optional')})` : t('clients.ruc')}
                rules={[{ required: !hasTrialPhase, message: t('clients.rucRequired') }]}
              >
                <LrvInput
                  theme='light'
                  id='txt_edit_ruc'
                  placeholder={t('clients.ruc')}
                  value={ruc}
                  onChange={(e) => setRuc(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t('clients.phone')}
                name='editPhone'
                required
                rules={[
                  { validator: (_, value) => validatePhoneInputGroup(value, form.getFieldValue('editPhone'), form.getFieldValue('editCountryCode')) },
                ]}
                validateTrigger={['onBlur', 'onChange']}
              >
                <Input.Group className={styles.inputGroup} compact>
                  <Form.Item
                    name='editCountryCode'
                    noStyle
                  >
                    <LrvSelect
                      theme='light'
                      showSearch
                      className={styles.countryCodeSelect}
                      containerClassName={styles.countryCodeContainer}
                      style={{ width: '100%' }}
                      suffixIcon={<Icon name='arrow-down-s' />}
                      removeIcon={<Icon name='close' />}
                      placeholder={t('clients.select')}
                      optionFilterProp='children'
                      filterOption={filterOptionSelect}
                      onChange={value => {
                        setPhonePrefix(getCountryCallingCode(value));
                        setCountryCode(value);
                      }}
                      value={countryCode}
                    >
                      {
                        getCountries().map((country) => {
                          return (
                            <Option
                              key={country}
                              value={country}
                            >
                              {`${country} +${getCountryCallingCode(country)}`}
                            </Option>
                          );
                        })
                      }
                    </LrvSelect>
                  </Form.Item>
                  <Form.Item
                    name='editPhone'
                    noStyle
                  >
                    <LrvInput
                      theme='light'
                      id='txt_edit_phone'
                      placeholder={t('clients.phone')}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    />
                  </Form.Item>
                </Input.Group>

              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                required={!hasTrialPhase}
                name='editAddress'
                label={hasTrialPhase ? `${t('clients.address')} (${t('common.optional')})` : t('clients.address')}
                rules={[{ required: !hasTrialPhase, message: t('clients.addressRequired') }]}
              >
                <LrvInput
                  theme='light'
                  id='txt_edit_address'
                  placeholder={t('clients.address')}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t('clients.owner')}
                name='editOwner'
                rules={[{ required: true, message: t('clients.ownerRequired') }]}
              >
                {renderUserInput()}
              </Form.Item>
            </Col>
          </Row>

          {
            isAdminUser() && selectedClient._id !== LARVIA_ID &&
            <Row gutter={16}>
              <Col span={12}>
                {renderResponsibleSelect()}
              </Col>

              <Col span={12}>
                {renderPromoterSelect()}
              </Col>
            </Row>
          }

          {renderPlanAndPrice()}
          {renderCode()}

          {renderSwitchDistributorAndTrialPhase()}
          {renderSwitchAllowXlsxAndTrialPhase()}
          {renderDiscountPercentage()}
          {renderMaturations()}
          {renderAssignedCompanies()}
          {renderRenuevalMonths()}

          <Form.Item>
            <LrvCollapse theme='light'>
              <Panel key='1' header={t('clients.additionalData')}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={t('users.email')}
                      name='editOwnerEmail'
                    >
                      <LrvInput theme='light' className={styles.notAllowed} placeholder={t('clients.ownerEmail')} readOnly value={newUserEmail} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label={t('clients.planPrice')}
                      name='editPlanPrice'
                    >
                      <LrvInput theme='light' className={styles.notAllowed} readOnly value={applyThousandsSeparator(selectedClient.balance?.planPrice)} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={t('clients.companyBalance')}
                      name='editCompanyBalance'
                    >
                      <Tooltip title={t('clients.balanceManage')}>
                        <LrvInput theme='light' className={styles.notAllowed} placeholder={t('clients.companyBalance')} readOnly value={applyThousandsSeparator(selectedClient.balance?.quota)} />
                      </Tooltip>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label={t('clients.availableBalance')}
                      name='editAvailableBalance'
                    >
                      <Tooltip title={t('clients.balanceManage')}>
                        <LrvInput theme='light' className={styles.notAllowed} placeholder={t('clients.availableBalance')} readOnly value={getAvailableBalance(selectedClient)} />
                      </Tooltip>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={t('clients.createdAt')}
                      name='editCreatedAt'
                    >
                      <LrvInput theme='light' className={styles.notAllowed} placeholder={t('clients.createdAt')} readOnly value={formatMonthDayYear(selectedClient.createdAt)} />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </LrvCollapse>
          </Form.Item>
        </LrvForm>
      </LrvModal>

      <LrvModal
        theme='light'
        className={styles.newClientModal}
        title={t('clients.newClient')}
        open={showCreateClientModal}
        destroyOnClose={true}
        okButtonProps={{ form: 'formNewClient', loading: isFormCreateLoading, id: 'btn_save_new_client' }}
        onOk={() => {
          form.submit();
        }}
        okText={t('clients.create').toUpperCase()}
        cancelText={t('clients.cancel').toUpperCase()}
        style={{ top: 20 }}
        onCancel={() => {
          dispatch(setShowCreateClientModal(false));
          form.resetFields();

          setNewPlan('');
          setNewPlanType(undefined);
          setNewBusinessName(undefined);
          setRuc(undefined);
          setPhone('');
          setCountryCode('EC');
          setPhonePrefix('593');
          setAddress(undefined);
          setNewResponsible(undefined);
          setNewPromoter(undefined);
          setNewCode(undefined);
          resetSelectedClient();

          if (isRunningOnboarding) {
            dispatch(goToOnboardingNextStep(1200));
          }
        }}
      >
        <LrvForm
          theme='light'
          form={form}
          initialValues={{
            countryCode,
            phonePrefix,
          }}
          id='formNewClient'
          name='formNewClient'
          layout='vertical'
          onFinish={addNewClient}
        >

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='companyName'
                required
                label={t('clients.companyName')}
                rules={[{ required: true, message: t('clients.companyNameRequired') }]}
              >
                <LrvInput
                  theme='light'
                  autoFocus
                  value={newCompany}
                  onChange={(e) => setNewCompany(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                required={!hasTrialPhase}
                name='businessName'
                label={hasTrialPhase ? `${t('clients.businessName')} (${t('common.optional')})` : t('clients.businessName')}
                rules={[{ required: !hasTrialPhase, message: t('clients.businessNameRequired') }]}
              >
                <LrvInput
                  theme='light'
                  value={newBusinessName}
                  onChange={(e) => setNewBusinessName(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                required={!hasTrialPhase}
                name='ruc'
                label={hasTrialPhase ? `${t('clients.ruc')} (${t('common.optional')})` : t('clients.ruc')}
                rules={[{ required: !hasTrialPhase, message: t('clients.rucRequired') }]}
              >
                <LrvInput
                  theme='light'
                  id='txt_ruc'
                  value={ruc}
                  onChange={(e) => setRuc(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                required={!hasTrialPhase}
                name='address'
                label={hasTrialPhase ? `${t('clients.address')} (${t('common.optional')})` : t('clients.address')}
                rules={[{ required: !hasTrialPhase, message: t('clients.addressRequired') }]}
              >
                <LrvInput
                  theme='light'
                  id='txt_address'
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                required={!hasTrialPhase}
                name='code'
                label={hasTrialPhase ? `${t('clients.code')} (${t('common.optional')})` : t('clients.code')}
                rules={[{ required: !hasTrialPhase, message: t('clients.codeRequired') }]}
              >
                <LrvInput
                  theme='light'
                  maxLength={MAX_LENGTH_CODE}
                  id='txt_code'
                  value={newCode}
                  onChange={(e) => setNewCode(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t('clients.phone')}
                name='phone'
                required
                rules={[
                  { validator: (_, value) => validatePhoneInputGroup(value, form.getFieldValue('phone'), form.getFieldValue('countryCode')) },
                ]}
                validateTrigger={['onBlur', 'onChange']}
              >
                <Input.Group className={styles.inputGroup} compact>
                  <Form.Item
                    name='countryCode'
                    noStyle
                  >
                    <LrvSelect
                      theme='light'
                      showSearch
                      className={styles.countryCodeSelect}
                      containerClassName={styles.countryCodeContainer}
                      style={{ width: '100%' }}
                      suffixIcon={<Icon name='arrow-down-s' />}
                      removeIcon={<Icon name='close' />}
                      placeholder={t('clients.select')}
                      optionFilterProp='children'
                      filterOption={filterOptionSelect}
                      onChange={value => {
                        setPhonePrefix(getCountryCallingCode(value));
                        setCountryCode(value);
                      }}
                      value={countryCode}
                    >
                      {
                        getCountries().map((country) => {
                          return (
                            <Option
                              key={country}
                              value={country}
                            >
                              {`${country} +${getCountryCallingCode(country)}`}
                            </Option>
                          );
                        })
                      }
                    </LrvSelect>
                  </Form.Item>

                  <Form.Item
                    name='phone'
                    noStyle
                  >
                    <LrvInput
                      theme='light'
                      id='txt_phone'
                      placeholder={t('clients.phone')}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='ownerFirstName'
                label={t('clients.ownerFirstName')}
                rules={[{ required: true, message: t('clients.ownerFirstNameRequired') }]}
              >
                <LrvInput
                  theme='light'
                  value={newUserFirstName}
                  onChange={(e) => setNewUserFirstName(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='ownerLastName'
                label={t('clients.ownerLastName')}
                rules={[{ required: true, message: t('clients.ownerLastNameRequired') }]}
              >
                <LrvInput
                  theme='light'
                  value={newUserLastName}
                  onChange={(e) => setUserLastName(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='ownerEmail'
                label={t('clients.ownerEmail')}
                validateStatus={hasEmailError ? 'error' : undefined}
                help={hasEmailError ? t('clients.emailRepeat') : undefined}
                rules={[
                  { required: true, type: 'email', message: t('clients.ownerEmailRequired') },
                ]}
              >
                <LrvInput
                  theme='light'
                  value={newUserEmail}
                  autoComplete='off'
                  onChange={(e) => setNewUserEmail(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className={styles.password}>
                    {t('clients.ownerPassword')}&nbsp;
                    <PasswordPolicy />
                  </span>
                }
                required
                name='ownerPassword'
                rules={[() => ({ validator (rule, value) { return validatePasswordPolicy(value); } })]}
              >
                <LrvPassword
                  theme='light'
                  autoComplete='new-password'
                  value={newUserPassword}
                  onChange={(e) => setNewUserPassword(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          {renderResponsibleAndPromoterSelect()}

          <Row gutter={16}>
            {renderDaysToInitialStage()}
            {renderIsInternacional()}
          </Row>

          <Row gutter={16}>
            {
              !isDistributorCompany() &&
              <Col span={12}>
                {renderDistributionCompany()}
              </Col>
            }

            <Col span={12}>
              <Form.Item
                name='hasTrialPhase'
                label={t('clients.trialPhase')}
              >
                <LrvSwitch
                  theme='light'
                  id='trial_phase_switch'
                  checked={hasTrialPhase}
                  onChange={(value) => {
                    setHasTrialPhase(value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {
            hasPermissionToManageCompanies &&
            <Form.Item
              name='allowXlsxAnalysisReport'
              label={t('clients.allowXlsxAnalysisReport')}
            >
              <LrvSwitch
                theme='light'
                id='allow_xlsx_switch'
                checked={allowXlsxAnalysisReport}
                onChange={(value) => setAllowXlsxAnalysisReport(value)}
              />
            </Form.Item>
          }

          <Form.Item
            name='renuevalMonths'
            label={t('clients.renuevalMonths')}
          >
            <LrvSelect
              theme='light'
              showSearch
              mode='multiple'
              suffixIcon={<Icon name='arrow-down-s' />}
              removeIcon={<Icon name='close' />}
              placeholder={t('clients.select')}
              optionFilterProp='children'
              filterOption={filterOptionSelect}
              onChange={(e: number[]) => {
                setRenuevalMonths(e);
              }}
              value={renuevalMonths}
            >
              {
                months.map((month) => {
                  return (
                    <Option
                      key={month}
                      value={month}
                    >
                      {getMonthName(month.toString())}
                    </Option>
                  );
                })
              }
            </LrvSelect>
          </Form.Item>

          <Form.Item
            required
            name='plan'
            label={t('clients.plans')}
            rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
          >
            <LrvSelect
              theme='light'
              placeholder={t('clients.selectPlan')}
              onChange={handleChangePlans}
              value={newPlan}
              suffixIcon={<Icon name='arrow-down-s' />}
            >
              <Option key={PREPAID_PLAN} value={PREPAID_PLAN}> {t('clients.prepaidPlan')} </Option>
              <OptGroup label={t('clients.postpaidPlans')}>
                {childrenPlan}
                <Option key={PERSONALIZED_PLAN} value={PERSONALIZED_PLAN}> {t('clients.otherPlan')} </Option>
              </OptGroup>
            </LrvSelect>
          </Form.Item>

          {
            newPlan === PERSONALIZED_PLAN &&
            <Form.Item
              name='companyBalance'
              required
              label={t('clients.companyBalance')}
              rules={[() => ({ validator (rule, value) { return validateNumber(value, true, companyBalanceLimits.MIN, companyBalanceLimits.MAX, t('validations.maxValue') + companyBalanceLimits.MAX); } })]}
            >
              <LrvInput
                theme='light'
                autoFocus
                value={newCompanyBalance}
                onChange={(e) => {
                  const value = e.target.value;
                  setNewCompanyBalance(value);
                  updatePlanPrice({ value: PERSONALIZED_PLAN, newQuota: parseInt(value) });
                }}
              />
            </Form.Item>
          }

          {
            newPlan &&
            <Form.Item>
              <LrvText theme='light' text={t('payments.planPrice', { value: dollarUS.format(newPlanPrice) })} />
            </Form.Item>
          }

          {renderDiscountPercentage()}
        </LrvForm >
      </LrvModal>
    </Row >
  </div >;
}

export default Clients;
