import moment from 'moment';

import { INITIAL_DATE } from '../../../config/commons';
import { getCurrentElementHeight } from '../../../utils/dimensions';
import { typeParam, typeScale } from '../../../common/components/charts/ShadedPlot/helpers';

export const disabledMinimunDateTo = (current: moment.Moment, maximumDate: string) => {
  const start = moment(maximumDate).subtract(6, 'months');
  const end = moment(maximumDate).subtract(1, 'months');
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const disabledMaximunDateTo = (current: moment.Moment) => {
  const start = moment(INITIAL_DATE);
  const end = moment();
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const getMaximumDate = () => {
  return moment().format();
};

export const getMinimumDate = () => {
  return moment().subtract(2, 'months').format();
};

export const initialFilterState = {
  maximumDate: getMaximumDate(),
  minimumDate: getMinimumDate(),
  parameter: typeParam.AVG_WEIGHT,
  scale: typeScale.LINEAR,
};

interface HeightProps {
  filters: React.RefObject<HTMLDivElement>;
  legends: React.RefObject<HTMLDivElement>;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { filters, legends } = props;

  const headerHeight = 64;
  const axisXHeight = 24;
  const extraHeight = 42;

  const value = getCurrentElementHeight(filters) + getCurrentElementHeight(legends) + axisXHeight + headerHeight + extraHeight;
  return value;
};

export const getWidthOfTheOtherElements = () => {
  const sidebarWidth = window.innerWidth > 1420 ? 264 : window.innerWidth > 950 ? 78 : 0;
  const extraWidth = 40;
  return sidebarWidth + extraWidth;
};
