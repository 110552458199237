import cx from 'classnames';
import { Dropdown, Menu, Table } from 'antd';
import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { formatMonthDayYear } from '../../utils/date';
import { changeHeader } from '../AppHeader/headerSlice';
import { applyThousandsSeparator } from '../../utils/strings';
import { LrvTag } from '../../common/components/LrvTag/LrvTag';
import { LrvEmpty } from '../../common/components/LrvEmpty/LrvEmpty';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { lrvConfirm } from '../../common/components/LrvConfirm/LrvConfirm';
import { ExtraActionsButton } from '../../common/components/buttons/ExtraActionsButton';
import { ItemRenderPagination } from '../../common/components/LrvPagination/ItemRenderPagination';
import { THEME, stockingPhaseTypes, commonOptions, stockingStatuses, harvestOptions } from '../../config/commons';

import { Harvest } from './interfaces';
import { Subheader } from './Subheader';
import styles from './Harvests.module.scss';
import * as harvestsSlice from './harvestsSlice';
import { HistogramModal } from './Histogram/HistogramModal';
import { ConsolidateHarvests } from './ConsolidateHarvests';
import { HarvestHistogramXAxis } from './HarvestHistogramXAxis';

export const Harvests = () => {
  const [t] = useTranslation();

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const { company, phaseType } = useSelector((state: Store) => state.header);
  const {
    isLoading,
    consolidateHarvests,
    harvestsToShow,
    currentHarvests,
    previousHarvests,
    currentPage,
    limit,
    total,
  } = useSelector((state: Store) => state.harvests);
  const { selectedHarvests, selectedRowKeys } = consolidateHarvests;

  const showCurrentHarvests = harvestsToShow === harvestOptions.CURRENT;

  const theme = getCurrentTheme();
  const dispatch = useDispatch();

  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    dispatch(changeHeader({ title: t('harvests.title') }));
  }, [dispatch]);

  useEffect(() => {
    if (!company._id || phaseType !== stockingPhaseTypes.LARVAE) {
      return;
    }

    switch (harvestsToShow) {
      case harvestOptions.CURRENT:
        dispatch(harvestsSlice.fetchCurrentHarvests({ companyId: company._id, currentPage }));
        break;

      case harvestOptions.PREVIOUS:
        dispatch(harvestsSlice.fetchPreviousHarvests({ companyId: company._id, currentPage }));
        break;
    }
  }, [dispatch, company._id, phaseType]);

  const onExpand = (expanded: boolean, record: Harvest) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record._id]);
      return;
    }

    setExpandedRowKeys(expandedRowKeys.filter(key => key !== record._id));
  };

  const getLabelTag = (record: Harvest) => {
    if (record.stockingIds) {
      return t('harvests.tags.consolidated');
    }

    switch (record.status) {
      case stockingStatuses.HARVESTED:
        return t('harvests.tags.harvested');

      case stockingStatuses.ACTIVE:
        return t('harvests.tags.partialHarvested');

      default:
        return undefined;
    }
  };

  const getLabelClassName = (record: Harvest) => {
    if (record.stockingIds) {
      return isLightTheme ? styles.consolidatedTagLight : styles.consolidatedTagDark;
    }

    switch (record.status) {
      case stockingStatuses.HARVESTED:
        return styles.harvestedTag;

      case stockingStatuses.ACTIVE:
        return styles.partialHarvestedTag;

      default:
        return undefined;
    }
  };

  const renderTag = (record: Harvest) => {
    const label = getLabelTag(record);

    if (!label) {
      return;
    }

    return (
      <LrvTag
        id={`tag_${record._id}`}
        className={cx(styles.tag, isLightTheme ? styles.tagLight : styles.tagDark, getLabelClassName(record))}
      >
        {label}
      </LrvTag>
    );
  };

  const renderDropdownOptions = (record: Harvest) => {
    return (
      <Dropdown
        overlay={() => renderMenuItems(record)}
        trigger={['click']}
        placement='bottomRight'
      >
        <ExtraActionsButton
          onClick={(e) => e.stopPropagation()}
          id={`button_${record._id}`}
        />
      </Dropdown>
    );
  };

  const renderMenuItems = (record: Harvest) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          if (key === commonOptions.REMOVE) {
            showDeleteConfirm({ harvestId: record._id, harvestName: record.name });
            return;
          }
        }}
      >

        <Menu.Item id='unit_menu_option_remove' key={commonOptions.REMOVE} className={styles.menuItemOptions}>
          <Icon name='delete-bin' type='line' theme={theme} className={styles.icon} />
          <span>{t('harvests.deconsolidate')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const showDeleteConfirm = (props: { harvestId: string; harvestName: string }) => {
    const { harvestId, harvestName } = props;

    lrvConfirm({
      theme: 'light',
      title: t('harvests.confirmDeconsolidate'),
      icon: <ExclamationCircleOutlined />,
      content: harvestName,
      okText: t('harvests.deconsolidate'),
      okButtonProps: { id: 'btnOkDelete' },
      okType: 'primary',
      cancelText: t('harvests.cancel'),
      onOk () {
        dispatch(harvestsSlice.deleteHarvest({ harvestId, companyId: company._id, currentPage }));
      },
    });
  };

  const dataSource = showCurrentHarvests ? currentHarvests : previousHarvests;

  const getColumnTypes = () => {
    const columnsType: ColumnsType<Harvest> = [
      {
        key: 1,
        width: '18%',
        title: t('harvests.harvest'),
        dataIndex: 'name',
        ellipsis: { showTitle: false },
      },
      {
        key: 2,
        width: '12%',
        title: t('harvests.unit'),
        dataIndex: ['campusId', 'name'],
        ellipsis: { showTitle: false },
        responsive: ['sm'] as Breakpoint[],
      },
      {
        key: 3,
        width: '12%',
        title: t('harvests.module'),
        dataIndex: ['moduleId', 'name'],
        ellipsis: { showTitle: false },
        responsive: ['md'] as Breakpoint[],
      },
      {
        key: 4,
        width: '12%',
        title: t('harvests.container'),
        dataIndex: ['tankId', 'name'],
        ellipsis: { showTitle: false },
        responsive: ['md'] as Breakpoint[],
      },
      {
        key: 5,
        width: '12%',
        title: t('harvests.harvestDate'),
        dataIndex: 'endDate',
        ellipsis: { showTitle: false },
        responsive: ['sm'] as Breakpoint[],
        render: (endDate: string) => formatMonthDayYear(endDate),
      },
      {
        key: 6,
        width: '12%',
        title: t('harvests.animalsHarvested'),
        ellipsis: { showTitle: false },
        responsive: ['sm'] as Breakpoint[],
        render: (record: Harvest) => {
          if (record.isPartOfConsolidated && record.consolidatedAnimals) {
            return applyThousandsSeparator(record.consolidatedAnimals);
          }

          if (record.harvestQuantity) {
            return applyThousandsSeparator(record.harvestQuantity);
          }

          return '';
        },
      },
      {
        key: 7,
        width: '12%',
        render: (record: Harvest) => {
          return renderTag(record);
        },
      },
      {
        key: 8,
        width: '2%',
        render: (record: Harvest) => {
          if (!showCurrentHarvests || !record.stockingIds) {
            return ' ';
          }

          return renderDropdownOptions(record);
        },
      },
      Table.EXPAND_COLUMN
    ];

    if (!showCurrentHarvests) {
      return columnsType.slice(0, -2).concat(columnsType.slice(-1));
    }

    return columnsType;
  };

  const gerRowClassName = (record: Harvest, index: number) => {
    !!record.stockingIds;

    const className = index % 2 === 0 ? styles.rowEven : styles.rowOdd;
    const borderClassName = record.stockingIds ? expandedRowKeys.includes(record._id) ? styles.openRow : styles.border : '';

    const stockingIds = selectedHarvests.map((harvest) => harvest._id);
    const selectedRow = stockingIds.includes(record._id) ? styles.selected : '';

    return cx(className, borderClassName, selectedRow);
  };

  const clickOnRow = (record: Harvest) => {
    dispatch(harvestsSlice.resetHistograms());
    dispatch(harvestsSlice.setConsolidated(undefined));

    if (!record.stockingIds) {
      dispatch(harvestsSlice.fetchHarvestHistogram({ harvestId: record._id }));
      dispatch(harvestsSlice.setSelectedHarvest(record));
      dispatch(harvestsSlice.setShowModal(true));
      return;
    }

    dispatch(harvestsSlice.fetchConsolidationHistogram({ consolidatedId: record._id }));
    dispatch(harvestsSlice.setSelectedHarvest(record));
    dispatch(harvestsSlice.setShowModal(true));
  };

  const getRowSelection = (harvest?: Harvest) => {
    return {
      selectedRowKeys,
      onChange: (selectedRowKeys: React.Key[], selectedRows: Harvest[]) => {
        const harvests = selectedRows.map(item => ({
          ...item,
          harvestQuantityEdited: item.harvestQuantity,
        }));

        dispatch(harvestsSlice.setSelectedRowKeys(selectedRowKeys));
        dispatch(harvestsSlice.setSelectedHarvests(harvests));
      },
      getCheckboxProps: (record: Harvest) => ({
        name: record.name,
        style: { display: (record.stockingIds && record.stockingIds.length > 0) || (harvest && harvest.stockingIds && harvest.stockingIds.length > 0) ? 'none' : '' },
      }),
    };
  };

  const renderExpandedRow = (record: Harvest) => {
    return (
      <LrvTable
        className={styles.children}
        columns={[
          ...getColumnTypes().slice(0, -1),
          { title: '', dataIndex: 'expander', key: 'expander', width: 50, render: () => ' ' },
        ]}
        dataSource={record.stockingIds}
        pagination={false}
        showHeader={false}
        theme={theme}
        size='small'
        rowSelection={showCurrentHarvests ? { type: 'checkbox', ...getRowSelection(record) } : undefined}
        rowClassName={(r, index) => {
          const lastRow = record.stockingIds.length === index + 1 ? styles.lastRow : '';
          return cx(styles.row, lastRow);
        }}
        onRow={(record: Harvest) => {
          return {
            onClick: () => clickOnRow(record)
          };
        }}
      />
    );
  };

  const onChangePage = (page: number) => {
    dispatch(harvestsSlice.setCurrentPage(page));

    switch (harvestsToShow) {
      case harvestOptions.CURRENT:
        dispatch(harvestsSlice.fetchCurrentHarvests({ companyId: company._id, currentPage: page }));
        break;

      case harvestOptions.PREVIOUS:
        dispatch(harvestsSlice.fetchPreviousHarvests({ companyId: company._id, currentPage: page }));
        break;
    }
  };

  const renderTable = () => {
    if (phaseType !== stockingPhaseTypes.LARVAE) {
      return (
        <div className={styles.center}>
          <LrvEmpty description={t('harvests.disabled')} theme={theme} />
        </div>
      );
    }

    return (
      <LrvTable
        id='harvests-table'
        className={cx(styles.table, isLightTheme ? styles.tableLight : styles.tableDark)}
        columns={getColumnTypes()}
        loading={isLoading}
        dataSource={dataSource}
        scroll={dataSource.length === 0 ? undefined : { y: 'calc(100vh - 268px)' }}
        size='small'
        theme={theme}
        pagination={{
          size: 'default',
          showSizeChanger: false,
          defaultPageSize: limit,
          pageSize: limit,
          onChange: onChangePage,
          total: total,
          current: currentPage,
          itemRender: ItemRenderPagination,
        }}
        expandable={{
          expandedRowRender: renderExpandedRow,
          rowExpandable: (record) => !!record.stockingIds,
          onExpand: onExpand,
          expandedRowKeys: expandedRowKeys,
          expandIcon: ({ expanded, onExpand, record }) => {
            if (!record.stockingIds) {
              return ' ';
            }

            if (expanded) {
              return (
                <div
                  onClick={(e) => {
                    onExpand(record, e);
                    e.stopPropagation();
                  }}
                >
                  <Icon name='arrow-up-s' className={cx(styles.expandIcon, isLightTheme ? styles.expandIconLight : styles.expandIconDark)} />
                </div>
              );
            }

            return (
              <div
                onClick={(e) => {
                  onExpand(record, e);
                  e.stopPropagation();
                }}
              >
                <Icon name='arrow-down-s' className={cx(styles.expandIcon, isLightTheme ? styles.expandIconLight : styles.expandIconDark)} />
              </div>
            );
          }
        }}
        rowKey='_id'
        rowClassName={gerRowClassName}
        rowSelection={showCurrentHarvests ? { type: 'checkbox', ...getRowSelection() } : undefined}
        onRow={(record: Harvest) => {
          return {
            onClick: () => clickOnRow(record)
          };
        }}
      />
    );
  };

  return (
    <div className={styles.harvests}>
      <Subheader />
      {renderTable()}
      <HistogramModal />
      <ConsolidateHarvests />
      <HarvestHistogramXAxis />
    </div>
  );
};
