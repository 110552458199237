import cx from 'classnames';
import { Row, InputRef } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { THEME } from '../../config/commons';
import { RootState } from '../../state/store';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import IconButton from '../../common/components/buttons/IconButton';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';

import * as stockingSlice from './sowingsSlice';
import styles from './StockingSearchDesktop.module.scss';
import RadioGroupSearchType from './RadioGroupSearchType';

interface Props {
  searchStockingsByName: (value: string) => void;
}

function StockingSearchDesktop ({ searchStockingsByName }: Props) {
  const searchRef = useRef<InputRef>(null);

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { showSearchInput, searchValue } = useSelector((state: RootState) => state.stockings.filters);
  const [showRadioGroupSearch, setShowRadioGroupSearch] = useState(!!searchValue);

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    setTimeout(() => {
      searchRef.current?.focus({ cursor: 'all' });
    }, 100);
  }, [showSearchInput]);

  const onSearch = (value: string) => {
    setShowRadioGroupSearch(false);
    if (!showSearchInput) {
      dispatch(stockingSlice.setShowSearchInput(true));
      return;
    }

    dispatch(stockingSlice.setPage(1));
    dispatch(stockingSlice.setSearchValue(value));
    searchStockingsByName(value);

    if (!value && showSearchInput) { // hide input only when there is no value or is empty
      dispatch(stockingSlice.setShowSearchInput(false));
    }
  };

  return (
    <Row className={styles.containerInputSearch}>
      <LrvInput
        id='search_stocking'
        theme={theme}
        ref={searchRef}
        autoFocus
        readOnly={!showSearchInput}
        className={cx(
          styles.inputSearch,
          isLightTheme ? styles.inputSearchLight : styles.inputSearchDark,
          showSearchInput ? styles.activeSearch : styles.hideSearch
        )}
        placeholder={t('stockings.search')}
        onChange={(e) => {
          if (e.target.value) {
            setShowRadioGroupSearch(true);
          }
          dispatch(stockingSlice.setSearchValue(e.target.value));
        }}
        value={searchValue}
        onFocus={() => {
          const existValue = !!searchValue;
          setShowRadioGroupSearch(existValue);
        }}
        onBlur={() => {
          setShowRadioGroupSearch(false);
          if (!searchValue) {
            setTimeout(() => {
              dispatch(stockingSlice.setShowSearchInput(false));
            }, 100);
          }
        }}
        onPressEnter={() => {
          onSearch(searchValue);
        }}
      />
      <IconButton
        id='btn_search_stocking'
        theme={theme}
        type={showSearchInput ? 'default' : 'text'}
        className={showSearchInput ? cx(styles.btnSearchStokings, styles.activeBtn) : ''}
        onClick={() => {
          onSearch(searchValue);
        }}
        icon={<Icon id='search_icon_button' name='search' theme={theme} />}
      />
      <RadioGroupSearchType show={showRadioGroupSearch} />
    </Row>
  );
}

export default StockingSearchDesktop;