import cx from 'classnames';
import moment from 'moment';
import { Form, Space } from 'antd';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import { RootState } from '../../state/store';
import Icon from '../../common/components/Icon/Icon';
import { formatMonthDayYear } from '../../utils/date';
import { MAX_SURVIVAL_RATE } from '../../config/commons';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import IconButton from '../../common/components/buttons/IconButton';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvDivider } from '../../common/components/LrvDivider/LrvDivider';
import { LrvPopover } from '../../common/components/LrvPopover/LrvPopover';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../utils/strings';
import { fixStockingStartDate, getAnimals, getStartDateStocking, calcDensity, getUnitDensity } from '../../helpers/stocking.helpers';
import { calcNewDensity, calcNewSurvivalRate, calcNewNumberAnimalsByDensity, calcNewNumberAnimalsBySurvivalRate, getMaxAnimals, getMaxDensity, getMaxSurvivalRate, sortAndBuildPopulationData, updateTransfersItems } from '../../helpers/population.helpers';

import './Sowings.scss';
import PopulationTypes, { PopulationData } from './interfaces';
import styles from './EditStockingPopulation.module.scss';
import * as editPopulationStockingSlice from './editStockingPopulationSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export const EditStockingPopulation = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { showModal, selectedStocking, isLoadingStocking, isUpdating, stockingTransfers } = useSelector((state: RootState) => state.editStockingPopulation);
  const { _id: companyId } = useSelector((state: RootState) => state.header.company);

  const [disabledButton, setDisabledButton] = useState(true);
  const [showPopulationAnimalError, setShowPopulationAnimalError] = useState(false);
  const [populationData, setPopulationData] = useState<PopulationData[]>([]);
  const populationsRef = useRef<HTMLDivElement | null>(null);
  const [unitDensity, setUnitDensity] = useState<string>('');
  const [errorData, setErrorData] = useState({ index: -1, hasError: false });

  useEffect(() => {
    if (!selectedStocking._id) {
      return;
    }

    const startDateStocking = getStartDateStocking({ stocking: selectedStocking, phaseType: selectedStocking.phaseType });
    const startDateFixed = fixStockingStartDate(startDateStocking);
    const animalsNumber = getAnimals({ stocking: selectedStocking, phaseTypeSelected: selectedStocking.phaseType });

    const density = calcDensity(selectedStocking);
    const unitDensity = getUnitDensity(selectedStocking);
    setUnitDensity(unitDensity);

    form.setFieldsValue({
      stockingStartDate: formatMonthDayYear(startDateFixed),
      animalsNumber,
      density: `${density} ${unitDensity}`,
      survivalRate: `${MAX_SURVIVAL_RATE}%`,
    });
  }, [selectedStocking]);

  useEffect(() => {
    if (!selectedStocking?._id) {
      return;
    }

    const stockingIds = selectedStocking.transfers.map(item => item.stockingId);
    dispatch(editPopulationStockingSlice.fetchStockingTransfersData({ companyId, stockingIds }));

  }, [selectedStocking, companyId]);

  useEffect(() => {
    if (selectedStocking?.populations?.length > 0 || selectedStocking?.transfers?.length > 0) {
      const populationsData = sortAndBuildPopulationData(selectedStocking);
      setPopulationData(populationsData);
      return;
    }
    setPopulationData([{ populationDate: moment().toISOString(), animalsTransferred: 0, animalsNumber: 0, density: 0, survivalRate: 0, type: PopulationTypes.MANUAL }]);
  }, [selectedStocking]);

  useEffect(() => {
    if (!showModal) {
      setPopulationData([{ populationDate: moment().toISOString(), animalsTransferred: 0, animalsNumber: 0, density: 0, survivalRate: 0, type: PopulationTypes.MANUAL }]);
      form.resetFields();
    }
  }, [showModal]);

  useEffect(() => {
    if (!populationsRef.current) {
      return;
    }

    if (populationData.length === 1 && populationData[0].animalsNumber === 0) {
      return;
    }

    for (let index = 0; index < populationData.length; index++) {
      const population = populationData[index];
      if (population.animalsNumber === 0) {
        setDisabledButton(true);
        return;
      }
    }

    const populationAnimalElements = populationsRef.current.querySelectorAll('.ant-input-number.hasPopulationError');

    const hasPopulationAnimalError = populationAnimalElements.length > 0;

    setShowPopulationAnimalError(hasPopulationAnimalError);

    setDisabledButton(hasPopulationAnimalError);
  }, [populationsRef, populationData]);

  const addPopulationItem = () => {
    if (disabledButton) {
      return;
    }

    const newData: PopulationData[] = cloneDeep(populationData);
    newData.push({ populationDate: moment().toISOString(), animalsTransferred: 0, animalsNumber: 0, density: 0, survivalRate: 0, type: PopulationTypes.MANUAL });
    setPopulationData(newData);
  };

  const renderAddPopulationButton = () => {
    return (
      <ActionButton
        id='btn_add_population'
        theme='light'
        containerClassName={cx(styles.addPopulationBtnContainer, disabledButton ? styles.marginTop : '')}
        icon={<Icon name='add' />}
        onClick={addPopulationItem}
        disabled={disabledButton}
        type='ghost'
      >
        {t('stockings.newPopulation')}
      </ActionButton>
    );
  };

  const disableDate = (current: moment.MomentInput, index: number) => {
    const isThereNextIndex = (index + 1) < populationData.length;
    const nextDate = isThereNextIndex ? populationData[index + 1].populationDate : undefined;

    if (index === 0) {
      const stockingDate = getStartDateStocking({ stocking: selectedStocking, phaseType: selectedStocking.phaseType });
      const start = moment(stockingDate).startOf('day').add(1, 'day');
      const end = moment(nextDate || undefined);
      return !(start.isSameOrBefore(current) && end.isSameOrAfter(current));
    }

    const prevDate = populationData[index - 1].populationDate;

    if (nextDate) {
      const start = moment(prevDate).startOf('day');
      const end = moment(nextDate).startOf('day').add(1, 'day');
      return !(start.isSameOrBefore(current) && end.isSameOrAfter(current));
    }

    const start = moment(prevDate).startOf('day');
    const end = moment();
    return !(start.isSameOrBefore(current) && end.isSameOrAfter(current));
  };

  const findAnimalsPopulationError = (index: number, populationData: PopulationData[]) => {
    const populationItem = populationData[index];
    const animalsPopulation = populationItem.animalsNumber;

    const isThereNextIndex = (index + 1) < populationData.length;
    const isTherePrevIndex = (index - 1) >= 0;
    const stockingAnimals = getAnimals({ stocking: selectedStocking, phaseTypeSelected: selectedStocking.phaseType });
    const prevAnimalsPopulation = isTherePrevIndex ? populationData[index - 1].animalsNumber : stockingAnimals;
    const nextAnimalsPopulation = isThereNextIndex ? populationData[index + 1].animalsNumber : undefined;

    if (nextAnimalsPopulation) {
      return !(animalsPopulation <= prevAnimalsPopulation && animalsPopulation >= nextAnimalsPopulation);
    }

    return !(animalsPopulation <= prevAnimalsPopulation);
  };

  const findDensityPopulationError = (index: number, populationData: PopulationData[]) => {
    const populationItem = populationData[index];
    const densityPopulation = populationItem.density;

    const isThereNextIndex = (index + 1) < populationData.length;
    const isTherePrevIndex = (index - 1) >= 0;
    const stockingDensity = calcDensity(selectedStocking);
    const prevDensityPopulation = isTherePrevIndex ? populationData[index - 1].density : stockingDensity;
    const nextDensityPopulation = isThereNextIndex ? populationData[index + 1].density : undefined;

    if (nextDensityPopulation) {
      return !(densityPopulation <= prevDensityPopulation && densityPopulation >= nextDensityPopulation);
    }

    return !(densityPopulation <= prevDensityPopulation);
  };

  const findSurvivalRatePopulationError = (index: number, populationData: PopulationData[]) => {
    const populationItem = populationData[index];
    const survivalRatePopulation = populationItem.survivalRate;

    const isThereNextIndex = (index + 1) < populationData.length;
    const isTherePrevIndex = (index - 1) >= 0;
    const stockingSurvivalRate = MAX_SURVIVAL_RATE;
    const prevSurvivalRatePopulation = isTherePrevIndex ? populationData[index - 1].survivalRate : stockingSurvivalRate;
    const nextSurvivalRatePopulation = isThereNextIndex ? populationData[index + 1].survivalRate : undefined;

    if (nextSurvivalRatePopulation) {
      return !(survivalRatePopulation <= prevSurvivalRatePopulation && survivalRatePopulation >= nextSurvivalRatePopulation);
    }

    return !(survivalRatePopulation <= prevSurvivalRatePopulation);
  };

  const renderPopulationDate = (props: { population: PopulationData; index: number }) => {
    const { population, index } = props;
    const allowValidation = population.type === PopulationTypes.MANUAL;

    const populationDate = population.populationDate ? moment(population.populationDate) : moment();

    return (
      <LrvDatePicker
        id={`population_date_${index}`}
        titleClassName={styles.titleInput}
        containerClassName={cx(styles.populationDateContainer, allowValidation ? '' : styles.readOnly)}
        title={index === 0 ? t('stockings.populationDate') : undefined}
        theme={theme}
        disabled={!allowValidation}
        className={cx(styles.populationDate, allowValidation ? '' : styles.readOnly)}
        value={populationDate}
        allowClear={false}
        disabledDate={(value) => allowValidation ? disableDate(value, index) : false}
        placeholder={t('stockings.populationDate')}
        onChange={(date) => {
          const newData: PopulationData[] = cloneDeep(populationData);
          const value = date?.toISOString() || '';
          newData[index].populationDate = value;
          setPopulationData(newData);
        }}
      />
    );
  };

  const renderNumberAnimals = (props: { population: PopulationData; index: number }) => {
    const { population, index } = props;
    const allowValidation = population.type === PopulationTypes.MANUAL;

    const maxAnimals = allowValidation ? getMaxAnimals({ index, populations: populationData, stocking: selectedStocking }) : undefined;
    const hasPopulationAnimalsError = allowValidation ? findAnimalsPopulationError(index, populationData) : false;
    const hasError = index === errorData.index && errorData.hasError;

    return (
      <LrvInputNumber
        id={`txt_animals_number_${index}`}
        titleClassName={styles.titleInput}
        className={cx(styles.populationAnimalsNumber, (hasPopulationAnimalsError || hasError) ? 'hasPopulationError' : '', allowValidation ? '' : styles.readOnly)}
        title={index === 0 ? t('stockings.animalsNumber') : undefined}
        theme={theme}
        value={population.animalsNumber}
        min={0}
        max={maxAnimals}
        readOnly={!allowValidation}
        formatter={value => applyThousandsSeparator(value)}
        parser={value => applyParserThousandsSeparator(value)}
        placeholder={t('stockings.animalsNumber')}
        autoFocus
        onChange={(e) => {
          const populations: PopulationData[] = cloneDeep(populationData);
          const value = Number(e);

          populations[index].animalsNumber = value;
          populations[index].density = calcNewDensity({ population: populations[index], stocking: selectedStocking });
          populations[index].survivalRate = calcNewSurvivalRate({ population: populations[index], stocking: selectedStocking, populations, index });
          const populationsUpdated = updateTransfersItems(populations, selectedStocking);
          const hasAnimalsError = findAnimalsPopulationError(index, populations);
          setErrorData({ index, hasError: hasAnimalsError });
          setShowPopulationAnimalError(hasAnimalsError);

          setPopulationData(populationsUpdated);
        }}
      />
    );
  };

  const renderDensity = (props: { population: PopulationData; index: number }) => {
    const { population, index } = props;
    const allowValidation = population.type === PopulationTypes.MANUAL;

    const maxDensity = allowValidation ? getMaxDensity({ index, populations: populationData, stocking: selectedStocking }) : undefined;
    const hasPopulationDensityError = allowValidation ? findDensityPopulationError(index, populationData) : false;
    const hasError = index === errorData.index && errorData.hasError;

    return (
      <LrvInputNumber
        id={`txt_density_${index}`}
        titleClassName={styles.titleInput}
        className={cx(styles.populationAnimalsNumber, (hasPopulationDensityError || hasError) ? 'hasPopulationError' : '', allowValidation ? '' : styles.readOnly)}
        title={index === 0 ? `${t('stockings.density')} ${unitDensity}` : undefined}
        theme={theme}
        value={population.density}
        min={0}
        max={maxDensity}
        readOnly={!allowValidation}
        formatter={value => applyThousandsSeparator(value)}
        parser={value => applyParserThousandsSeparator(value)}
        placeholder={`${t('stockings.density')} ${unitDensity}`}
        autoFocus
        onChange={(e) => {
          const populations: PopulationData[] = cloneDeep(populationData);
          const value = Number(e);

          populations[index].density = value;
          populations[index].animalsNumber = calcNewNumberAnimalsByDensity({ density: value, stocking: selectedStocking });
          populations[index].survivalRate = calcNewSurvivalRate({ population: populations[index], stocking: selectedStocking, index, populations });
          const populationsUpdated = updateTransfersItems(populations, selectedStocking);
          const hasDensityError = findDensityPopulationError(index, populations);
          setErrorData({ index, hasError: hasDensityError });
          setShowPopulationAnimalError(hasDensityError);

          setPopulationData(populationsUpdated);
        }}
      />
    );
  };

  const renderSurvivalRate = (props: { population: PopulationData; index: number }) => {
    const { population, index } = props;
    const allowValidation = population.type === PopulationTypes.MANUAL;

    const maxSurvivalRate = allowValidation ? getMaxSurvivalRate({ index, populations: populationData }) : undefined;
    const hasPopulationSurvivalRateError = allowValidation ? findSurvivalRatePopulationError(index, populationData) : false;
    const hasError = index === errorData.index && errorData.hasError;

    return (
      <LrvInputNumber
        id={`txt_survival_rate_${index}`}
        titleClassName={styles.titleInput}
        className={cx(styles.populationSurvivalRate, (hasPopulationSurvivalRateError || hasError) ? 'hasPopulationError' : '', allowValidation ? '' : styles.readOnly)}
        title={index === 0 ? `${t('survivalRate.title')} (%)` : undefined}
        theme={theme}
        value={population.survivalRate}
        min={0}
        max={maxSurvivalRate}
        readOnly={!allowValidation}
        placeholder={`${t('survivalRate.title')} (%)`}
        autoFocus
        onChange={(e) => {
          const populations: PopulationData[] = cloneDeep(populationData);
          const value = Number(e);

          populations[index].survivalRate = value;
          populations[index].animalsNumber = calcNewNumberAnimalsBySurvivalRate({ population: populations[index], stocking: selectedStocking, index, populations });
          populations[index].density = calcNewDensity({ population: populations[index], stocking: selectedStocking });
          const populationsUpdated = updateTransfersItems(populations, selectedStocking);
          const hasSurvivalRateError = findSurvivalRatePopulationError(index, populations);
          setErrorData({ index, hasError: hasSurvivalRateError });
          setShowPopulationAnimalError(hasSurvivalRateError);

          setPopulationData(populationsUpdated);
        }}
      />
    );
  };

  const renderRightIcon = (params: { population: PopulationData, index: number }) => {
    const { index, population } = params;
    if (population.type === PopulationTypes.HARVEST) {
      const stockingTransferred = stockingTransfers.find(element => element._id === population.stockingId);

      const content = (
        <div className={styles.popoverContent}>
          <li><strong>{t('stockings.destinationStocking')}: </strong>{stockingTransferred?.name}</li>
          <li><strong>{t('stockings.animalsTransferred')}: </strong>{applyThousandsSeparator(population.animalsTransferred)}</li>
        </div>
      );

      return (
        <LrvPopover content={content} trigger='click'>
          <IconButton
            className={styles.btnRemovePopulationRow}
            theme={theme}
            iconName='information'
          />
        </LrvPopover>
      );
    }

    return (
      <IconButton
        className={styles.btnRemovePopulationRow}
        onClick={() => {
          const populations: PopulationData[] = cloneDeep(populationData);
          populations.splice(index, 1);
          const populationsUpdated = updateTransfersItems(populations, selectedStocking);
          setPopulationData(populationsUpdated);
        }}
        theme={theme}
        disabled={populationData.length === 1}
        iconName='delete-bin'
      />
    );
  };

  const renderPopulationInputs = () => {
    return populationData.map((item: PopulationData, index: number) => {
      return (
        <Space size={20} className={styles.populationRow}>

          {renderPopulationDate({ population: item, index })}
          {renderNumberAnimals({ population: item, index })}
          {renderDensity({ population: item, index })}
          {renderSurvivalRate({ population: item, index })}
          {renderRightIcon({ population: item, index })}
        </Space>
      );
    });
  };

  const renderErrorLabel = (text: string) => {
    return (
      <div>
        <LrvText className={styles.errorLabel} text={text} />
      </div>
    );
  };

  return (
    <LrvModal
      theme={theme}
      isLoading={isLoadingStocking}
      className={styles.stockingPopulationModal}
      title={t('stockings.addPopulations')}
      open={showModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit_edit_stocking_population',
        htmlType: 'submit',
        form: 'formStockingPopulation',
        loading: isUpdating,
        disabled: disabledButton,
      }}
      okText={t('stockings.savePopulation')}
      cancelText={t('stockings.cancel')}
      onOk={() => {
        dispatch(editPopulationStockingSlice.patchStockingPopulations({ stockingId: selectedStocking._id, data: populationData }));
      }}
      onCancel={() => {
        setPopulationData([{ populationDate: moment().toISOString(), animalsTransferred: 0, animalsNumber: 0, density: 0, survivalRate: 0, type: PopulationTypes.MANUAL }]);
        setDisabledButton(false);
        setShowPopulationAnimalError(false);
        dispatch(editPopulationStockingSlice.setShowModal(false));
      }}
    >
      <div>
        <LrvForm
          theme={theme}
          form={form}
          name='formStockingPopulation'
          id='formStockingPopulation'
          layout='vertical'
        >
          <Space direction='horizontal' size={20}>
            <Form.Item
              name='stockingStartDate'
              label={t('stockings.startDate')}
            >
              <LrvInput
                id='stocking_start_date'
                className={styles.stockingStartDateInput}
                theme={theme}
                readOnly
                placeholder={t('stockings.startDate')}
              />
            </Form.Item>

            <Form.Item
              name='animalsNumber'
              label={t('stockings.sownAnimals')}
            >
              <LrvInputNumber
                id='stocking_animals'
                className={styles.sownAnimalsInput}
                theme={theme}
                readOnly
                placeholder={t('stockings.sownAnimals')}
                formatter={value => applyThousandsSeparator(value)}
                parser={value => applyParserThousandsSeparator(value)}
              />
            </Form.Item>

            <Form.Item
              name='density'
              label={t('stockings.density')}
            >
              <LrvInput
                id='density'
                className={styles.density}
                theme={theme}
                readOnly
                placeholder={t('stockings.density')}
              />
            </Form.Item>

            <Form.Item
              name='survivalRate'
              label={t('survivalRate.title')}
            >
              <LrvInput
                id='survival-rate'
                className={styles.survivalRate}
                theme={theme}
                readOnly
                placeholder={t('stockings.survivalRate')}
              />
            </Form.Item>
          </Space>

          <LrvDivider theme='light' className={styles.divider} />

          <Form.Item
            name='populations'
          >
            <div ref={populationsRef} className={styles.populations}>
              {renderPopulationInputs()}
            </div>
          </Form.Item>
          {showPopulationAnimalError && renderErrorLabel(t('stockings.populationAnimalError'))}
          {renderAddPopulationButton()}
        </LrvForm>
      </div>
    </LrvModal>
  );
};
