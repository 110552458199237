import cx from 'classnames';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeSpaces } from '../../utils/strings';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getUserSession } from '../../utils/userSession';
import { isSuperAdmin, THEME } from '../../config/commons';
import { LrvText } from '../../common/components/LrvText/LrvText';
import IconButton from '../../common/components/buttons/IconButton';
import { LrvPopover } from '../../common/components/LrvPopover/LrvPopover';
import { LrvTooltip } from '../../common/components/LrvTooltip/LrvTooltip';
import { formatMonthDayYear, getMonthName, getStartDate } from '../../utils/date';
import { LrvPagination } from '../../common/components/LrvPagination/LrvPagination';
import { ItemRenderPagination } from '../../common/components/LrvPagination/ItemRenderPagination';

import Legends from './Legends';
import Spinner from './Spinner';
import './PrepaidBalanceConsumption.scss';
import { CompanyPrepaidBalances } from './interfaces';
import styles from './PrepaidBalanceConsumption.module.scss';
import { fetchPrepaidBalancePayments, fetchXlsxPaymentsReport, setPrepaidCurrentPage } from './BalanceConsumptionSlice';
import { dayFifteen, dayOne, getBackgroundColor, getDaysBeetwenTwoDates, getDaysInTheLastThreeMonths } from './helpers';

interface Props {
  theme?: 'dark' | 'light';
}

export default function PrepaidBalanceConsumption (props: Props) {
  const { theme } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { filters, prepaid } = useSelector((state: Store) => state.balanceConsumption);

  const {
    data: dataBalance,
    months,
    total,
    limit,
    currentPage,
    isLoading,
    isDownloadingBeforeLastMonth,
    isDownloadingLastMonth,
  } = prepaid;

  const {
    sellerId, promoterId,
    showActiveCompanies,
    showInternationalCompanies,
    showTrialPhaseCompanies,
  } = filters;

  const refGrid = useRef<HTMLDivElement>(null);
  const userSession = getUserSession();
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    const params = { page: 0, sellerId, promoterId, active: showActiveCompanies, isInternational: showInternationalCompanies, showTrialPhase: showTrialPhaseCompanies };
    dispatch(fetchPrepaidBalancePayments(params));
  }, [dispatch, sellerId, promoterId, showActiveCompanies, showTrialPhaseCompanies, showInternationalCompanies]);

  function renderBalanceUsageByCompany (props: { companyBalances: CompanyPrepaidBalances[]; totalDays: number; showBorderBottom: boolean }) {
    const { companyBalances, totalDays, showBorderBottom } = props;

    const boxs = [];

    for (let index = 0; index < companyBalances.length; index++) {
      const balance = companyBalances[index];

      if (!balance.balanceEndingDate) {
        continue;
      }

      const quota = balance.quota;
      const quotaUsed = balance.quotaUsed;
      const percentage = quota === 0 ? 0 : quotaUsed * 100 / quota;

      const startDate = getStartDate({ monthsToSubtract: 2 });
      const paymentDate = new Date(balance.paymentDate);
      const balanceEndingDate = new Date(balance.balanceEndingDate);

      const left = getDaysBeetwenTwoDates({ startDate, endDate: paymentDate });
      const days = getDaysBeetwenTwoDates({ startDate: paymentDate, endDate: balanceEndingDate });
      const backgroundColor = getBackgroundColor(percentage);

      const box = (
        <LrvPopover
          key={balance._id}
          color='white'
          content={() =>
            <div className={styles.popover}>
              <div>
                {t('balances.prepaidBalance.credited')}: {quota}
              </div>
              <div>
                {t('balances.prepaidBalance.consumed')}: {quotaUsed}
              </div>
              <div>
                {t('balances.prepaidBalance.startDate')}: {formatMonthDayYear(balance.paymentDate)}
              </div>
              <div>
                {t('balances.prepaidBalance.endDate')}: {formatMonthDayYear(balance.balanceEndingDate)}
              </div>
            </div>
          }
        >
          <div
            style={{
              position: 'absolute',
              height: 24,
              width: (days * 100 / totalDays) + '%',
              left: (left * 100 / totalDays) + '%',
              backgroundColor: backgroundColor
            }}
          />
        </LrvPopover >
      );

      boxs.push(box);
    }

    return (
      <div className={cx(styles.item, showBorderBottom ? styles.showBorderBottom : undefined)}>
        <div className={cx(styles.month, isLightTheme ? styles.monthLight : styles.monthDark)} />
        <div className={cx(styles.month, isLightTheme ? styles.monthLight : styles.monthDark)} />
        <div className={cx(styles.month, isLightTheme ? styles.monthLight : styles.monthDark)} />

        <div className={styles.absolute}>
          {boxs}
        </div>
      </div>
    );
  }

  function renderBalanceUsage () {
    const listPercentage = [];
    const days = getDaysInTheLastThreeMonths();

    const keys = Object.keys(dataBalance);
    const lastKey = keys[keys.length - 1];

    for (const key in dataBalance) {
      if (Object.prototype.hasOwnProperty.call(dataBalance, key)) {
        const companyName = key;
        const companyBalances: CompanyPrepaidBalances[] = dataBalance[key];

        const showBorderBottom = lastKey === key;
        const balanceBoxes = renderBalanceUsageByCompany({ companyBalances, totalDays: days, showBorderBottom });

        const companyId = `company_${removeSpaces(companyName).toLowerCase()}`;
        const percentagesId = `percentages_${removeSpaces(companyName).toLowerCase()}`;

        const item = (
          <div className={styles.row} key={companyName}>
            <LrvTooltip title={companyName} placement='topLeft'>
              <div id={companyId} className={styles.company}>
                <LrvText text={companyName} theme={theme} />
              </div>
            </LrvTooltip>

            <div id={percentagesId} className={styles.percentages}>
              <div className={styles.months}>
                {balanceBoxes}
              </div>
            </div>
          </div>
        );

        listPercentage.push(item);
      }
    }

    return listPercentage;
  }

  const renderMonthBeforeLast = () => {
    let monthBeforeLast = '';
    if (months.monthBeforeLast.month !== 0 && months.monthBeforeLast.year !== 0) {
      monthBeforeLast = `${getMonthName(months.monthBeforeLast.month.toString())} ${months.monthBeforeLast.year}`;
    }

    return (
      <Space>
        <div className={styles.month}>
          <LrvText text={monthBeforeLast} theme={theme} />
        </div>

        {
          (monthBeforeLast && (isSuperAdmin() || userSession.haveAccessPaymentReport)) &&
          <IconButton
            id='maturations_button'
            loading={isDownloadingBeforeLastMonth}
            className={styles.button}
            icon={<Icon name='download' theme={theme} />}
            onClick={() => {
              const data = { year: months.monthBeforeLast.year, month: months.monthBeforeLast.month };
              dispatch(fetchXlsxPaymentsReport({ ...data, monthToDownload: 'beforeLast', payment: 'prepaid' }));
            }}
            tooltipText={t('balances.download', { date: monthBeforeLast })}
          />
        }
      </Space>
    );
  };

  const renderLastMonth = () => {
    let lastMonth = '';
    if (months.lastMonth.month !== 0 && months.lastMonth.year !== 0) {
      lastMonth = `${getMonthName(months.lastMonth.month.toString())} ${months.lastMonth.year}`;
    }

    return (
      <Space>
        <div className={styles.month}>
          <LrvText text={lastMonth} theme={theme} />
        </div>

        {
          (lastMonth && (isSuperAdmin() || userSession.haveAccessPaymentReport)) &&
          <IconButton
            id='maturations_button'
            loading={isDownloadingLastMonth}
            className={styles.button}
            icon={<Icon name='download' theme={theme} />}
            onClick={() => {
              const data = { year: months.lastMonth.year, month: months.lastMonth.month };
              dispatch(fetchXlsxPaymentsReport({ ...data, monthToDownload: 'last', payment: 'prepaid' }));
            }}
            tooltipText={t('balances.download', { date: lastMonth })}
          />
        }
      </Space>
    );
  };

  const renderCurrentMonth = () => {
    let currentMonth = '';
    if (months.currentMonth.month !== 0 && months.currentMonth.year !== 0) {
      currentMonth = `${getMonthName(months.currentMonth.month.toString())} ${months.currentMonth.year}`;
    }

    return (
      <div>
        <div className={styles.month}>
          <LrvText text={currentMonth} theme={theme} />
        </div>
      </div>
    );
  };

  function renderBalanceReport () {
    const balanceUsage = renderBalanceUsage();

    return (
      <div>
        <div className={styles.container}>
          <div className={styles.months}>
            {renderMonthBeforeLast()}
            {renderLastMonth()}
            {renderCurrentMonth()}
          </div>

          <div className={styles.months}>
            <div className={styles.days}>
              <div>
                <LrvText text={dayOne.toString()} theme={theme} />
              </div>
              <div>
                <LrvText text={dayFifteen.toString()} theme={theme} />
              </div>
            </div>

            <div className={styles.days}>
              <div>
                <LrvText text={dayOne.toString()} theme={theme} />
              </div>
              <div>
                <LrvText text={dayFifteen.toString()} theme={theme} />
              </div>
            </div>

            <div className={styles.days}>
              <div>
                <LrvText text={dayOne.toString()} theme={theme} />
              </div>
              <div>
                <LrvText text={dayFifteen.toString()} theme={theme} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.grid} ref={refGrid}>
          {balanceUsage}
        </div>
      </div>
    );
  }

  const resetScroll = () => {
    if (refGrid.current?.scrollTop && refGrid.current?.scrollTop > 0) {
      refGrid.current.scrollTop = 0;
    }
  };

  function onChangePage (page: number) {
    dispatch(setPrepaidCurrentPage(page));
    resetScroll();

    const params = { page: page, sellerId, promoterId, active: showActiveCompanies, isInternational: showInternationalCompanies, showTrialPhase: showTrialPhaseCompanies };
    dispatch(fetchPrepaidBalancePayments(params));
  }

  function renderPagination () {
    return <div className={styles.pagination}>
      <LrvPagination
        defaultPageSize={limit}
        pageSize={limit}
        onChange={onChangePage}
        total={total}
        current={currentPage}
        showSizeChanger={false}
        itemRender={ItemRenderPagination}
        theme={theme}
      />
    </div>;
  }

  const renderSpinner = () => {
    if (isLoading) {
      return (
        <Spinner />
      );
    }

    return null;
  };

  return (
    <div className={cx(styles.prepaid, 'prepaid')}>
      {renderSpinner()}
      <Legends theme={theme} />
      {renderBalanceReport()}
      {renderPagination()}
    </div>
  );
}
