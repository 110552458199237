import { useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Store } from '../../state/store.interfaces';
import { formatMonthDayYear } from '../../utils/date';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { lrvConfirm } from '../../common/components/LrvConfirm/LrvConfirm';

import styles from './AnalysisFromConsolidated.module.scss';
import * as analysisFromConsolidatedSlice from './analysisFromConsolidatedSlice';
import { AnalysisFromConsolidated as IAnalysisFromConsolidated } from './interfaces';

interface Props {
  theme?: 'dark' | 'light';
  currentPage: number;
}

export const AnalysisFromConsolidated = (props: Props) => {
  const { theme = 'light', currentPage } = props;

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const {
    analysis,
    analysisConsolidated,
    isLoading,
    showModal,
  } = useSelector((state: Store) => state.analysisFromConsolidated);

  const { selectedStocking } = useSelector((state: Store) => state.stockings);

  useEffect(() => {
    if (!analysisConsolidated?._id) {
      return;
    }

    dispatch(analysisFromConsolidatedSlice.fetchAnalysis(analysisConsolidated._id));
  }, [dispatch, analysisConsolidated?._id]);

  const analysisData: IAnalysisFromConsolidated[] = analysis.map((item) => {
    return {
      _id: item._id,
      code: item.code,
      createdAt: formatMonthDayYear(item.createdAt),
    };
  });

  const renderConfirmationRemoveAnalyisis = (analysis: IAnalysisFromConsolidated) => {
    const title = t('analysis.confirmRemoveAnalysis.title', { analysisCode: analysis.code });
    const description = t('analysis.confirmRemoveAnalysis.description');
    const idOkButton = 'confirm_button';
    const idCancelButton = 'cancel_button';

    lrvConfirm({
      theme: 'light',
      title: title,
      content: description,
      icon: <ExclamationCircleOutlined />,
      okText: t('confirm.yes').toUpperCase(),
      cancelText: t('confirm.no').toUpperCase(),
      okButtonProps: { id: idOkButton },
      cancelButtonProps: { id: idCancelButton },
      onOk () {
        if (!analysisConsolidated?._id) {
          return;
        }

        const params = { analysisId: analysis._id, consolidatedId: analysisConsolidated._id, companyId: selectedStocking.companyId, currentPage, stockingId: selectedStocking._id };
        dispatch(analysisFromConsolidatedSlice.removeAnalysis(params));
      },
    });
  };

  const getColumnsType = () => {
    const columnsType: ColumnsType<IAnalysisFromConsolidated> = [
      {
        key: 1,
        width: '42%',
        title: t('analysis.code'),
        responsive: ['lg'] as Breakpoint[],
        dataIndex: 'code',
      },
      {
        key: 2,
        width: '42%',
        title: t('analysis.date'),
        dataIndex: 'createdAt',
      },
    ];

    if (analysis.length > 2) {
      columnsType.push(
        {
          key: 3,
          width: '16%',
          render: (record: IAnalysisFromConsolidated) => {
            return (
              <div
                className={styles.exclude}
                onClick={() => renderConfirmationRemoveAnalyisis(record)}
              >
                {t('analysis.exclude')}
              </div>
            );
          }
        },
      );
    }
    return columnsType;
  };

  const closeModal = () => {
    dispatch(analysisFromConsolidatedSlice.setAnalysisConsolidated(undefined));
    dispatch(analysisFromConsolidatedSlice.setShowModal(false));
  };

  return (
    <LrvModal
      theme={theme}
      className={styles.analysisFromConsolidatedModal}
      title={t('analysis.analysis')}
      open={showModal}
      destroyOnClose={true}
      okButtonProps={{ id: 'submit_remove_analysis_from_consolidated', htmlType: 'button' }}
      cancelButtonProps={{ id: 'cancel_remove_analysis_from_consolidated' }}
      onOk={closeModal}
      okText={t('analysis.accept')}
      cancelText={t('analysis.cancel').toUpperCase()}
      onCancel={closeModal}
    >
      <div className={styles.description}>
        <LrvText text={`${t('analysis.consolidatedAnalysis')}: ${analysisConsolidated?.code}`} theme={theme} />
      </div>

      <div className={styles.description}>
        <LrvText text={`* ${t('analysis.descriptionConsolidatedAnalysis')}`} theme={theme} />
      </div>

      <LrvTable
        id='analysis_table'
        columns={getColumnsType()}
        loading={isLoading}
        dataSource={analysisData}
        size='small'
        theme={theme}
        pagination={false}
      />
    </LrvModal>
  );
};
