import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STOCKINGS_URL } from '../../config/config.api';
import { axiosClient as axios } from '../../utils/axios_instance';
import { showErrorStocking } from '../../helpers/stocking.helpers';
import { openSuccessNotification } from '../../common/notification/Notification';

import * as sowingsSlice from './sowingsSlice';
import { PartialHarvest, Stocking, HarvestsStockingState, UndoHarvestStockingProps } from './interfaces';

const initialState: HarvestsStockingState = {
  isLoadingHarvests: false,
  isLoadingUndoHarvest: false,
  showHarvestsInfo: false,
  harvests: [],
};

export const harvestsStockingSlice = createSlice({
  name: 'partialHarvests',
  initialState,
  reducers: {
    setIsLoadingHarvests: (state: HarvestsStockingState, action: PayloadAction<boolean>) => {
      state.isLoadingHarvests = action.payload;
    },
    setIsLoadingUndoHarvest: (state: HarvestsStockingState, action: PayloadAction<boolean>) => {
      state.isLoadingUndoHarvest = action.payload;
    },
    setShowHarvestsInfo: (state: HarvestsStockingState, action: PayloadAction<boolean>) => {
      state.showHarvestsInfo = action.payload;
    },
    setPartialHarvests: (state: HarvestsStockingState, action: PayloadAction<PartialHarvest[]>) => {
      state.harvests = action.payload;
    },
  },
});

export const {
  setIsLoadingHarvests,
  setIsLoadingUndoHarvest,
  setPartialHarvests,
  setShowHarvestsInfo,
} = harvestsStockingSlice.actions;

export const fetchPartialHarvests = (props: { stockingId: string }) => async (dispatch: Function) => {
  const { stockingId } = props;
  dispatch(setIsLoadingHarvests(true));

  try {
    const params = {
      $select: ['_id', 'name', 'harvests', 'naupliusNumber', 'juvenilesNumber', 'growOutNumber'],
    };
    const response = await axios.get(`${STOCKINGS_URL}/${stockingId}`, { params });
    console.log('response', response);
    const stocking: Stocking = response.data;
    const { harvests } = stocking;
    dispatch(setPartialHarvests(harvests || []));
  } catch (error) {
    console.log(error?.response);
  }

  dispatch(setIsLoadingHarvests(false));
};

export const undoPartialHarvestStocking = (props: UndoHarvestStockingProps) => async (dispatch: Function) => {
  const { originStockingId, partialHarvestId, makeFetchStockings, paramsToFetchStocking } = props;
  dispatch(setIsLoadingUndoHarvest(true));

  const body = { partialHarvestId };
  const url = `${STOCKINGS_URL}/${originStockingId}/stocking-undo-partial-harvest`;

  try {
    await axios.patch(url, body);
    openSuccessNotification(i18next.t('stockings.updated'));

    dispatch(fetchPartialHarvests({ stockingId: originStockingId }));
    dispatch(setIsLoadingUndoHarvest(false));
  } catch (e) {
    console.log(e?.response);

    dispatch(setIsLoadingUndoHarvest(false));
    if (e?.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorStocking({ error });
      return;
    }
  }

  if (makeFetchStockings) {
    dispatch(sowingsSlice.fetchStockings(paramsToFetchStocking));
  } else {
    dispatch(sowingsSlice.fetchStocking({ id: originStockingId }));
  }
};

export default harvestsStockingSlice.reducer;
