import { roundTwoDecimals, MAX_SURVIVAL_RATE } from '../config/commons';
import PopulationTypes, { PopulationData, Stocking, StockingPopulation } from '../pages/Sowings/interfaces';

import { getVolume, getAnimals, calcDensity, fixStockingStartDate } from './stocking.helpers';

export const calcNewDensity = (props: { population: StockingPopulation; stocking: Stocking }) => {
  const { population, stocking } = props;
  return Math.round(population.animalsNumber / getVolume(stocking));
};

export const calcNewNumberAnimalsByDensity = (props: { density: number; stocking: Stocking }) => {
  const { density, stocking } = props;
  return getVolume(stocking) * density;
};

export const calcNewNumberAnimalsBySurvivalRate = (props: { population: PopulationData; stocking: Stocking; populations: PopulationData[]; index: number; }) => {
  const { population, stocking, populations, index } = props;
  let totalAnimalsTransferred = 0;
  for (let i = index; i >= 0; i--) {
    const element = populations[i];
    totalAnimalsTransferred += element.animalsTransferred;
  }

  const survivalRate = population.survivalRate;
  const maxAnimals = getAnimals({ stocking, phaseTypeSelected: stocking.phaseType });

  return Math.round(((survivalRate / 100) * maxAnimals) - totalAnimalsTransferred);

};

export const calcNewSurvivalRate = (props: { population: StockingPopulation; stocking: Stocking; populations: PopulationData[], index: number }) => {
  const { population, stocking, populations, index } = props;
  let totalAnimalsTransferred = 0;
  for (let i = index; i >= 0; i--) {
    const element = populations[i];
    totalAnimalsTransferred += element.animalsTransferred;
  }

  const animalsNumber = population.animalsNumber;
  const maxAnimals = getAnimals({ stocking, phaseTypeSelected: stocking.phaseType });

  return roundTwoDecimals(((animalsNumber + totalAnimalsTransferred) / maxAnimals) * 100);
};

export const getMaxAnimals = (props: { populations: PopulationData[]; stocking: Stocking; index: number }) => {
  const { populations, stocking, index } = props;

  if (index === 0) {
    const maxAnimals = getAnimals({ stocking: stocking, phaseTypeSelected: stocking.phaseType });
    return maxAnimals;
  }

  return populations[index - 1].animalsNumber;
};

export const getMaxDensity = (props: { populations: PopulationData[]; stocking: Stocking; index: number }) => {
  const { populations, stocking, index } = props;

  if (index === 0) {
    return calcDensity(stocking);
  }

  return populations[index - 1].density;
};

export const getMaxSurvivalRate = (props: { populations: PopulationData[]; index: number }) => {
  const { populations, index } = props;

  if (index === 0) {
    return MAX_SURVIVAL_RATE;
  }

  return populations[index - 1].survivalRate;
};

const buildPopulationDataForTransfers = (params: { index: number, stocking: Stocking, populations: PopulationData[] }) => {
  const { populations, stocking, index } = params;
  const currentPopulation = populations[index];
  const stockingAnimals = getAnimals({ stocking, phaseTypeSelected: stocking.phaseType });
  const animalsTransferred = currentPopulation.animalsTransferred;
  const volume = getVolume(stocking);

  let survivalRate = 100;
  let animalsNumber = stockingAnimals - animalsTransferred;
  let density = animalsNumber / volume;

  const prevIndex = index - 1;

  if (prevIndex >= 0) {
    const prevPopulation = populations[prevIndex];
    animalsNumber = prevPopulation.animalsNumber - animalsTransferred;
    density = animalsNumber / volume;
    survivalRate = prevPopulation.survivalRate;
  }

  const population: PopulationData = {
    ...currentPopulation,
    animalsNumber,
    density: Math.round(density),
    survivalRate,
  };
  
  return population;
};

export const sortAndBuildPopulationData = (stocking: Stocking) => {
  const { populations, transfers } = stocking;
  const populationsData: PopulationData[] = populations.map(item => ({ ...item, density: 0, survivalRate: 0, animalsTransferred: 0, type: PopulationTypes.MANUAL }));
  const transfersData: PopulationData[] = transfers.map(item => {
    const transferDate = fixStockingStartDate(item.transferDate);
    return {
      type: PopulationTypes.HARVEST,
      animalsNumber: 0,
      stockingId: item.stockingId,
      animalsTransferred: item.animalsTransferred,
      density: 0,
      populationDate: transferDate,
      survivalRate: 0,
    };
  });

  const joinedPopulationData = [...populationsData, ...transfersData];
  const sortedData = joinedPopulationData.sort((a, b) => {
    const dateA = new Date(a.populationDate).getTime();
    const dateB = new Date(b.populationDate).getTime();
    
    return dateA - dateB;
  });

  const auxSortedData = sortedData.slice();
  const newPopulationData = sortedData.map((item, index) => {
    let population = item;

    if (item.type === PopulationTypes.MANUAL) {
      population.density = calcNewDensity({ population, stocking });
      population.survivalRate = calcNewSurvivalRate({ population, stocking, populations: auxSortedData, index });
      auxSortedData[index] = population;
    }

    if (item.type === PopulationTypes.HARVEST) {
      population = buildPopulationDataForTransfers({ index, stocking, populations: auxSortedData });
      auxSortedData[index] = population;
    }

    return population;
  });

  return newPopulationData;
};

export const updateTransfersItems = (populations: PopulationData[], stocking: Stocking) => {
  const auxSortedData = populations.slice();
  const newPopulationData = populations.map((item, index) => {
    let population = item;

    if (item.type === PopulationTypes.HARVEST) {
      population = buildPopulationDataForTransfers({ index, stocking, populations: auxSortedData });
      auxSortedData[index] = population;
    }

    return population;
  });
  return newPopulationData;
};
