import cx from 'classnames';
import { RadioChangeEvent } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../state/store';
import { getCurrentTheme } from '../../helpers/theme';
import { THEME, stockingSearchTypes } from '../../config/commons';
import { LrvRadioGroup } from '../../common/components/LrvRadioGroup/LrvRadioGroup';

import * as stockingSlice from './sowingsSlice';
import styles from './RadioGroupSearchType.module.scss';

interface Props {
  show?: boolean;
}

function RadioGroupSearchType (props: Props) {
  const { show = false } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { searchType } = useSelector((state: RootState) => state.stockings.filters);

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const onChangeTypeSearch = (event: RadioChangeEvent) => {
    const value = event.target.value;
    dispatch(stockingSlice.setSearchType(value));
  };

  const options = [
    { label: t('stockings.typeSearch.name'), value: stockingSearchTypes.NAME },
    { label: t('stockings.typeSearch.cycle'), value: stockingSearchTypes.CYCLE },
    { label: t('stockings.typeSearch.lab'), value: stockingSearchTypes.LAB },
  ];

  return (
    <div
      className={cx(
        styles.typeSearch,
        isLightTheme ? styles.typeSearchLight : styles.typeSearchDark,
        show ? styles.showTypeSearch : styles.hideTypeSearch
      )}
    >
      <LrvRadioGroup
        title={t('stockings.typeSearch.title')}
        theme={isLightTheme ? 'dark' : 'light'}
        id='type_search'
        value={searchType}
        onChange={onChangeTypeSearch}
        options={options}
        className={styles.radioGroup}
      >
      </LrvRadioGroup>
    </div>
  );
}

export default RadioGroupSearchType;